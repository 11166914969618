﻿@import "mixins";
@import "vars";

.btn-property-let {
	@include btn-property-let;
	&:hover {
		background: $property-let-light;
	}
}
#nav-property-let {
    &.selected {
        border-color: $property-let;
    }
}
.property-let {
	::selection {
		background: $property-let;
		color: $white;
	}
	::-moz-selection {
		background: $property-let;
		color: $white;
	}
	h2, h3 {
		color: $property-let;
	}
    &.quote-button {
		background: $property-let;
        width: 100% !important;
        padding: 12px;

		&:hover {
			background: lighten($property-let, 15%);
			color: #fff;
		}
        &:active {
			background: darken($property-let, 15%);
			color: #fff;
		}
	}
	.product-feature {
		background: url(../img/banner_property-let.png) no-repeat 0 0;
		background-size: cover;
        min-height: 260px;
        position: relative;

        .banner-details {
            height: 100%;
            position: absolute;
        }

		h1 {
			color: $white;
		}
		p {
			color: $property-let;
		}

        .coming-soon {
            background-color: $property-let;
        }

	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $property-let;
				}
				&.selected {
					border-color: $property-let;
				}
			}
		}
		#call-us {
			strong {
				color: $property-let;
			}
		}
	}

		.case-studies {
			li {
				a{
					color: $property-let;

					&:hover{
						color: $property-let;
					}
				}
			}
		}

		.right-sidebar {

			h4 {
				color: $property-let;
                margin: 0;
			}

			.button {
				background: $property-let-light !important;
			}
			li {
				border-bottom-color: $property-let;
				&:before {
					@include list-tick-property-let;
				}
			}
		}

	.feature-list {
		li {
			@include list-tick-property-let;
		}
	}
	.round-message {
		background: $property-let;
	}

	.radio-orange {
		margin: 0 10px 0 -5px;
		span:before {
			background-color: $property-let !important;
		}
	}
}

table#quote-results-table {
	tbody tr:nth-child(even) {
		background: none;
	}
}
