// Align
.u-align-left{
    text-align: left;
}
.u-align-center{
    text-align: center;
}
.u-align-right{
    text-align: right;
}


// Text
.u-bold{
    font-weight: bold;
}
.u-extrabold{
    font-weight: bolder;
}
.u-italic{
    font-style: italic;
}
.u-uppercase{
    text-transform: uppercase;
}
.u-bree{
    font-family: "bree", sans-serif;
}
.u-text-small{
    font-size: 12px;
}

// Display
.u-block{
    display: block;
}
.u-inline-block{
    display: inline-block;
}
.u-inline{
    display: inline;
}
.u-hide{
    display: none !important;
}

// Spacing
.u-space{

    @for $i from 0 to 16 {
        &-t#{$i} {
            margin-top: ($i * $space);
        }

        &-r#{$i} {
            margin-right: ($i * $space);
        }

        &-b#{$i} {
            margin-bottom: ($i * $space);
        }

        &-l#{$i} {
            margin-left: ($i * $space);
        }

        &-x#{$i} {
            margin-left: ($i * $space);
            margin-right: ($i * $space);
        }
    }

}

.u-pad{

    @for $i from 0 to 16 {
        &-t#{$i} {
            padding-top: ($i * $space);
        }

        &-r#{$i} {
            padding-right: ($i * $space);
        }

        &-b#{$i} {
            padding-bottom: ($i * $space);
        }

        &-l#{$i} {
            padding-left: ($i * $space);
        }
    }

}

.u-clear {
    clear: both;
}
