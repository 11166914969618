.btn-gap {
	@include btn-gap;
	&:hover {
		background: $gap-hover;
	}
}

.gap {
	::selection {
		background: $gap;
		color: $white;
	}
	::-moz-selection {
		background: $gap;
		color: $white;
	}
	h2, h3 {
		color: $gap;
	}
	.product-feature {
		background: url(../img/banner_gap.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $gap-light;

			&:hover {
				background: $gap;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: $gap-light;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $gap;
				}
				&.active {
					background: $gap;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $gap;
			}
		}
	}

		.case-studies {
			li {
				a{
					color: $gap;

					&:hover{
						color: $gap;
					}
				}
			}
		}

		.right-sidebar {
	            	background: #e0f0df;

			h4 {
				color: $gap;
			}
			.button {
				background: $gap-light !important;
			}
			li {
				border-bottom-color: $gap;
				&:before {
					@include list-tick-gap;
				}
			}
		}

	.feature-list {
		li {
			@include list-tick-gap;
		}
	}
	.round-message {
		background: $gap;
	}

	.radio-orange {
		margin: 0 10px 0 -5px;
		span:before {
			background-color: $gap !important;
		}
	}
}

table#quote-results-table {
	tbody tr:nth-child(even) {
		background: none;
	}
}