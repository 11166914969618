@charset "UTF-8";
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

/* BUTTON MIXINS */
/* END BUTTON MIXINS */
/* LIST MIXINS */
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin: 0; }

h1 {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px; }

h2 {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 39px; }

h3 {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-size: 28px;
  font-style: normal;
  font-weight: 400; }

h4 {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; }

h5 {
  font-style: normal;
  font-weight: normal; }

h6 {
  font-style: italic;
  font-weight: 400; }

p a:link {
  text-decoration: none; }

p a:visited {
  text-decoration: none; }

p a:hover {
  text-decoration: none; }

dt, dl, dd, label, li, p, div {
  color: #756f6f;
  font-family: myriad-pro, arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-indent: 0px; }

p.bullet {
  font-family: myriad-pro, arial, sans-serif;
  text-indent: -10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; }

p b {
  margin: 0; }

/* End Typography */
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

/* BUTTON MIXINS */
/* END BUTTON MIXINS */
/* LIST MIXINS */
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

#home-boxes {
  margin-bottom: 20px; }
  #home-boxes li {
    background: url(../img/home_boxes_new.jpg) no-repeat;
    list-style: none;
    margin: 0 10px 20px;
    padding: 0;
    width: 220px; }
    #home-boxes li span {
      background-image: url(../img/home_boxes_arrows.png);
      background-repeat: no-repeat;
      width: 100%; }
    #home-boxes li a.home-box-link {
      display: block;
      min-height: 166px; }
    #home-boxes li.box-breakdown {
      /* used to be 1494px*/
      background-position: left -1991px; }
      #home-boxes li.box-breakdown:hover {
        background-position: right -1991px; }
      #home-boxes li.box-breakdown span {
        background-color: #032d4a;
        background-position: 180px -176px; }
    #home-boxes li.box-lifestyle-legal {
      background-position: left -2158px; }
      #home-boxes li.box-lifestyle-legal:hover {
        background-position: right -2158px; }
      #home-boxes li.box-lifestyle-legal span {
        background-color: #007e81;
        background-position: 180px -951px; }
    #home-boxes li.box-rent-guarantee {
      background-position: left -3777px; }
      #home-boxes li.box-rent-guarantee:hover {
        background-position: right -3777px; }
      #home-boxes li.box-rent-guarantee span {
        background-color: #007e81;
        background-position: 180px -951px; }
    #home-boxes li.box-travel {
      background: url(../img/home_boxes_new.jpg) no-repeat;
      background-position: left -499px;
      width: 220px; }
      #home-boxes li.box-travel:hover {
        background-position: right -499px; }
      #home-boxes li.box-travel span {
        background-color: #361f59;
        background-position: 180px -176px; }
    #home-boxes li.box-news {
      background: #bdd9bb;
      background-position: left 0;
      height: 220px;
      width: 460px; }
      #home-boxes li.box-news:hover {
        background-position: right 0; }
      #home-boxes li.box-news span {
        background-color: #3e863b;
        background-position: 420px -305px; }
    #home-boxes li.box-household {
      background: url(../img/home_boxes_new.jpg) no-repeat;
      background-position: left -0px;
      width: 220px; }
      #home-boxes li.box-household:hover {
        background-position: right -0px; }
      #home-boxes li.box-household span {
        background-color: #21557a;
        background-position: 420px -111px; }
    #home-boxes li.box-household-wide {
      background: url(../img/home_boxes_wide.jpg);
      background-repeat: no-repeat !important;
      background-position: -1px -333px;
      width: 460px; }
      #home-boxes li.box-household-wide:hover {
        background-position: -461px -333px; }
      #home-boxes li.box-household-wide span {
        background-color: #21557a;
        background-position: 420px -305px; }
    #home-boxes li.box-household2 {
      background-position: left 0; }
      #home-boxes li.box-household2:hover {
        background-position: right 0; }
      #home-boxes li.box-household2 span {
        background-color: #21557a;
        background-position: 180px -120px; }
    #home-boxes li.box-warranty {
      background-position: left -1826px; }
      #home-boxes li.box-warranty:hover {
        background-position: right -1826px; }
      #home-boxes li.box-warranty span {
        background-color: #124546;
        background-position: 180px -563px; }
    #home-boxes li.box-gap {
      background-position: left -332px; }
      #home-boxes li.box-gap:hover {
        background-position: right -332px; }
      #home-boxes li.box-gap span {
        background-color: #3e863b;
        background-position: 180px -240px; }
      #home-boxes li.box-gap strong {
        font-family: arial, sans-serif;
        font-style: normal;
        color: #3e863b;
        display: block;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        margin: 20px 20px 0 15px; }
    #home-boxes li.box-excess {
      background-position: left -830px; }
      #home-boxes li.box-excess:hover {
        background-position: right -830px; }
      #home-boxes li.box-excess span {
        background-color: #55bb50;
        background-position: 180px -499px; }
    #home-boxes li.box-mot {
      background-position: left -996px; }
      #home-boxes li.box-mot:hover {
        background-position: right -996px; }
      #home-boxes li.box-mot span {
        background-color: #d4ab17;
        background-position: 180px -396px; }
    #home-boxes li.box-home {
      background: url(../img/home_boxes_new.jpg) no-repeat;
      background-position: left -166px;
      width: 220px; }
      #home-boxes li.box-home:hover {
        background-position: right -167px; }
      #home-boxes li.box-home span {
        background-color: #f79132;
        background-position: 420px -886px; }
    #home-boxes li.box-bicycle {
      background: url(../img/home_boxes_new.jpg) no-repeat;
      background-position: left -3557px;
      width: 220px; }
      #home-boxes li.box-bicycle:hover {
        background-position: right -3557px; }
      #home-boxes li.box-bicycle span {
        background-color: #f79132;
        background-position: 180px -886px; }
    #home-boxes li.box-life {
      background-position: left -2378px; }
      #home-boxes li.box-life:hover {
        background-position: right -2378px; }
      #home-boxes li.box-life span {
        background-color: #d4ab17;
        background-position: 180px 18px; }
    #home-boxes li.box-keys {
      background-position: left -2710px; }
      #home-boxes li.box-keys:hover {
        background-position: right -2710px; }
      #home-boxes li.box-keys span {
        background-color: #fcb64f;
        background-position: 180px -628px; }
    #home-boxes li.box-hire {
      background-position: left -1660px; }
      #home-boxes li.box-hire:hover {
        background-position: right -1660px; }
      #home-boxes li.box-hire span {
        background-color: #d53f3b;
        background-position: 180px -370px; }
    #home-boxes li.box-caravan {
      background-position: left -2544px; }
      #home-boxes li.box-caravan:hover {
        background-position: right -2544px; }
      #home-boxes li.box-caravan span {
        background-color: #4e396d;
        background-position: 180px -434px; }
    #home-boxes li.box-touring-caravan {
      background-position: left -3042px; }
      #home-boxes li.box-touring-caravan:hover {
        background-position: right -3042px; }
      #home-boxes li.box-touring-caravan span {
        background-color: #4e396d;
        background-position: 180px -434px; }
    #home-boxes li.box-alloy {
      background: url(../img/home_boxes_wide.jpg) no-repeat;
      background-position: left 0;
      width: 460px; }
      #home-boxes li.box-alloy:hover {
        background-position: right 0; }
      #home-boxes li.box-alloy span {
        background-color: #9d1b0f;
        background-position: 420px -757px; }
    #home-boxes li.box-tyre {
      background-position: left -1328px; }
      #home-boxes li.box-tyre:hover {
        background-position: right -1328px; }
      #home-boxes li.box-tyre span {
        background-color: #a2086e;
        background-position: 180px -822px; }
    #home-boxes li.box-scratch {
      background-position: left -664px; }
      #home-boxes li.box-scratch:hover {
        background-position: right -664px; }
      #home-boxes li.box-scratch span {
        background-color: #5a120b;
        background-position: 180px -693px; }
    #home-boxes li.box-gadget {
      background-position: left -3208px; }
      #home-boxes li.box-gadget:hover {
        background-position: right -3208px; }
      #home-boxes li.box-gadget span {
        background-color: #d53674;
        background-position: 180px -1015px; }
    #home-boxes li.box-property-let {
      background-position: left -3376px; }
      #home-boxes li.box-property-let:hover {
        background-position: right -3376px; }
      #home-boxes li.box-property-let span {
        background-color: #0089ab;
        background-position: 180px -176px; }
  #home-boxes h3 {
    font-family: "bree", sans-serif;
    font-style: normal;
    font-size: 28px;
    margin: 0;
    padding: 15px 15px 0; }
  #home-boxes p {
    color: #fff; }
  #home-boxes span {
    background: url(../img/home/arrow-quote.png) no-repeat 170px center;
    display: block;
    font-weight: bold;
    padding: 15px; }
    #home-boxes span a {
      color: #fff;
      display: block; }
  #home-boxes .home-news {
    margin: 8px 0 0 0;
    height: 175px; }
    #home-boxes .home-news li {
      background: none !important;
      height: 175px;
      width: 460px !important; }
      #home-boxes .home-news li p {
        color: #000;
        line-height: 1.5em;
        margin: 0 15px; }
      #home-boxes .home-news li span {
        bottom: 0;
        position: absolute;
        width: 100%; }

/************************
7. a. HOME SLIDER BASE
************************/
#home-slider {
  clear: both;
  float: left;
  height: 290px !important;
  margin: 36px 1px 0;
  position: relative; }
  #home-slider .slide-sl {
    color: #fff;
    float: left;
    height: 185px;
    margin-top: 0;
    position: relative;
    z-index: 2; }
  #home-slider .slide-s4 h2 {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #1a2d3d;
    font-size: 32px;
    line-height: 36px;
    font-weight: 400;
    margin-bottom: 15px; }
  #home-slider .slide-s4 h1 {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #1a2d3d;
    font-size: 65px;
    font-weight: 700;
    line-height: 36px; }
  #home-slider .slide-content-sl ul {
    height: 34px;
    list-style: none;
    position: absolute; }
    #home-slider .slide-content-sl ul li {
      float: left; }
      #home-slider .slide-content-sl ul li a.link {
        color: #d2cdb8;
        display: block;
        float: left;
        font-size: 0.875em;
        margin: 14px 0 0 0;
        padding: 0 9px 5px 0;
        text-align: center;
        text-decoration: none;
        width: 136px; }

/************************
7. b. HOME SLIDER TEXT
************************/
h2.daysingletrip {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 54px;
  letter-spacing: -2px;
  text-indent: 0;
  color: white; }

h1.europe {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 70px;
  letter-spacing: -4px;
  text-indent: 0;
  margin-top: 0;
  margin-bottom: 0;
  color: #946aac;
  width: 700px; }

h4.travelfromaslittleas {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  font-style: oblique;
  font-weight: 200;
  font-size: 24px;
  text-indent: 0;
  letter-spacing: 0px;
  line-height: 34px;
  margin-bottom: 0px;
  color: #fff;
  margin-top: 0; }

h3.travelprice {
  font-family: "myriad-pro";
  font-size: 62px;
  line-height: 44px;
  text-indent: 0;
  color: #fff;
  letter-spacing: -3px;
  margin-bottom: 0px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 700; }

h1.recovery {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  font-style: normal;
  font-weight: 700;
  font-size: 102px;
  line-height: 60px;
  letter-spacing: -4px;
  text-indent: 0px;
  margin-top: 0;
  margin-bottom: 0;
  color: #234666;
  width: 577px;
  text-shadow: 0px 0px 10px white; }

h2.localbreakdown {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 72px;
  letter-spacing: -2px;
  text-indent: 0px;
  -moz-font-feature-settings: 'liga=1';
  -ms-font-feature-settings: 'liga' 1;
  -webkit-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  color: #234666;
  text-shadow: 0px 0px 10px white; }

h4.breakdownfromaslittleas {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-style: oblique;
  font-weight: 200;
  -moz-font-feature-settings: 'liga=1';
  -ms-font-feature-settings: 'liga' 1;
  -webkit-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  font-size: 24px;
  text-indent: 4px;
  letter-spacing: -0.04em;
  line-height: 34px;
  margin-bottom: 0px;
  color: #234666;
  margin-top: 12px; }

h3.breakdownprice {
  font-family: myriad-pro;
  font-size: 63px;
  line-height: 61px;
  text-indent: 164px;
  color: #234666;
  letter-spacing: 0px;
  margin-bottom: 0px;
  margin-top: -34px;
  font-style: normal;
  font-weight: 400; }
  h3.breakdownprice strong {
    color: #0fb5e4; }

h4.breakdownpermonth {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-style: oblique;
  font-weight: 400;
  -moz-font-feature-settings: 'liga=1';
  -ms-font-feature-settings: 'liga' 1;
  -webkit-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  font-size: 24px;
  text-indent: 268px;
  letter-spacing: -0.04em;
  line-height: 46px;
  margin-bottom: 0px;
  color: #234666;
  margin-top: -44px; }

/* Household */
h2.household {
  font-family: "bree", sans-serif;
  font-style: normal;
  color: #fff;
  font-size: 39px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; }

h2.householdinsurance {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  color: #fff;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 48px;
  text-indent: 0px; }

#household-price {
  -moz-transform: rotate(-7deg);
  -ms-transform: rotate(-7deg);
  -o-transform: rotate(-7deg);
  -webkit-transform: rotate(-7deg);
  transform: rotate(-7deg);
  background: url(../img/home/bg_household_price.png) no-repeat 0 0;
  bottom: 20px;
  height: 175px;
  left: 450px;
  padding: 40px 0 0;
  position: absolute;
  text-align: center;
  width: 175px; }
  #household-price strong {
    font-family: "bree-serif", sans-serif;
    font-style: normal;
    color: #fff;
    display: block;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 4px; }
  #household-price span {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #0a5c06;
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    padding: 0 20px; }

/* Home Emergency */
.slider-text h1.indent3 {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  color: #f9f394;
  text-indent: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  letter-spacing: -4px;
  line-height: 15px;
  margin: -5px 0 40px; }

.slider-text h3.indent2 {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  color: #fff;
  font-size: 24px;
  font-style: oblique;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 10px;
  margin: -8px 0 200px;
  text-indent: 173px; }

.slider-text h1.white {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  color: #fff;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 72px;
  text-indent: 0px; }

.slider-text h3.white {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  color: #fff;
  font-size: 24px;
  font-style: oblique;
  font-weight: 200;
  letter-spacing: 0px;
  line-height: 30px;
  margin: -10px 0 0;
  text-indent: 41px; }

.slider-text h5.price-homeemergencywhite {
  font-family: "bree-serif", sans-serif;
  font-style: normal;
  color: #fff;
  font-size: 75px;
  letter-spacing: 0px;
  line-height: 10px;
  margin: 20px 0 -5px;
  text-indent: 95px; }

/* Gap */
h1.gap {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  color: #3e863b;
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  line-height: 65px;
  letter-spacing: -2px;
  text-indent: 0px; }

h2.gapbestcoveronthemarket {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  color: #234666;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 38px;
  text-indent: 0px; }

h3.gapreplacementcar {
  font-family: "bree", sans-serif;
  font-style: normal;
  font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga=1";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga" 1;
  -webkit-font-feature-settings: "liga" 1;
  color: #234666;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23px;
  text-indent: 0px; }

#register-modal {
  left: 0;
  margin: 0 auto;
  right: 0;
  width: 700px; }

#login-modal {
  left: 0;
  margin: 0 auto;
  max-width: 600px;
  right: 0; }

#login-modal, #register-modal, #retrieve-modal, #retrieve-quote, #edit-details-modal, #retrieve-password {
  background: #eee;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  text-align: center; }
  #login-modal .modal-content table, #register-modal .modal-content table, #retrieve-modal .modal-content table, #retrieve-quote .modal-content table, #edit-details-modal .modal-content table, #retrieve-password .modal-content table {
    width: 100%; }
  #login-modal .modal-content .modal-iframe, #register-modal .modal-content .modal-iframe, #retrieve-modal .modal-content .modal-iframe, #retrieve-quote .modal-content .modal-iframe, #edit-details-modal .modal-content .modal-iframe, #retrieve-password .modal-content .modal-iframe {
    border: none;
    height: 320px;
    width: 95%; }
  #login-modal .modal-header, #register-modal .modal-header, #retrieve-modal .modal-header, #retrieve-quote .modal-header, #edit-details-modal .modal-header, #retrieve-password .modal-header {
    padding: 9px 15px; }
  #login-modal .modal-body, #register-modal .modal-body, #retrieve-modal .modal-body, #retrieve-quote .modal-body, #edit-details-modal .modal-body, #retrieve-password .modal-body {
    max-height: 470px;
    overflow-y: auto;
    padding: 15px;
    position: relative; }
  #login-modal .regPage, #register-modal .regPage, #retrieve-modal .regPage, #retrieve-quote .regPage, #edit-details-modal .regPage, #retrieve-password .regPage {
    max-height: 600px; }
    #login-modal .regPage .input-container, #register-modal .regPage .input-container, #retrieve-modal .regPage .input-container, #retrieve-quote .regPage .input-container, #edit-details-modal .regPage .input-container, #retrieve-password .regPage .input-container {
      margin: 0 0 5px; }
      #login-modal .regPage .input-container .input, #login-modal .regPage .input-container .select, #register-modal .regPage .input-container .input, #register-modal .regPage .input-container .select, #retrieve-modal .regPage .input-container .input, #retrieve-modal .regPage .input-container .select, #retrieve-quote .regPage .input-container .input, #retrieve-quote .regPage .input-container .select, #edit-details-modal .regPage .input-container .input, #edit-details-modal .regPage .input-container .select, #retrieve-password .regPage .input-container .input, #retrieve-password .regPage .input-container .select {
        box-sizing: border-box;
        height: 40px;
        margin: 0 0 10px;
        padding: 0 0 0 10px; }
  #login-modal .regWarning, #register-modal .regWarning, #retrieve-modal .regWarning, #retrieve-quote .regWarning, #edit-details-modal .regWarning, #retrieve-password .regWarning {
    background: #ffffcc url(../img/alert000.png) no-repeat 15px 17px;
    margin: 5px 0 0;
    padding: 15px 15px 15px 40px; }
    #login-modal .regWarning p, #register-modal .regWarning p, #retrieve-modal .regWarning p, #retrieve-quote .regWarning p, #edit-details-modal .regWarning p, #retrieve-password .regWarning p {
      margin: 0; }
  #login-modal .facebook, #register-modal .facebook, #retrieve-modal .facebook, #retrieve-quote .facebook, #edit-details-modal .facebook, #retrieve-password .facebook {
    background-color: #4161a6;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 12px 0;
    position: relative;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5); }
    #login-modal .facebook:hover, #register-modal .facebook:hover, #retrieve-modal .facebook:hover, #retrieve-quote .facebook:hover, #edit-details-modal .facebook:hover, #retrieve-password .facebook:hover {
      background-color: #234666; }
    #login-modal .facebook a, #register-modal .facebook a, #retrieve-modal .facebook a, #retrieve-quote .facebook a, #edit-details-modal .facebook a, #retrieve-password .facebook a {
      color: #fff;
      display: block; }
  #login-modal .password-reset, #register-modal .password-reset, #retrieve-modal .password-reset, #retrieve-quote .password-reset, #edit-details-modal .password-reset, #retrieve-password .password-reset {
    color: #666;
    display: block;
    font-size: 12px;
    margin: 8px 0 0 0;
    text-align: right;
    text-decoration: underline; }
  #login-modal .register-link, #register-modal .register-link, #retrieve-modal .register-link, #retrieve-quote .register-link, #edit-details-modal .register-link, #retrieve-password .register-link {
    color: #666;
    display: block;
    font-size: 14px;
    margin: 15px 0 0 0;
    text-align: left; }
    #login-modal .register-link a, #register-modal .register-link a, #retrieve-modal .register-link a, #retrieve-quote .register-link a, #edit-details-modal .register-link a, #retrieve-password .register-link a {
      color: #234666;
      font-weight: bold; }
      #login-modal .register-link a:hover, #register-modal .register-link a:hover, #retrieve-modal .register-link a:hover, #retrieve-quote .register-link a:hover, #edit-details-modal .register-link a:hover, #retrieve-password .register-link a:hover {
        color: #0f283e; }
  #login-modal .input-container, #register-modal .input-container, #retrieve-modal .input-container, #retrieve-quote .input-container, #edit-details-modal .input-container, #retrieve-password .input-container {
    position: relative; }
    #login-modal .input-container input, #register-modal .input-container input, #retrieve-modal .input-container input, #retrieve-quote .input-container input, #edit-details-modal .input-container input, #retrieve-password .input-container input {
      background-color: #fff;
      border: 1px solid #ccc;
      box-sizing: border-box;
      color: #aaa;
      display: inline-block;
      font-size: 17px;
      height: 54px;
      margin: 0 0 20px;
      padding: 0 0 0 60px;
      width: 100%; }
    #login-modal .input-container i, #register-modal .input-container i, #retrieve-modal .input-container i, #retrieve-quote .input-container i, #edit-details-modal .input-container i, #retrieve-password .input-container i {
      position: absolute; }
    #login-modal .input-container .sprite, #register-modal .input-container .sprite, #retrieve-modal .input-container .sprite, #retrieve-quote .input-container .sprite, #edit-details-modal .input-container .sprite, #retrieve-password .input-container .sprite {
      background-image: url(../img/social-sprite.png);
      background-repeat: no-repeat; }
    #login-modal .input-container .head, #register-modal .input-container .head, #retrieve-modal .input-container .head, #retrieve-quote .input-container .head, #edit-details-modal .input-container .head, #retrieve-password .input-container .head {
      background-position: -552px -55px;
      height: 26px;
      left: 15px;
      top: 15px;
      width: 28px; }
    #login-modal .input-container .lock, #register-modal .input-container .lock, #retrieve-modal .input-container .lock, #retrieve-quote .input-container .lock, #edit-details-modal .input-container .lock, #retrieve-password .input-container .lock {
      background-position: -509px -343px;
      height: 32px;
      left: 17px;
      top: 10px;
      width: 23px; }
  #login-modal .white-fb-logo, #register-modal .white-fb-logo, #retrieve-modal .white-fb-logo, #retrieve-quote .white-fb-logo, #edit-details-modal .white-fb-logo, #retrieve-password .white-fb-logo {
    background-position: -228px -107px;
    height: 30px;
    width: 14px; }
  #login-modal .pulse-btn.facebook i, #register-modal .pulse-btn.facebook i, #retrieve-modal .pulse-btn.facebook i, #retrieve-quote .pulse-btn.facebook i, #edit-details-modal .pulse-btn.facebook i, #retrieve-password .pulse-btn.facebook i {
    left: 25px;
    margin-top: -15px;
    position: absolute;
    top: 50%; }
  #login-modal .options-divider, #register-modal .options-divider, #retrieve-modal .options-divider, #retrieve-quote .options-divider, #edit-details-modal .options-divider, #retrieve-password .options-divider {
    color: #686868;
    font-size: 16px;
    margin: 15px 0;
    text-align: center;
    text-shadow: 0 1px 0 #fff; }

#edit-details-modal {
  margin-top: -225px; }
  #edit-details-modal #edit-details {
    margin-top: 20px; }
    #edit-details-modal #edit-details .watermarked {
      color: #9e9e9f !important; }

/* Main Navigation */
.nav-bar {
  background: #234666;
  display: table;
  margin-bottom: 0;
  position: relative;
  width: 100%;
  z-index: 11; }
  .nav-bar li {
    position: relative;
    display: table-cell;
    vertical-align: top;
    float: none; }
    .nav-bar li:hover {
      background: #032d4a; }
    .nav-bar li a, .nav-bar li span {
      font-family: "myriad-pro", arial;
      font-style: normal;
      color: #fff;
      font-weight: 600;
      font-size: 16px !important;
      padding: 0 12px 0 12px !important; }
      .nav-bar li a.active, .nav-bar li span.active {
        font-weight: bold;
        line-height: 56px; }
  .nav-bar li > ul a.selected, .nav-bar li > ul span.selected {
    background-color: #032d4a;
    border-bottom: 7px solid #0c98c0;
    color: #fff; }
  .nav-bar__dropdown {
    padding-left: 10px;
    padding-right: 10px; }
    .nav-bar__dropdown:after {
      background: url(../img/nav-arrow.png) no-repeat right top;
      content: '';
      display: inline-block;
      height: 12px;
      width: 12px; }
    .nav-bar__dropdown:hover {
      cursor: default;
      background-position: right -69px; }
      .nav-bar__dropdown:hover:after {
        background-position: right bottom; }

.nav-bar.sticky {
  margin: 0;
  position: fixed;
  top: 0;
  width: 940px;
  z-index: 100; }

.nav-bar li ul {
  display: none;
  margin: 0;
  left: 0;
  position: absolute;
  min-width: 100%;
  z-index: 2; }
  .nav-bar li ul li {
    background: #032d4a;
    border-bottom: 1px solid #7e9bb5;
    border-left: none;
    display: block;
    float: none;
    height: auto;
    margin: 0;
    width: 100%; }
    .nav-bar li ul li:last-child {
      border-bottom: none; }
    .nav-bar li ul li a {
      color: #fff;
      padding: 12px 20px 12px 12px !important; }
      .nav-bar li ul li a .right-arrow {
        right: 12px;
        position: absolute;
        top: 35%; }

.nav-bar li:hover ul {
  display: block; }
  .nav-bar li:hover ul li {
    margin: 0; }
    .nav-bar li:hover ul li a {
      border-bottom: 7px solid transparent; }
      .nav-bar li:hover ul li a:hover {
        background: #032d4a;
        color: #fff; }
  .nav-bar li:hover ul li.nav-household a:hover, .nav-bar li:hover ul li.nav-household a.selected {
    border-bottom: 7px solid #21557a; }
  .nav-bar li:hover ul li.nav-home-emergency a:hover, .nav-bar li:hover ul li.nav-home-emergency a.selected {
    border-bottom: 7px solid #f79132; }
  .nav-bar li:hover ul li.nav-keys a:hover, .nav-bar li:hover ul li.nav-keys a.selected {
    border-bottom: 7px solid #fcb64f; }
  .nav-bar li:hover ul li.nav-property-let a:hover, .nav-bar li:hover ul li.nav-property-let a.selected {
    border-bottom: 7px solid #0089ab; }
  .nav-bar li:hover ul li.nav-travel a:hover, .nav-bar li:hover ul li.nav-travel a.selected {
    border-bottom: 7px solid #361f59; }
  .nav-bar li:hover ul li.nav-life a:hover, .nav-bar li:hover ul li.nav-life a.selected {
    border-bottom: 7px solid #d4ab17; }
  .nav-bar li:hover ul li.nav-hire a:hover, .nav-bar li:hover ul li.nav-hire a.selected {
    border-bottom: 7px solid #d53f3b; }
  .nav-bar li:hover ul li.nav-breakdown a:hover, .nav-bar li:hover ul li.nav-breakdown a.selected {
    border-bottom: 7px solid #9fdef5; }
  .nav-bar li:hover ul li.nav-gap a:hover, .nav-bar li:hover ul li.nav-gap a.selected {
    border-bottom: 7px solid #3e863b; }
  .nav-bar li:hover ul li.nav-caravan a:hover, .nav-bar li:hover ul li.nav-caravan a.selected {
    border-bottom: 7px solid #4e396d; }
  .nav-bar li:hover ul li.nav-gadget a:hover, .nav-bar li:hover ul li.nav-gadget a.selected {
    border-bottom: 7px solid #d53674; }
  .nav-bar li:hover ul li.nav-other a:hover, .nav-bar li:hover ul li.nav-other a.selected {
    border-bottom: 7px solid #032d4a; }
  .nav-bar li:hover ul li.nav-lifestyle-legal a:hover, .nav-bar li:hover ul li.nav-lifestyle-legal a.selected {
    border-bottom: 7px solid #007e81; }
  .nav-bar li:hover ul li.nav-alloy-wheel a:hover, .nav-bar li:hover ul li.nav-alloy-wheel a.selected {
    border-bottom: 7px solid #9d1b0f; }
  .nav-bar li:hover ul li.nav-scratch-dent a:hover, .nav-bar li:hover ul li.nav-scratch-dent a.selected {
    border-bottom: 7px solid #5a120b; }
  .nav-bar li:hover ul li.nav-car-excess a:hover, .nav-bar li:hover ul li.nav-car-excess a.selected {
    border-bottom: 7px solid #55bb50; }
  .nav-bar li:hover ul li.nav-mot a:hover, .nav-bar li:hover ul li.nav-mot a.selected {
    border-bottom: 7px solid #d4ab17; }
  .nav-bar li:hover ul li.nav-courtesy-car a:hover, .nav-bar li:hover ul li.nav-courtesy-car a.selected {
    border-bottom: 7px solid #a2086e; }
  .nav-bar li:hover ul li.nav-tyre a:hover, .nav-bar li:hover ul li.nav-tyre a.selected {
    border-bottom: 7px solid #a2086e; }
  .nav-bar li:hover ul li.nav-warranty a:hover, .nav-bar li:hover ul li.nav-warranty a.selected {
    border-bottom: 7px solid #124546; }

/* Top Navigation  */
.utility-nav {
  text-align: right; }
  .utility-nav__locale {
    color: #8c8c8c;
    display: inline-block;
    font-style: italic;
    padding-top: 12px; }
    .utility-nav__locale a {
      font-style: normal;
      font-weight: 700;
      text-decoration: underline; }

.sub-nav {
  /*padding-top: 32px;*/
  text-align: right; }

/* Top Navigation  */
#nav-extra {
  display: inline-block;
  margin: 15px 0 0 0;
  position: relative;
  vertical-align: top;
  z-index: 2; }
  #nav-extra li {
    border-right: 1px solid #1c1c1b;
    display: inline;
    list-style: none;
    padding: 0 9px 0 9px;
    position: relative;
    z-index: 3; }
    #nav-extra li.last {
      border-right: none; }
    #nav-extra li a {
      font-family: "myriad-pro", arial;
      font-style: normal;
      font-size: 16px; }

ul#top-btns {
  float: right; }
  ul#top-btns li {
    display: inline-block;
    margin: 0 0 0 8px;
    text-align: center; }
    ul#top-btns li.first, ul#top-btns li.second {
      margin: 0;
      vertical-align: top; }
    ul#top-btns li.first {
      padding-top: 12px; }
    ul#top-btns li a {
      border: 0;
      color: #fff;
      display: inline-block;
      font-weight: 600;
      padding: 12px 20px;
      text-align: center;
      padding: 12px 15px;
      font-size: 16px; }

/* Utility Nav */
#nav-util {
  margin: 0; }
  #nav-util li {
    border-right: 1px solid #1c1c1b;
    display: inline;
    list-style: none;
    padding: 0 9px 0 9px;
    position: relative;
    margin: 0 !important; }
    #nav-util li:last-child {
      border-right: none; }
    #nav-util li a {
      font-family: "myriad-pro", arial;
      font-style: normal;
      font-weight: 400 !important;
      color: #8c8c8c !important;
      padding: 0 0 !important;
      font-size: 15px !important; }

/* Country Selector */
#country_change {
  appearance: none;
  background-color: transparent;
  background-image: url(../img/ui-country-select-bg.png);
  background-repeat: no-repeat;
  background-position: center bottom 4px;
  padding-bottom: 14px;
  padding-top: 18px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #234666;
  border-top: none;
  cursor: pointer;
  color: #8c8c8c !important; }

.loggedin-controls {
  position: relative;
  text-align: left; }
  .loggedin-controls .parent:after {
    margin-left: 8px;
    background: url(../img/nav-arrow.png) no-repeat right top;
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px; }
  .loggedin-controls .parent.active {
    cursor: default;
    background-color: #1a1f26;
    background-position: right -69px; }
    .loggedin-controls .parent.active:after {
      background-position: right bottom; }
  .loggedin-controls__dropdown {
    display: block;
    position: absolute;
    top: -999em;
    margin: 0;
    width: 14em;
    z-index: 999999; }
    .loggedin-controls__dropdown li {
      background: #1a1f26;
      border-bottom: 1px solid #7e9bb5;
      color: #fff;
      margin: 0 !important;
      width: 100%;
      z-index: 99999; }
      .loggedin-controls__dropdown li:last-child {
        border: none; }
      .loggedin-controls__dropdown li:hover {
        background: #234666; }
      .loggedin-controls__dropdown li a {
        text-align: left !important;
        position: relative;
        padding: 12px !important;
        width: 100%; }
  .loggedin-controls .parent.active + .loggedin-controls__dropdown {
    left: 0;
    top: 48px; }

.login-details {
  display: inline-block;
  text-align: left;
  font-size: 10pt;
  vertical-align: top;
  margin: 2px 10px 0 0; }
  .login-details .date {
    color: #aaa;
    font-size: 0.65em;
    line-height: 1.5; }
  .login-details .login-name {
    bottom: 5px;
    font-size: 0.9em;
    line-height: 1.2;
    overflow: hidden; }
    .login-details .login-name em {
      color: #234666;
      display: inline-block;
      font-style: normal;
      font-weight: bold;
      width: 52px; }

.non-fault-heading {
  font-family: "myriad-pro", arial;
  font-style: normal;
  font-size: 19px;
  font-style: italic;
  font-weight: 400;
  text-align: right; }
  .non-fault-heading span {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    vertical-align: bottom; }

#product-info-link {
  position: relative;
  z-index: 0; }
  #product-info-link p {
    margin: 0; }
  #product-info-link a {
    color: #fff;
    background: #3ab11a;
    display: block;
    margin: 40px 0 20px;
    padding: 10px;
    text-align: center; }
    #product-info-link a:hover {
      background: #2a930e; }

#home-refer {
  background: url(../img/home/refer-a-friend2.jpg) no-repeat 0 0;
  padding: 20px; }
  #home-refer h3 {
    margin: 0 0 10px; }
  #home-refer ul {
    list-style: none; }
    #home-refer ul li {
      background: url(../img/bull-tick-green.png) no-repeat 0 5px;
      color: #1c1c1b;
      font-weight: bold;
      font-size: 16px;
      padding: 0 0 0 20px; }
  #home-refer a {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #fff;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    position: absolute;
    right: 53px;
    text-transform: uppercase;
    text-align: center;
    top: 55px;
    width: 110px; }

#home-news h3 {
  margin: 0 0 20px; }

#home-news p {
  line-height: 21px;
  margin: 0 0 20px; }
  #home-news p.home-news-headline {
    color: #262c33;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 10px; }

#home-news a {
  color: #1c1c1b;
  font-weight: bold; }

#home-news ul li {
  list-style: none; }

#footer-email-signup {
  margin: 20px auto 30px;
  width: 740px; }
  #footer-email-signup div {
    float: left;
    margin: 0 20px 0 0; }
  #footer-email-signup input[type="email"] {
    border: 4px solid #e7edef;
    box-shadow: none;
    height: 40px; }
  #footer-email-signup input[type="submit"] {
    background: #3ab11a;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 0 20px;
    padding: 10px 40px; }
    #footer-email-signup input[type="submit"]:hover {
      background: #2a930e; }
  #footer-email-signup h4 {
    line-height: 20px;
    margin: 0; }

.footer-social {
  margin-top: 32px;
  margin-bottom: 35px;
  text-align: center; }

#quicklinks {
  overflow: hidden;
  width: 100%; }
  #quicklinks p {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #1c1c1b;
    font-size: 16px;
    margin: 0 0 10px; }
  #quicklinks ul {
    list-style: none; }
    #quicklinks ul li {
      font-size: 15px;
      line-height: 22px; }
      #quicklinks ul li.footer-tel {
        font-family: "bree", sans-serif;
        font-style: normal;
        font-size: 30px;
        font-weight: bold;
        color: #8c8c8c; }
  #quicklinks .red {
    color: red; }
  #quicklinks .appStore {
    display: block;
    margin-top: 25px; }
  #quicklinks input[type=submit] {
    margin-top: 24px; }

#quicklinks-title {
  text-align: center; }
  #quicklinks-title p {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #1c1c1b;
    font-size: 22px;
    margin: 0 0 30px; }

#bodies UL.logos {
  list-style-type: none; }
  #bodies UL.logos li {
    float: left;
    margin: 0 18px;
    display: block; }

#copyright p, #copyright li,
#flint p,
#flint li {
  font-family: arial, sans-serif;
  font-style: normal;
  font-size: 12px; }

#copyright ul,
#flint ul {
  list-style: none; }
  #copyright ul li,
  #flint ul li {
    border-right: 1px solid #8c8c8c;
    display: inline;
    margin: 0 5px 0 0;
    padding: 0 10px 0 0; }
    #copyright ul li.last,
    #flint ul li.last {
      border: none; }

.footer-testimonials {
  margin-bottom: 36px; }
  .footer-testimonials h4 {
    color: #1c1c1b;
    margin-bottom: 12px; }
  .footer-testimonials p {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #1a1f26;
    font-size: 18px; }

.footer-sponsors h4 {
  color: #1c1c1b;
  margin-bottom: 12px; }

.footer-sponsors ul {
  margin-bottom: 36px;
  text-align: center; }

.footer-sponsors li {
  display: inline-block;
  list-style: none;
  padding: 0 20px; }

.sitemap ul {
  margin: 0; }
  .sitemap ul li {
    list-style: none;
    margin: 0; }

#primaryNav.col8 li {
  width: 12.5%; }

/*	Top Level */
#primaryNav {
  margin: 0;
  float: left;
  width: 100%; }
  #primaryNav li {
    background: url("../img/sitemap/L1-center.png") center top no-repeat;
    float: left;
    margin-top: -30px;
    padding: 30px 0; }
    #primaryNav li a {
      border: 1px solid #ccc;
      display: block;
      color: black;
      font-size: 12px;
      font-weight: bold;
      margin: 0 20px 0 0;
      padding: 10px 5px;
      text-align: center; }
      #primaryNav li a:hover {
        background-color: #ccc; }
    #primaryNav li:last-child {
      background: url("../img/sitemap/L1-right.png") center top no-repeat; }
    #primaryNav li li {
      background: url("../img/sitemap/vertical-line.png") center bottom repeat-y;
      clear: left;
      margin-top: 0;
      padding: 10px 0 0;
      width: 100% !important; }
      #primaryNav li li a {
        background-color: #eee;
        font-weight: normal; }
        #primaryNav li li a:hover {
          background-color: #e7f1d7;
          border-color: #94b75f; }
        #primaryNav li li a:link:before, #primaryNav li li a:visited:before {
          color: #8faf5c; }
      #primaryNav li li:first-child {
        padding-top: 30px; }
      #primaryNav li li:first-child {
        background: url("../img/sitemap/vertical-line.png") center bottom repeat-y; }
      #primaryNav li li ul {
        background: #ffffff url("../img/sitemap/L3-ul-top.png") center top no-repeat;
        float: right;
        padding: 9px 0 10px 0;
        margin: 10px 0 0 0;
        width: 100%; }
        #primaryNav li li ul li {
          background: url("../img/sitemap/L3-center.png") left center no-repeat;
          padding: 5px 0;
          width: 100% !important; }
          #primaryNav li li ul li a {
            background-color: #eee;
            border-color: #ccc;
            float: right;
            font-size: 12px;
            padding: 5px 0;
            width: 80%; }
            #primaryNav li li ul li a:hover {
              background-color: #ddd;
              border-color: #ccc; }
            #primaryNav li li ul li a:link:before, #primaryNav li li ul li a:visited:before {
              color: #ccae14;
              font-size: 9px; }
          #primaryNav li li ul li:first-child {
            padding: 15px 0 5px 0;
            background: url("../img/sitemap/L3-li-top.png") left center no-repeat; }
          #primaryNav li li ul li:last-child {
            background: url("../img/sitemap/L3-bottom.png") left center no-repeat; }
  #primaryNav #home {
    background: #ffffff url("../img/sitemap/L1-left.png") center bottom no-repeat;
    display: block;
    float: none;
    padding: 0 0 30px 0;
    position: relative;
    z-index: 2; }

/* Utility Navigation */
.utilityNav {
  float: right;
  margin-right: 10px;
  max-width: 100%; }
  .utilityNav li {
    float: left;
    margin-bottom: 10px; }
    .utilityNav li a {
      background: #ccc;
      border: 1px solid #ccc;
      color: black;
      display: block;
      margin: 0 10px 0 0;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: bold;
      text-align: center; }
      .utilityNav li a:hover {
        background-color: #fff;
        border-color: #ccc; }
      .utilityNav li a:link:before, .utilityNav li a:visited:before {
        color: #ccae14;
        font-size: 9px;
        margin-bottom: 3px; }

/* 2 COLUMNS */
.p-table {
  float: left;
  height: 100%;
  margin: 10px 0 35px;
  width: 100%; }
  .p-table a.sign_up {
    margin: 0 auto;
    text-align: center; }
  .p-table b {
    color: #fff;
    display: block; }
  .p-table h4 {
    color: #fff;
    line-height: 20px;
    margin: 10px 0 0; }
  .p-table p {
    margin: 80px 0 15px 10px;
    display: block;
    font-weight: bold; }
  .p-table strong {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #fff;
    font-size: 30px; }
  .p-table ul {
    list-style-type: none; }
    .p-table ul li {
      margin: 0px; }
      .p-table ul li.footer_row {
        background-color: #e2e2e2;
        border: none;
        float: left;
        height: 75px;
        padding: 15px 0;
        width: 100%; }
        .p-table ul li.footer_row div {
          display: inline !important;
          text-align: center; }
        .p-table ul li.footer_row .button {
          border: none;
          padding: 15px; }
      .p-table ul li.row_style_2, .p-table ul li.row_style_4 {
        float: left;
        height: 100%;
        min-height: 26px;
        padding: 4px 0 3px !important;
        width: 100%; }
  .p-table .caption_column {
    float: left;
    height: 100%;
    width: 50%; }
    .p-table .caption_column li {
      text-align: left; }
  .p-table .header_row_1 {
    height: 100px;
    padding: 32px 0px 6px 10px;
    width: 100%; }
  .p-table .header_row_2 {
    background-color: #333;
    height: 100px;
    width: 100%;
    padding: 3px 0px 7px 0px; }
    .p-table .header_row_2 H4 {
      margin: 3px 0 0 0 !important; }
  .p-table .header_row_hhuk {
    background-color: #333;
    height: 100px;
    width: 100%;
    padding: 3px 0px 7px 0px !important; }
    .p-table .header_row_hhuk H4 {
      margin: 10px 0 0 0 !important; }
  .p-table .column_1, .p-table .column_2 {
    border-left: 1px solid #ccc;
    height: 100%;
    float: left;
    position: relative;
    transition: box-shadow 0.5s ease;
    -moz-transition: box-shadow 0.5s ease;
    -ms-transition: box-shadow 0.5s ease;
    -o-transition: box-shadow 0.5s ease;
    -webkit-transition: box-shadow 0.5s ease;
    width: 25%; }
    .p-table .column_1:hover, .p-table .column_2:hover {
      box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.5);
      left: 0px;
      margin: 0 0 -45px;
      position: relative;
      top: -15px;
      z-index: 100; }
    .p-table .column_1 li, .p-table .column_2 li {
      text-align: center; }
  .p-table .column_2 {
    border-right: 1px solid #ccc; }

.column_1:hover li.row_style_2, .column_2:hover li.row_style_2, .column_3:hover li.row_style_2,
.column_1:hover li.row_style_4, .column_2:hover li.row_style_4, .column_3:hover li.row_style_4 {
  padding: 7px 5px 3px !important; }

.column_1:hover li.header_row_2, .column_2:hover li.header_row_2,
.column_3:hover li.header_row_2 {
  padding: 5px 0 !important; }

.column_1:hover li.footer_row, .column_2:hover li.footer_row,
.column_3:hover li.footer_row {
  padding: 10px 0 0 !important; }

li.row_style_2 {
  background-color: #f6f6f6; }
  li.row_style_2 span {
    line-height: 26px;
    padding: 0 0 0 10px; }
    li.row_style_2 span.has-tip {
      font-weight: normal;
      padding: 0; }

li.row_style_4 {
  background-color: #e3e3e3; }
  li.row_style_4 span {
    line-height: 26px;
    padding: 0 0 0 10px; }
    li.row_style_4 span.has-tip {
      font-weight: normal;
      padding: 0; }

/* 3 COLUMNS */
.three-col-table span {
  font-size: 14px; }

.three-col-table .caption_column {
  float: left;
  height: 100%;
  width: 40%; }
  .three-col-table .caption_column li {
    text-align: left; }

.three-col-table .header_row_1 {
  height: 100px;
  padding: 32px 0px 6px 10px;
  width: 100%; }

.three-col-table .header_row_2 span {
  color: #fff;
  display: block; }
  .three-col-table .header_row_2 span.month-cost {
    font-size: 11px;
    line-height: 14px;
    margin: 12px 0 0; }
    .three-col-table .header_row_2 span.month-cost b {
      font-family: "bree-serif", sans-serif;
      font-style: normal;
      display: inline;
      font-size: 32px;
      font-weight: normal;
      margin: 0 5px 0 0; }
  .three-col-table .header_row_2 span.or {
    font-size: 13px;
    line-height: 16px; }
  .three-col-table .header_row_2 span.annual-cost {
    font-size: 13px;
    line-height: 14px;
    margin-top: 0; }
    .three-col-table .header_row_2 span.annual-cost b {
      display: inline;
      font-size: 14px;
      font-weight: bold; }

.three-col-table .column_1, .three-col-table .column_2, .three-col-table .column_3 {
  border-left: 1px solid #ccc;
  float: left;
  height: 100%;
  position: relative;
  transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  -webkit-transition: box-shadow 0.5s ease;
  width: 20%; }

.three-col-table .column_2, .three-col-table .columns_3 {
  border-right: 1px solid #ccc; }

.p1-table {
  float: left;
  height: 100%;
  margin: 10px 0 35px;
  width: 100%; }
  .p1-table a.sign_up {
    margin: 0 auto;
    text-align: center; }
  .p1-table b {
    color: #fff;
    display: block; }
  .p1-table h4 {
    color: #fff;
    line-height: 20px;
    margin: 10px 0 0; }
  .p1-table p {
    margin: 80px 0 15px 10px;
    display: block;
    font-weight: bold; }
  .p1-table strong {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #fff;
    font-size: 30px; }
  .p1-table ul {
    list-style-type: none; }
    .p1-table ul li {
      margin: 0px; }
      .p1-table ul li.footer_row {
        background-color: #e2e2e2;
        float: left;
        height: 75px;
        padding: 14px 0 6px;
        width: 100%; }
        .p1-table ul li.footer_row div {
          display: inline !important;
          text-align: center; }
      .p1-table ul li.row_style_2, .p1-table ul li.row_style_4 {
        float: left;
        height: 100%;
        min-height: 26px;
        padding: 4px 0 3px !important;
        width: 100%; }
  .p1-table .caption_column {
    float: left;
    height: 100%;
    width: 65%; }
    .p1-table .caption_column li {
      text-align: left; }
      .p1-table .caption_column li.header_row_1 {
        height: 100px;
        padding: 32px 0px 6px 10px !important;
        width: 100%; }
  .p1-table .header_row_2 {
    background-color: #361f59;
    border-top: 1px solid #d4ab17;
    border-bottom: 1px solid #d4ab17;
    height: 100px;
    width: 100%;
    padding: 3px 0px 7px 0px !important; }
    .p1-table .header_row_2 H4 {
      margin: 3px 0 0 0 !important; }
  .p1-table .header_row_travel {
    background-color: #361f59;
    border-top: 1px solid #361f59;
    border-bottom: 1px solid #361f59;
    height: 100px;
    width: 100%;
    padding: 3px 0px 7px 0px !important; }
    .p1-table .header_row_travel H4 {
      margin: 10px 0 0 0 !important; }
  .p1-table .column_1, .p1-table .column_2 {
    border-left: 1px solid #ccc;
    height: 100%;
    float: left;
    position: relative;
    transition: box-shadow 0.5s ease;
    -moz-transition: box-shadow 0.5s ease;
    -ms-transition: box-shadow 0.5s ease;
    -o-transition: box-shadow 0.5s ease;
    -webkit-transition: box-shadow 0.5s ease;
    width: 35%; }
    .p1-table .column_1:hover, .p1-table .column_2:hover {
      box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 5px 0px 30px rgba(0, 0, 0, 0.5);
      left: 0px;
      margin: 0 0 -45px;
      position: relative;
      top: -15px;
      z-index: 100; }
    .p1-table .column_1 li, .p1-table .column_2 li {
      text-align: center; }
  .p1-table .column_2 {
    border-right: 1px solid #ccc; }

.breakdown .p-table .header_row_1 {
  height: 80px; }

.breakdown .p-table .header_row_2 {
  background-color: #032d4a;
  height: 80px;
  padding-top: 8px; }

.breakdown .p-table .header_row_hhuk {
  background-color: #032d4a; }

.breakdown .p-table .footer_row .button {
  background: #032d4a; }

.home-emergency .p-table .header_row_2 {
  background-color: #f79132; }

.home-emergency .p-table .header_row_hhuk {
  background-color: #f79132; }

.home-emergency .p-table .footer_row .button {
  background: #f79132; }

.car-excess .p-table .header_row_2 {
  background-color: #55bb50; }
  .car-excess .p-table .header_row_2 h4 {
    font-size: 17px; }

.car-excess .p-table .header_row_hhuk {
  background-color: #55bb50; }

.car-excess .p-table .footer_row .button {
  background: #55bb50; }

.tyre .p-table .header_row_1 {
  height: 80px; }

.tyre .p-table .header_row_2 {
  background-color: #a2086e;
  height: 80px;
  padding-top: 8px; }

.tyre .p-table .header_row_2 {
  background-color: #a2086e; }

.tyre .p-table .header_row_hhuk {
  background-color: #a2086e; }

.tyre .p-table .footer_row .button {
  background: #a2086e; }

.mot .p-table .header_row_1 {
  height: 80px; }

.mot .p-table .header_row_2 {
  background-color: #d4ab17;
  height: 80px;
  padding-top: 8px; }

.mot .p-table .header_row_hhuk {
  background-color: #d4ab17; }

.mot .p-table .footer_row .button {
  background: #d4ab17; }

/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

/* BUTTON MIXINS */
/* END BUTTON MIXINS */
/* LIST MIXINS */
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

.btn-household {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #21557a; }
  .btn-household:hover {
    background: #21557a; }
  .btn-household.disabled {
    background: #27c5f2; }

.household ::selection {
  background: #21557a;
  color: #fff; }

.household ::-moz-selection {
  background: #21557a;
  color: #fff; }

.household h2, .household h3 {
  color: #21557a; }

.household .product-feature {
  background: url(../img/banner_household.png) no-repeat 0 0;
  background-size: cover; }
  .household .product-feature a {
    background: #21557a; }
    .household .product-feature a:hover {
      background: #53c6e7; }
  .household .product-feature h1 {
    color: #fff; }
  .household .product-feature p {
    color: #53c6e7; }

.household .left-sidebar li a:hover {
  border-bottom: 7px solid #21557a; }

.household .left-sidebar li a.active {
  background: #21557a;
  border-bottom: none; }

.household .left-sidebar #call-us strong {
  color: #21557a; }

.household .case-studies li a {
  color: #21557a; }
  .household .case-studies li a:hover {
    color: #21557a; }

.household .right-sidebar {
  background: #d3f2fb; }
  .household .right-sidebar h4 {
    color: #21557a; }
  .household .right-sidebar li {
    border-bottom-color: #21557a; }
    .household .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -695px; }

.household .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -695px;
  line-height: 24px; }

.household .round-message {
  background: #21557a; }

.household .postcode {
  left: -10px; }

.radio-green {
  margin: 0 10px 0 -5px; }
  .radio-green span:before {
    background-color: #21557a !important; }

.btn-home {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #f79132; }
  .btn-home:hover {
    background: #f79132; }

.home-emergency ::selection {
  background: #f79132;
  color: #fff; }

.home-emergency ::-moz-selection {
  background: #f79132;
  color: #fff; }

.home-emergency h1, .home-emergency h2, .home-emergency h3, .home-emergency h4 {
  color: #f79132; }

.home-emergency .product-feature {
  background: url(../img/banner_home.png) no-repeat 0 0;
  background-size: cover; }
  .home-emergency .product-feature a {
    background: #f79132; }
    .home-emergency .product-feature a:hover {
      background: #f79132; }
  .home-emergency .product-feature h1 {
    color: #fff; }
  .home-emergency .product-feature p {
    color: #f79132; }

.home-emergency .left-sidebar li a:hover {
  border-bottom: 7px solid #f79132; }

.home-emergency .left-sidebar li a.active {
  background: #f79132;
  border-bottom: none; }

.home-emergency .left-sidebar #call-us strong {
  color: #f79132; }

.home-emergency .case-studies li a {
  color: #f79132; }
  .home-emergency .case-studies li a:hover {
    color: #f79132; }

.home-emergency .right-sidebar {
  background: #e0f0df; }
  .home-emergency .right-sidebar h4 {
    color: #f79132; }
  .home-emergency .right-sidebar li {
    border-bottom-color: #f79132; }
    .home-emergency .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -945px; }

.home-emergency ul.feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -945px; }

.home-emergency .round-message {
  background: #f79132;
  /*display:none;*/ }

.radio-gold {
  margin: 0 10px 0 -5px; }
  .radio-gold span:before {
    background-color: #f79132 !important; }

.btn-gap {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #74c370; }
  .btn-gap:hover {
    background: #3e863b; }

.gap ::selection {
  background: #3e863b;
  color: #fff; }

.gap ::-moz-selection {
  background: #3e863b;
  color: #fff; }

.gap h2, .gap h3 {
  color: #3e863b; }

.gap .product-feature {
  background: url(../img/banner_gap.png) no-repeat 0 0;
  background-size: cover; }
  .gap .product-feature a {
    background: #74c370; }
    .gap .product-feature a:hover {
      background: #3e863b; }
  .gap .product-feature h1 {
    color: #fff; }
  .gap .product-feature p {
    color: #74c370; }

.gap .left-sidebar li a:hover {
  border-bottom: 7px solid #3e863b; }

.gap .left-sidebar li a.active {
  background: #3e863b;
  border-bottom: none; }

.gap .left-sidebar #call-us strong {
  color: #3e863b; }

.gap .case-studies li a {
  color: #3e863b; }
  .gap .case-studies li a:hover {
    color: #3e863b; }

.gap .right-sidebar {
  background: #e0f0df; }
  .gap .right-sidebar h4 {
    color: #3e863b; }
  .gap .right-sidebar .button {
    background: #74c370 !important; }
  .gap .right-sidebar li {
    border-bottom-color: #3e863b; }
    .gap .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -795px; }

.gap .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -795px; }

.gap .round-message {
  background: #3e863b; }

.gap .radio-orange {
  margin: 0 10px 0 -5px; }
  .gap .radio-orange span:before {
    background-color: #3e863b !important; }

table#quote-results-table tbody tr:nth-child(even) {
  background: none; }

.btn-travel {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #361f59; }
  .btn-travel:hover {
    background: #361f59; }

body .travel {
  margin-bottom: 24px;
  position: relative; }
  body .travel ::selection {
    background: #361f59;
    color: #fff; }
  body .travel ::-moz-selection {
    background: #361f59;
    color: #fff; }
  body .travel h1 {
    font-family: "bree", sans-serif;
    font-style: normal;
    font-size: 60px;
    line-height: 48px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -3px;
    color: #A2086E; }
  body .travel .middle-content h2.blackh2 {
    color: #333;
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 700;
    margin-top: 0px;
    letter-spacing: -1px;
    text-indent: 0px; }
  body .travel .insurees .missingInsurees {
    background-color: #fae8f4;
    text-align: center; }
  body .travel .insurees TABLE {
    width: 100%;
    margin: 0px;
    border: none;
    text-align: center;
    padding: 0px; }
    body .travel .insurees TABLE thead TR {
      background-color: white;
      color: #361f59;
      margin-bottom: 8px;
      font-family: "bree", sans-serif;
      padding-bottom: 8px; }
      body .travel .insurees TABLE thead TR TD {
        padding: 0px 0px 8px 0px;
        font-size: 16px;
        text-align: center; }
        body .travel .insurees TABLE thead TR TD.insureeName {
          width: 165px;
          text-align: left; }
        body .travel .insurees TABLE thead TR TD.insureeCategory {
          width: 95px; }
        body .travel .insurees TABLE thead TR TD.insureeAge {
          width: 55px; }
        body .travel .insurees TABLE thead TR TD.insureeHealth {
          width: 190px; }
        body .travel .insurees TABLE thead TR TD.insureeAdditions {
          width: 120px; }
    body .travel .insurees TABLE TR {
      background-color: #fae8f4;
      margin-bottom: 4px; }
      body .travel .insurees TABLE TR TD {
        padding: 10px;
        font-size: 16px;
        text-align: center;
        border-bottom: 5px solid white;
        color: #756f6f; }
        body .travel .insurees TABLE TR TD.policyHolderRemove {
          text-align: right; }
        body .travel .insurees TABLE TR TD.insureeName {
          text-align: left; }
        body .travel .insurees TABLE TR TD A {
          background-color: #361f59;
          padding: 0px 4px 0px 4px;
          color: white; }
  body .travel .priceBox {
    background-color: #fae8f4;
    padding: 20px;
    float: left;
    margin-bottom: 20px; }
    body .travel .priceBox H3 {
      width: 200px;
      float: left;
      margin: 0px; }
    body .travel .priceBox .quotePrice {
      float: left;
      color: #1b4464;
      font-size: 48px;
      font-weight: bold;
      margin-top: 30px; }
    body .travel .priceBox .btn-travel {
      margin-top: 20px;
      float: right; }
  body .travel h2, body .travel h3 {
    color: #361f59; }
  body .travel .checks > TABLE {
    border: none; }
  body .travel .checks > TABLE tbody tr:nth-child(even) {
    background: none; }
  body .travel .checks > TABLE tbody tr td {
    margin: 9px 0px;
    width: 135px; }
    body .travel .checks > TABLE tbody tr td SPAN {
      float: left;
      margin-right: 6px; }
    body .travel .checks > TABLE tbody tr td b {
      display: block;
      margin: 0 0 0 30px; }
    body .travel .checks > TABLE tbody tr td label p {
      margin: 0 0 0 40px; }
  body .travel .radios SPAN {
    float: left;
    margin-right: 10px; }
  body .travel .subText {
    font-size: small;
    float: left;
    margin-top: -7px; }
  body .travel .middle-content h4 {
    color: #361f59;
    font-weight: bold; }
  body .travel .product-feature {
    background: url(../img/banner_travel.png) no-repeat 0 0;
    background-size: cover; }
    body .travel .product-feature a {
      background: #361f59; }
      body .travel .product-feature a:hover {
        background: #bd98f4; }
    body .travel .product-feature h1 {
      color: #fff; }
    body .travel .product-feature p {
      color: #bd98f4; }
  body .travel .left-sidebar li a:hover {
    border-bottom: 7px solid #361f59; }
  body .travel .left-sidebar li a.active {
    background: #361f59;
    border-bottom: none; }
  body .travel .left-sidebar #call-us strong {
    color: #361f59; }
  body .travel .case-studies li a {
    color: #361f59; }
    body .travel .case-studies li a:hover {
      color: #361f59; }
  body .travel .right-sidebar h4 {
    color: #361f59; }
  body .travel .right-sidebar li {
    border-bottom-color: #361f59; }
    body .travel .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -645px; }
  body .travel .feature-list li {
    background-image: url(../img/sprite_lists.png);
    background-repeat: no-repeat;
    border-bottom: 1px solid #8c8c8c;
    font-size: 14px;
    list-style: none;
    padding: 3px 0 3px 18px;
    background-position: -4px -645px; }
  body .travel .round-message.soon {
    background: #361f59; }
  body .travel .radio-light-purple {
    margin: 0 10px 0 -5px; }
    body .travel .radio-light-purple span:before {
      background-color: #361f59 !important; }

.purpleSub {
  color: #361f59;
  font-weight: bold; }

#main-content .travel .selectPolicyType .radio {
  display: none; }

#main-content .travel .selectPolicyType label {
  color: #756f6f; }
  #main-content .travel .selectPolicyType label div {
    padding: 20px 0 20px 80px; }
  #main-content .travel .selectPolicyType label b {
    color: #361f59;
    display: block;
    font-size: 21px; }
  #main-content .travel .selectPolicyType label:hover {
    background: #361f59;
    color: #fff; }
    #main-content .travel .selectPolicyType label:hover b {
      color: #fff; }
    #main-content .travel .selectPolicyType label:hover div {
      color: #fff; }

#main-content .travel .selectPolicyType span.checked + label {
  background: #361f59;
  color: #fff; }
  #main-content .travel .selectPolicyType span.checked + label b {
    color: #fff; }
  #main-content .travel .selectPolicyType span.checked + label div {
    color: #fff; }

#main-content .travel .selectPolicyType #policySingle {
  background: url(../img/icon_single_trip.png) no-repeat 0 20px; }

#main-content .travel .selectPolicyType #policyAnnual {
  background: url(../img/icon_annual_trip.png) no-repeat 0 20px; }

#main-content .travel .selectPolicyType #policyAnnualWinter {
  background: url(../img/icon_annual_winter_trip.png) no-repeat 0 20px; }
  #main-content .travel .selectPolicyType #policyAnnualWinter span {
    font-weight: normal; }

#main-content .travel .selectPolicyType #policyBackpacker {
  background: url(../img/icon_backpacker_trip.png) no-repeat 0 20px; }

.travel.sticky .policySidebar {
  position: absolute; }

.travel.sticky .sticky-sidebar {
  width: 240px; }

.travel.sticky .nine.columns {
  margin-left: 240px; }

.sidebarPolicySummary {
  background: #f2cde5;
  margin-bottom: 24px;
  padding: 24px 20px; }
  .sidebarPolicySummary ul {
    list-style: none;
    margin: 0; }
    .sidebarPolicySummary ul li {
      font-size: 14px; }
  .sidebarPolicySummary p {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #234666;
    margin: 0; }
  .sidebarPolicySummary b {
    font-family: "myriad-pro", arial;
    font-style: normal;
    font-feature-settings: "liga" 1;
    -moz-font-feature-settings: "liga=1";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga" 1;
    -webkit-font-feature-settings: "liga" 1;
    color: #361f59;
    display: block;
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 24px; }

.btn-scratch {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #5a120b; }
  .btn-scratch:hover {
    background: #5a120b; }

.scratch ::selection {
  background: #5a120b;
  color: #fff; }

.scratch ::-moz-selection {
  background: #5a120b;
  color: #fff; }

.scratch h2, .scratch h3 {
  color: #5a120b; }

.scratch .product-feature {
  background: url(../img/banner_scratch.png) no-repeat 0 0;
  background-size: cover; }
  .scratch .product-feature a {
    background: #5a120b; }
    .scratch .product-feature a:hover {
      background: #5a120b; }
  .scratch .product-feature h1 {
    color: #fff; }
  .scratch .product-feature p {
    color: #5a120b; }

.scratch .left-sidebar li a:hover {
  border-bottom: 7px solid #5a120b; }

.scratch .left-sidebar li a.active {
  background: #5a120b;
  border-bottom: none; }

.scratch .left-sidebar #call-us strong {
  color: #5a120b; }

.scratch .case-studies li a {
  color: #5a120b; }
  .scratch .case-studies li a:hover {
    color: #5a120b; }

.scratch .right-sidebar {
  background: #e0f0df; }
  .scratch .right-sidebar h4 {
    color: #5a120b; }
  .scratch .right-sidebar li {
    border-bottom-color: #5a120b; }
    .scratch .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -1145px; }

.scratch .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -1145px; }

.scratch .round-message {
  background: #5a120b; }

.btn-excess {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #55bb50; }
  .btn-excess:hover {
    background: #55bb50; }

.car-excess ::selection {
  background: #55bb50;
  color: #fff; }

.car-excess ::-moz-selection {
  background: #55bb50;
  color: #fff; }

.car-excess h2, .car-excess h3 {
  color: #55bb50; }

.car-excess .product-feature {
  background: url(../img/banner_excess.png) no-repeat 0 0;
  background-size: cover; }
  .car-excess .product-feature a {
    background: #55bb50; }
    .car-excess .product-feature a:hover {
      background: #55bb50; }
  .car-excess .product-feature h1 {
    color: #fff; }
  .car-excess .product-feature p {
    color: #fff; }

.car-excess .left-sidebar li a:hover {
  border-bottom: 7px solid #55bb50; }

.car-excess .left-sidebar li a.active {
  background: #55bb50;
  border-bottom: none; }

.car-excess .left-sidebar #call-us strong {
  color: #55bb50; }

.car-excess .case-studies li a {
  color: #55bb50; }
  .car-excess .case-studies li a:hover {
    color: #55bb50; }

.car-excess .right-sidebar {
  background: #e0f0df; }
  .car-excess .right-sidebar h4 {
    color: #55bb50; }
  .car-excess .right-sidebar ul li {
    list-style: none;
    margin-left: 14px;
    position: relative; }
    .car-excess .right-sidebar ul li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -844px;
      border-bottom: none;
      content: "";
      height: 20px;
      left: -15px;
      position: absolute;
      top: -3px; }
    .car-excess .right-sidebar ul li:after {
      content: ""; }

.car-excess .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -844px; }

.car-excess .round-message {
  background: #55bb50; }

.engine-size {
  left: -60px; }

.btn-mot {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #d4ab17; }
  .btn-mot:hover {
    background: #d4ab17; }

.mot {
  overflow: hidden;
  width: 100%; }
  .mot .four.columns.colour-tier-option {
    float: left;
    margin-top: 48px;
    margin-left: 23%; }
  .mot ::selection {
    background: #d4ab17;
    color: #fff; }
  .mot ::-moz-selection {
    background: #d4ab17;
    color: #fff; }
  .mot h2, .mot h3 {
    color: #d4ab17; }
  .mot .product-feature {
    background: url(../img/banner_mot.png) no-repeat 0 0;
    background-size: cover; }
    .mot .product-feature a {
      background: #d4ab17; }
      .mot .product-feature a:hover {
        background: #d4ab17; }
    .mot .product-feature h1 {
      color: #fff; }
    .mot .product-feature p {
      color: #d4ab17; }
  .mot .left-sidebar li a:hover {
    border-bottom: 7px solid #d4ab17; }
  .mot .left-sidebar li a.active {
    background: #d4ab17;
    border-bottom: none; }
  .mot .left-sidebar #call-us strong {
    color: #d4ab17; }
  .mot .case-studies li a {
    color: #d4ab17; }
    .mot .case-studies li a:hover {
      color: #d4ab17; }
  .mot .right-sidebar {
    background: #e0f0df; }
    .mot .right-sidebar h4 {
      color: #d4ab17; }
    .mot .right-sidebar li {
      border-bottom-color: #d4ab17; }
      .mot .right-sidebar li:before {
        background-image: url(../img/sprite_lists.png);
        background-repeat: no-repeat;
        border-bottom: 1px solid #8c8c8c;
        font-size: 14px;
        list-style: none;
        padding: 3px 0 3px 18px;
        background-position: -4px -894px; }
  .mot .feature-list li {
    background-image: url(../img/sprite_lists.png);
    background-repeat: no-repeat;
    border-bottom: 1px solid #8c8c8c;
    font-size: 14px;
    list-style: none;
    padding: 3px 0 3px 18px;
    background-position: -4px -894px; }
  .mot .round-message {
    background: #d4ab17; }

/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

/* BUTTON MIXINS */
/* END BUTTON MIXINS */
/* LIST MIXINS */
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

.btn-alloy {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #9d1b0f; }
  .btn-alloy:hover {
    background: #9d1b0f; }

.row.alloy-options {
  margin-top: 48px; }

.alloy-wheel {
  overflow: hidden;
  width: 100%; }
  .alloy-wheel ::selection {
    background: #9d1b0f;
    color: #fff; }
  .alloy-wheel ::-moz-selection {
    background: #9d1b0f;
    color: #fff; }
  .alloy-wheel h2, .alloy-wheel h3 {
    color: #9d1b0f; }
  .alloy-wheel .product-feature {
    background: url(../img/banner_alloy.png) no-repeat 0 0;
    background-size: cover; }
    .alloy-wheel .product-feature a {
      background: #9d1b0f; }
      .alloy-wheel .product-feature a:hover {
        background: #ed2714; }
    .alloy-wheel .product-feature h1 {
      color: #fff; }
    .alloy-wheel .product-feature p {
      color: #fff; }
  .alloy-wheel .left-sidebar li a:hover {
    border-bottom: 7px solid #9d1b0f; }
  .alloy-wheel .left-sidebar li a.active {
    background: #9d1b0f;
    border-bottom: none; }
  .alloy-wheel .left-sidebar #call-us strong {
    color: #9d1b0f; }
  .alloy-wheel .case-studies li a {
    color: #9d1b0f; }
    .alloy-wheel .case-studies li a:hover {
      color: #9d1b0f; }
  .alloy-wheel .right-sidebar {
    background: #e0f0df; }
    .alloy-wheel .right-sidebar h4 {
      color: #9d1b0f; }
    .alloy-wheel .right-sidebar li {
      border-bottom-color: #9d1b0f; }
      .alloy-wheel .right-sidebar li:before {
        background-image: url(../img/sprite_lists.png);
        background-repeat: no-repeat;
        border-bottom: 1px solid #8c8c8c;
        font-size: 14px;
        list-style: none;
        padding: 3px 0 3px 18px;
        background-position: -4px -1045px; }
  .alloy-wheel .feature-list li {
    background-image: url(../img/sprite_lists.png);
    background-repeat: no-repeat;
    border-bottom: 1px solid #8c8c8c;
    font-size: 14px;
    list-style: none;
    padding: 3px 0 3px 18px;
    background-position: -4px -1045px; }
  .alloy-wheel .round-message {
    background: #9d1b0f; }

.btn-keys {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #fcb64f; }
  .btn-keys:hover {
    background: #fba11d; }

.keys ::selection {
  background: #fcb64f;
  color: #fff; }

.keys ::-moz-selection {
  background: #fcb64f;
  color: #fff; }

.keys h2, .keys h3 {
  color: #fcb64f; }

.keys .product-feature {
  background: url(../img/banner_keys.jpg) no-repeat 0 0;
  background-size: cover; }
  .keys .product-feature a {
    background: #fcb64f; }
    .keys .product-feature a:hover {
      background: #fba11d; }
  .keys .product-feature h1 {
    color: #234666; }
  .keys .product-feature p {
    color: #fcb64f; }

.keys .left-sidebar li a:hover {
  border-bottom: 7px solid #fcb64f; }

.keys .left-sidebar li a.active {
  background: #fcb64f;
  border-bottom: none; }

.keys .left-sidebar #call-us strong {
  color: #fcb64f; }

.keys .case-studies li a {
  color: #fcb64f; }
  .keys .case-studies li a:hover {
    color: #fcb64f; }

.keys .right-sidebar {
  background: #f6e4c9; }
  .keys .right-sidebar h4 {
    color: #fcb64f; }
  .keys .right-sidebar li {
    border-bottom-color: #fcb64f; }
    .keys .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -1241px; }

.keys .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -1241px; }

.keys .round-message {
  background: #fcb64f; }

.btn-caravan {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #fcb64f; }
  .btn-caravan:hover {
    background: #36274c; }

.caravan ::selection {
  background: #4e396d;
  color: #fff; }

.caravan ::-moz-selection {
  background: #4e396d;
  color: #fff; }

.caravan h2, .caravan h3 {
  color: #4e396d; }

.caravan .product-feature {
  background: url(../img/banner_caravan.jpg) no-repeat 0 0;
  background-size: cover; }
  .caravan .product-feature a {
    background: #4e396d; }
    .caravan .product-feature a:hover {
      background: #36274c; }
  .caravan .product-feature h1 {
    color: #234666; }
  .caravan .product-feature p {
    color: #8f77b2; }

.caravan .left-sidebar li a:hover {
  border-bottom: 7px solid #4e396d; }

.caravan .left-sidebar li a.active {
  background: #4e396d;
  border-bottom: none; }

.caravan .left-sidebar #call-us strong {
  color: #4e396d; }

.caravan .case-studies li a {
  color: #4e396d; }
  .caravan .case-studies li a:hover {
    color: #4e396d; }

.caravan .right-sidebar {
  background: #ebe3f7; }
  .caravan .right-sidebar h4 {
    color: #4e396d; }
  .caravan .right-sidebar li {
    border-bottom-color: #4e396d; }
    .caravan .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -1290px; }

.caravan .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -1290px; }

.caravan .round-message {
  background: #4e396d; }

/* 
 * Which Caravan
 * component.caravans
*/
.c-caravans {
  position: relative; }

.c-caravans__item {
  position: relative; }

.c-caravans__bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 418px; }

.c-caravans__item--touring .c-caravans__bg {
  background-image: url(../img/which-caravan-touring.jpg); }

.c-caravans__item--static .c-caravans__bg {
  background-image: url(../img/which-caravan-static.jpg); }

.c-caravans__detail {
  bottom: 0;
  left: 0;
  padding: 28px 35px;
  position: absolute;
  right: 0; }
  .c-caravans__detail h2 {
    color: #fff;
    font-size: 32px;
    margin-bottom: 30px; }
  .c-caravans__detail .button {
    border: 1px solid #fff;
    margin-right: 10px; }
    .c-caravans__detail .button:hover {
      background-color: rgba(255, 255, 255, 0.4); }

.btn-tyre {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #a2086e; }
  .btn-tyre:hover {
    background: #a2086e; }

.row.tyre-options {
  margin-top: 48px; }

.tyre {
  overflow: hidden;
  width: 100%; }
  .tyre ::selection {
    background: #a2086e;
    color: #fff; }
  .tyre ::-moz-selection {
    background: #a2086e;
    color: #fff; }
  .tyre h2, .tyre h3 {
    color: #a2086e; }
  .tyre .product-feature {
    background: url(../img/banner_tyre.png) no-repeat 0 0;
    background-size: cover; }
    .tyre .product-feature a {
      background: #a2086e; }
      .tyre .product-feature a:hover {
        background: #e7376b; }
    .tyre .product-feature h1 {
      color: #fff; }
    .tyre .product-feature p {
      color: #e7376b; }
  .tyre .left-sidebar li a:hover {
    border-bottom: 7px solid #a2086e; }
  .tyre .left-sidebar li a.active {
    background: #a2086e;
    border-bottom: none; }
  .tyre .left-sidebar #call-us strong {
    color: #a2086e; }
  .tyre .case-studies li a {
    color: #a2086e; }
    .tyre .case-studies li a:hover {
      color: #a2086e; }
  .tyre .right-sidebar {
    background: #e0f0df; }
    .tyre .right-sidebar h4 {
      color: #a2086e; }
    .tyre .right-sidebar li {
      border-bottom-color: #a2086e; }
      .tyre .right-sidebar li:before {
        background-image: url(../img/sprite_lists.png);
        background-repeat: no-repeat;
        border-bottom: 1px solid #8c8c8c;
        font-size: 14px;
        list-style: none;
        padding: 3px 0 3px 18px;
        background-position: -4px -994px; }
  .tyre .feature-list li {
    background-image: url(../img/sprite_lists.png);
    background-repeat: no-repeat;
    border-bottom: 1px solid #8c8c8c;
    font-size: 14px;
    list-style: none;
    padding: 3px 0 3px 18px;
    background-position: -4px -994px; }
  .tyre .round-message {
    background: #a2086e; }

.btn-breakdown {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #032d4a; }
  .btn-breakdown:hover {
    background: #032d4a; }

.breakdown h2, .breakdown h3 {
  color: #032d4a; }

.breakdown .product-feature {
  background: url(../img/banner_breakdown_summer.jpg) no-repeat 0 0;
  background-size: cover; }
  .breakdown .product-feature a {
    background: #032d4a; }
    .breakdown .product-feature a:hover {
      background: #032d4a; }
  .breakdown .product-feature h1 {
    color: #fff; }
  .breakdown .product-feature p {
    color: #032d4a; }

.breakdown .left-sidebar li a:hover {
  border-bottom: 7px solid #032d4a; }

.breakdown .left-sidebar li a.active {
  background: #032d4a;
  border-bottom: none; }

.breakdown .left-sidebar #call-us strong {
  color: #032d4a; }

.breakdown .case-studies li a {
  color: #032d4a; }
  .breakdown .case-studies li a:hover {
    color: #032d4a; }

.breakdown .right-sidebar {
  background: #e0f0df; }
  .breakdown .right-sidebar h4 {
    color: #032d4a; }
  .breakdown .right-sidebar li {
    border-bottom-color: #032d4a; }
    .breakdown .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -595px; }

.breakdown .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -595px; }

.breakdown .round-message {
  background: #032d4a; }

.btn-life {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #d4ab17; }
  .btn-life:hover {
    background: #d4ab17; }

.life ::selection {
  background: #d4ab17;
  color: #fff; }

.life ::-moz-selection {
  background: #d4ab17;
  color: #fff; }

.life h2, .life h3 {
  color: #d4ab17; }

.life .product-feature {
  background: url(../img/banner_life.png) no-repeat 0 0;
  background-size: cover; }
  .life .product-feature a {
    background: #d4ab17; }
    .life .product-feature a:hover {
      background: #d4ab17; }
  .life .product-feature h1 {
    color: #fff; }
  .life .product-feature p {
    color: #d4ab17; }
  .life .product-feature .banner-details {
    position: relative; }

.life .left-sidebar li a:hover {
  border-bottom: 7px solid #d4ab17; }

.life .left-sidebar li a.active {
  background: #d4ab17;
  border-bottom: none; }

.life .left-sidebar #call-us strong {
  color: #d4ab17; }

.life .middle-content h4 {
  color: #d4ab17;
  font-weight: bold; }

.life .case-studies li a {
  color: #d4ab17; }
  .life .case-studies li a:hover {
    color: #d4ab17; }

.life .right-sidebar {
  background: #e0f0df; }
  .life .right-sidebar h4 {
    color: #d4ab17; }
  .life .right-sidebar li {
    border-bottom-color: #d4ab17; }
    .life .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -1095px; }

.life .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -1095px; }

.life .round-message {
  background: #d4ab17; }

.btn-hire {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #d53f3b; }
  .btn-hire:hover {
    background: #d53f3b; }

.hire ::selection {
  background: #d53f3b;
  color: #fff; }

.hire ::-moz-selection {
  background: #d53f3b;
  color: #fff; }

.hire h2, .hire h3 {
  color: #d53f3b; }

.hire .product-feature {
  background: url(../img/banner-car-hire-excess.png) no-repeat 0 0;
  background-size: cover; }
  .hire .product-feature a {
    background: #d53f3b; }
    .hire .product-feature a:hover {
      background: #d53f3b; }
  .hire .product-feature h1 {
    color: #d53f3b; }
  .hire .product-feature p {
    color: #d53f3b; }
  .hire .product-feature .banner-details {
    position: relative; }

.hire .left-sidebar li a:hover {
  border-bottom: 7px solid #d53f3b; }

.hire .left-sidebar li a.active {
  background: #d53f3b;
  border-bottom: none; }

.hire .left-sidebar #call-us strong {
  color: #d53f3b; }

.hire .middle-content h4 {
  color: #d53f3b;
  font-weight: bold; }

.hire .case-studies li a {
  color: #d53f3b; }
  .hire .case-studies li a:hover {
    color: #d53f3b; }

.hire .right-sidebar {
  background: #e0f0df; }
  .hire .right-sidebar h4 {
    color: #d53f3b; }
  .hire .right-sidebar li {
    border-bottom-color: #d53f3b; }
    .hire .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -1095px; }

.hire .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -1095px; }

.hire .round-message {
  background: #d53f3b; }

.btn-warranty {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #124546; }
  .btn-warranty:hover {
    background: #124546; }

.warranty ::selection {
  background: #124546;
  color: #fff; }

.warranty ::-moz-selection {
  background: #124546;
  color: #fff; }

.warranty h2, .warranty h3 {
  color: #124546; }

.warranty .product-feature {
  background: url(../img/banner_warranty.png) no-repeat 0 0;
  background-size: cover; }
  .warranty .product-feature a {
    background: #124546; }
    .warranty .product-feature a:hover {
      background: #124546; }
  .warranty .product-feature h1 {
    color: #fff; }
  .warranty .product-feature p {
    color: #77e6e9; }

.warranty .left-sidebar li a:hover {
  border-bottom: 7px solid #124546; }

.warranty .left-sidebar li a.active {
  background: #124546;
  border-bottom: none; }

.warranty .left-sidebar #call-us strong {
  color: #124546; }

.warranty .case-studies li a {
  color: #124546; }
  .warranty .case-studies li a:hover {
    color: #124546; }

.warranty .right-sidebar {
  background: #e0f0df; }
  .warranty .right-sidebar h4 {
    color: #124546; }
  .warranty .right-sidebar li {
    border-bottom-color: #124546; }
    .warranty .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -745px; }

.warranty .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -745px; }

.warranty .round-message {
  background: #124546; }

.btn-travel {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #361f59; }
  .btn-travel:hover {
    background: #946aac; }

#nav-lifestyle-legal.selected {
  border-color: #007e81; }

body .lifestyle-legal {
  margin-bottom: 24px;
  position: relative; }
  body .lifestyle-legal ::selection {
    background: #007e81;
    color: #fff; }
  body .lifestyle-legal.quote-button {
    background: #007e81; }
    body .lifestyle-legal.quote-button:hover {
      background: #00c9ce;
      color: #fff; }
    body .lifestyle-legal.quote-button:active {
      background: #003335;
      color: #fff; }
  body .lifestyle-legal .p1-table .caption_column, body .lifestyle-legal .p1-table .column_1 {
    width: 50%; }
  body .lifestyle-legal ::-moz-selection {
    background: #007e81;
    color: #fff; }
  body .lifestyle-legal h1 {
    font-family: "bree", sans-serif;
    font-style: normal;
    font-size: 60px;
    line-height: 48px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -3px;
    color: #007e81; }
  body .lifestyle-legal .colour-container h3 {
    font-size: 20px;
    color: #007e81; }
  body .lifestyle-legal .colour-container p {
    color: #007e81; }
  body .lifestyle-legal .middle-content h2.blackh2 {
    color: #333;
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 700;
    margin-top: 0px;
    letter-spacing: -1px;
    text-indent: 0px; }
  body .lifestyle-legal .insurees .missingInsurees {
    background-color: #fae8f4;
    text-align: center; }
  body .lifestyle-legal .insurees TABLE {
    width: 100%;
    margin: 0px;
    border: none;
    text-align: center;
    padding: 0px; }
    body .lifestyle-legal .insurees TABLE thead TR {
      background-color: white;
      color: #007e81;
      margin-bottom: 8px;
      font-family: "bree", sans-serif;
      padding-bottom: 8px; }
      body .lifestyle-legal .insurees TABLE thead TR TD {
        padding: 0px 0px 8px 0px;
        font-size: 16px;
        text-align: center;
        color: #007e81; }
        body .lifestyle-legal .insurees TABLE thead TR TD.insureeName {
          width: 165px;
          text-align: left; }
        body .lifestyle-legal .insurees TABLE thead TR TD.insureeCategory {
          width: 95px; }
        body .lifestyle-legal .insurees TABLE thead TR TD.insureeAge {
          width: 55px; }
        body .lifestyle-legal .insurees TABLE thead TR TD.insureeHealth {
          width: 190px; }
        body .lifestyle-legal .insurees TABLE thead TR TD.insureeAdditions {
          width: 120px; }
    body .lifestyle-legal .insurees TABLE TR {
      background-color: #fae8f4;
      margin-bottom: 4px; }
      body .lifestyle-legal .insurees TABLE TR TD {
        padding: 10px;
        font-size: 16px;
        text-align: center;
        border-bottom: 5px solid white;
        color: #756f6f; }
        body .lifestyle-legal .insurees TABLE TR TD.policyHolderRemove {
          text-align: right; }
        body .lifestyle-legal .insurees TABLE TR TD.insureeName {
          text-align: left; }
        body .lifestyle-legal .insurees TABLE TR TD A {
          background-color: #007e81;
          padding: 0px 4px 0px 4px;
          color: white; }
  body .lifestyle-legal .priceBox {
    background-color: #fae8f4;
    padding: 20px;
    float: left;
    margin-bottom: 20px; }
    body .lifestyle-legal .priceBox H3 {
      width: 200px;
      float: left;
      margin: 0px; }
    body .lifestyle-legal .priceBox .quotePrice {
      float: left;
      color: #1b4464;
      font-size: 48px;
      font-weight: bold;
      margin-top: 30px; }
    body .lifestyle-legal .priceBox .btn-travel {
      margin-top: 20px;
      float: right; }
  body .lifestyle-legal h2, body .lifestyle-legal h3 {
    color: #007e81; }
  body .lifestyle-legal .checks > TABLE {
    border: none; }
  body .lifestyle-legal .checks > TABLE tbody tr:nth-child(even) {
    background: none; }
  body .lifestyle-legal .checks > TABLE tbody tr td {
    margin: 9px 0px;
    width: 135px; }
    body .lifestyle-legal .checks > TABLE tbody tr td SPAN {
      float: left;
      margin-right: 6px; }
    body .lifestyle-legal .checks > TABLE tbody tr td b {
      display: block;
      margin: 0 0 0 30px; }
    body .lifestyle-legal .checks > TABLE tbody tr td label p {
      margin: 0 0 0 40px; }
  body .lifestyle-legal .radios SPAN {
    float: left;
    margin-right: 10px; }
  body .lifestyle-legal .subText {
    font-size: small;
    float: left;
    margin-top: -7px; }
  body .lifestyle-legal .middle-content h4 {
    color: #007e81;
    font-weight: bold; }
  body .lifestyle-legal .product-feature {
    background: url(../img/banner_lifestylelegal.png) no-repeat 0 0;
    background-size: cover; }
    body .lifestyle-legal .product-feature a {
      background: #007e81; }
      body .lifestyle-legal .product-feature a:hover {
        background: #81b2bc; }
    body .lifestyle-legal .product-feature h1 {
      color: #fff; }
    body .lifestyle-legal .product-feature p {
      color: #81b2bc; }
  body .lifestyle-legal .left-sidebar li a:hover {
    border-bottom: 7px solid #007e81; }
  body .lifestyle-legal .left-sidebar li a.selected {
    border-color: #007e81; }
  body .lifestyle-legal .left-sidebar #call-us strong {
    color: #007e81; }
  body .lifestyle-legal .case-studies li a {
    color: #007e81; }
    body .lifestyle-legal .case-studies li a:hover {
      color: #007e81; }
  body .lifestyle-legal .right-sidebar h4 {
    color: #007e81; }
  body .lifestyle-legal .right-sidebar li {
    border-bottom-color: #007e81; }
    body .lifestyle-legal .right-sidebar li:before {
      background-image: url(../img/sprite_lists.png);
      background-repeat: no-repeat;
      border-bottom: 1px solid #8c8c8c;
      font-size: 14px;
      list-style: none;
      padding: 3px 0 3px 18px;
      background-position: -4px -1192px; }
  body .lifestyle-legal .feature-list li {
    background-image: url(../img/sprite_lists.png);
    background-repeat: no-repeat;
    border-bottom: 1px solid #8c8c8c;
    font-size: 14px;
    list-style: none;
    padding: 3px 0 3px 18px;
    background-position: -4px -1192px; }
  body .lifestyle-legal .round-message.soon {
    background: #007e81; }
  body .lifestyle-legal .radio-light-purple {
    margin: 0 10px 0 -5px; }
    body .lifestyle-legal .radio-light-purple span:before {
      background-color: #007e81 !important; }

.purpleSub {
  color: #007e81;
  font-weight: bold; }

#main-content .lifestyle-legal .selectPolicyType .radio {
  display: none; }

#main-content .lifestyle-legal .selectPolicyType label {
  color: #756f6f; }
  #main-content .lifestyle-legal .selectPolicyType label div {
    padding: 20px 0 20px 80px; }
  #main-content .lifestyle-legal .selectPolicyType label b {
    color: #007e81;
    display: block;
    font-size: 21px; }
  #main-content .lifestyle-legal .selectPolicyType label:hover {
    background: #007e81;
    color: #fff; }
    #main-content .lifestyle-legal .selectPolicyType label:hover b {
      color: #fff; }
    #main-content .lifestyle-legal .selectPolicyType label:hover div {
      color: #fff; }

#main-content .lifestyle-legal .selectPolicyType span.checked + label {
  background: #007e81;
  color: #fff; }
  #main-content .lifestyle-legal .selectPolicyType span.checked + label b {
    color: #fff; }
  #main-content .lifestyle-legal .selectPolicyType span.checked + label div {
    color: #fff; }

#main-content .lifestyle-legal .selectPolicyType #policySingle {
  background: url(../img/icon_single_trip.png) no-repeat 0 20px; }

#main-content .lifestyle-legal .selectPolicyType #policyAnnual {
  background: url(../img/icon_annual_trip.png) no-repeat 0 20px; }

#main-content .lifestyle-legal .selectPolicyType #policyAnnualWinter {
  background: url(../img/icon_annual_winter_trip.png) no-repeat 0 20px; }
  #main-content .lifestyle-legal .selectPolicyType #policyAnnualWinter span {
    font-weight: normal; }

#main-content .lifestyle-legal .selectPolicyType #policyBackpacker {
  background: url(../img/icon_backpacker_trip.png) no-repeat 0 20px; }

.lifestyle-legal.sticky .policySidebar {
  position: absolute; }

.lifestyle-legal.sticky .sticky-sidebar {
  width: 240px; }

.lifestyle-legal.sticky .nine.columns {
  margin-left: 240px; }

.sidebarPolicySummary {
  background: #f2cde5;
  margin-bottom: 24px;
  padding: 24px 20px; }
  .sidebarPolicySummary ul {
    list-style: none;
    margin: 0; }
    .sidebarPolicySummary ul li {
      font-size: 14px; }
  .sidebarPolicySummary p {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #234666;
    margin: 0; }
  .sidebarPolicySummary b {
    font-family: "myriad-pro", arial;
    font-style: normal;
    font-feature-settings: "liga" 1;
    -moz-font-feature-settings: "liga=1";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga" 1;
    -webkit-font-feature-settings: "liga" 1;
    color: #007e81;
    display: block;
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 24px; }
  .sidebarPolicySummary .quote-loading-side {
    position: absolute;
    left: 10px;
    top: 0;
    background-color: #f2cde5;
    width: 220px;
    padding: 20px 0 20px 80px;
    margin-bottom: 24px;
    height: 90px;
    opacity: 0.9;
    font-weight: bold;
    font-size: 1.2em;
    color: black; }

/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

/* BUTTON MIXINS */
/* END BUTTON MIXINS */
/* LIST MIXINS */
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

.btn-gadget {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #fee5f6; }
  .btn-gadget:hover {
    background: #a2086e; }

#nav-gadget.selected {
  border-color: #d53674; }

.gadget ::selection {
  background: #d53674;
  color: #fff; }

.gadget ::-moz-selection {
  background: #d53674;
  color: #fff; }

.gadget h2, .gadget h3 {
  color: #d53674; }

.gadget.quote-button {
  background: #d53674;
  width: 100% !important;
  padding: 12px; }
  .gadget.quote-button:hover {
    background: #e275a0;
    color: #fff; }
  .gadget.quote-button:active {
    background: #9e2152;
    color: #fff; }

.gadget .product-feature {
  background: url(../img/banner_gadget.png) no-repeat 0 0;
  /*background-size: cover;*/
  min-height: 260px;
  position: relative; }
  .gadget .product-feature #feature-copy {
    padding: 22px 10px 7px 30px; }
    .gadget .product-feature #feature-copy p {
      margin-bottom: 9px; }
  .gadget .product-feature .banner-details {
    height: 100%;
    position: absolute; }
  .gadget .product-feature h1 {
    color: #fff; }
  .gadget .product-feature p {
    color: #d53674; }
  .gadget .product-feature .coming-soon {
    background-color: #d53674; }

.gadget .left-sidebar li a:hover {
  border-bottom: 7px solid #d53674; }

.gadget .left-sidebar li a.selected {
  border-color: #d53674; }

.gadget .left-sidebar #call-us strong {
  color: #d53674; }

.gadget .case-studies li a {
  color: #d53674; }
  .gadget .case-studies li a:hover {
    color: #d53674; }

.gadget .right-sidebar h4 {
  color: #d53674;
  margin: 0; }

.gadget .right-sidebar .button {
  background: #fee5f6 !important; }

.gadget .right-sidebar li {
  border-bottom-color: #d53674; }
  .gadget .right-sidebar li:before {
    background-image: url(../img/sprite_lists.png);
    background-repeat: no-repeat;
    border-bottom: 1px solid #8c8c8c;
    font-size: 14px;
    list-style: none;
    padding: 3px 0 3px 18px;
    background-position: -4px -1095px; }

.gadget .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -1095px; }

.gadget .round-message {
  background: #d53674; }

.gadget .radio-orange {
  margin: 0 10px 0 -5px; }
  .gadget .radio-orange span:before {
    background-color: #d53674 !important; }

table#quote-results-table tbody tr:nth-child(even) {
  background: none; }

/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

/* BUTTON MIXINS */
/* END BUTTON MIXINS */
/* LIST MIXINS */
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

.btn-bicycle {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #f79132; }
  .btn-bicycle:hover {
    background: #fab97c;
    color: #fff; }

#nav-bicycle.selected {
  border-color: #f79132; }

.bicycle ::selection {
  background: #f79132;
  color: #fff; }

.bicycle ::-moz-selection {
  background: #f79132;
  color: #fff; }

.bicycle h2, .bicycle h3 {
  color: #f79132; }

.bicycle.quote-button {
  background: #f79132;
  padding: 12px; }
  .bicycle.quote-button:hover {
    background: #fab97c;
    color: #fff; }
  .bicycle.quote-button:active {
    background: #d46b08;
    color: #fff; }

.bicycle .call-and-advice {
  color: #1c1c1b;
  font-size: 18px; }
  .bicycle .call-and-advice span {
    color: #756f6f;
    font-size: 16px; }
  .bicycle .call-and-advice u {
    border-bottom: 1px solid #f79132;
    text-decoration: none; }

.bicycle .product-feature {
  background: url(../img/banner_bicycle.png) no-repeat 0 0;
  /*background-size: cover;*/
  min-height: 260px;
  position: relative; }
  .bicycle .product-feature #feature-copy {
    background: rgba(0, 0, 0, 0.7);
    padding: 22px 10px 7px 30px;
    min-height: 260px; }
    .bicycle .product-feature #feature-copy p {
      margin-bottom: 9px; }
  .bicycle .product-feature .banner-details {
    height: 100%;
    position: absolute; }
  .bicycle .product-feature h1 {
    color: #fff; }
  .bicycle .product-feature p {
    color: #f79132; }
  .bicycle .product-feature .coming-soon {
    background-color: #f79132; }

.bicycle .left-sidebar li a:hover {
  border-bottom: 1px solid #f79132; }

.bicycle .left-sidebar #call-us strong {
  color: #f79132; }

.bicycle .case-studies li a {
  color: #f79132; }
  .bicycle .case-studies li a:hover {
    color: #f79132; }

.bicycle .right-sidebar {
  background: #fff0e2 !important; }
  .bicycle .right-sidebar h4 {
    color: #f79132;
    font-size: 20px;
    margin: 0; }
  .bicycle .right-sidebar .button {
    background: #f79132 !important;
    padding-left: 12px;
    padding-right: 12px; }
  .bicycle .right-sidebar li {
    border-bottom-color: #f79132; }

.bicycle .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -945px;
  border-bottom: none; }

.bicycle .round-message {
  background: #f79132;
  bottom: -10px; }

.bicycle .radio-orange {
  margin: 0 10px 0 -5px; }
  .bicycle .radio-orange span:before {
    background-color: #f79132 !important; }

table#quote-results-table tbody tr:nth-child(even) {
  background: none; }

/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

/* BUTTON MIXINS */
/* END BUTTON MIXINS */
/* LIST MIXINS */
/* MAIN PRODUCT COLOURS */
/* Household */
/* Home Emergency */
/* Bicycle */
/* Caravan */
/* GAP */
/* GADGET */
/* Property */
/* Keys */
/* Travel */
/* Latest News */
/* Rent Guarantee */
/* Lifestyle Legal */
/* Scratch and Dent */
/* Car Excess */
/* MOT */
/* Alloy Wheel */
/* Tyre */
/* Breakdown Recovery */
/* Life Assurance */
/* Car Hire Excess */
/* Motor Warranty */
/* Courtesy Car */
/* LINKS AND BUTTON COLOURS */
.household {
  color: #21557a; }

.home {
  color: #f79132; }

.gap {
  color: #3e863b; }

.travel {
  color: #361f59; }

.scratch {
  color: #5a120b; }

.excess {
  color: #55bb50; }

.mot {
  color: #d4ab17; }

.keys {
  color: #fcb64f; }

.caravan {
  color: #4e396d; }

.alloy {
  color: #9d1b0f; }

.tyre {
  color: #a2086e; }

.breakdown {
  color: #032d4a; }

.life {
  color: #d4ab17; }

.hire {
  color: #d53f3b; }

.warranty {
  color: #124546; }

.btn-property-let {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #daf0f6; }
  .btn-property-let:hover {
    background: #daf0f6; }

#nav-property-let.selected {
  border-color: #0089ab; }

.property-let ::selection {
  background: #0089ab;
  color: #fff; }

.property-let ::-moz-selection {
  background: #0089ab;
  color: #fff; }

.property-let h2, .property-let h3 {
  color: #0089ab; }

.property-let.quote-button {
  background: #0089ab;
  width: 100% !important;
  padding: 12px; }
  .property-let.quote-button:hover {
    background: #00c6f8;
    color: #fff; }
  .property-let.quote-button:active {
    background: #004c5f;
    color: #fff; }

.property-let .product-feature {
  background: url(../img/banner_property-let.png) no-repeat 0 0;
  background-size: cover;
  min-height: 260px;
  position: relative; }
  .property-let .product-feature .banner-details {
    height: 100%;
    position: absolute; }
  .property-let .product-feature h1 {
    color: #fff; }
  .property-let .product-feature p {
    color: #0089ab; }
  .property-let .product-feature .coming-soon {
    background-color: #0089ab; }

.property-let .left-sidebar li a:hover {
  border-bottom: 7px solid #0089ab; }

.property-let .left-sidebar li a.selected {
  border-color: #0089ab; }

.property-let .left-sidebar #call-us strong {
  color: #0089ab; }

.property-let .case-studies li a {
  color: #0089ab; }
  .property-let .case-studies li a:hover {
    color: #0089ab; }

.property-let .right-sidebar h4 {
  color: #0089ab;
  margin: 0; }

.property-let .right-sidebar .button {
  background: #daf0f6 !important; }

.property-let .right-sidebar li {
  border-bottom-color: #0089ab; }
  .property-let .right-sidebar li:before {
    background-image: url(../img/sprite_lists.png);
    background-repeat: no-repeat;
    border-bottom: 1px solid #8c8c8c;
    font-size: 14px;
    list-style: none;
    padding: 3px 0 3px 18px;
    background-position: -4px -695px; }

.property-let .feature-list li {
  background-image: url(../img/sprite_lists.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #8c8c8c;
  font-size: 14px;
  list-style: none;
  padding: 3px 0 3px 18px;
  background-position: -4px -695px; }

.property-let .round-message {
  background: #0089ab; }

.property-let .radio-orange {
  margin: 0 10px 0 -5px; }
  .property-let .radio-orange span:before {
    background-color: #0089ab !important; }

table#quote-results-table tbody tr:nth-child(even) {
  background: none; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  /*background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;*/ }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    border: 1px solid #0072bc;
    border-radius: 4px; }
    .slick-dots li button {
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
    .slick-dots li.slick-active {
      background: #0072bc;
      color: black;
      opacity: 0.75; }

/* _component.download.scss */
.c-download__item {
  border-bottom: 1px solid #878787;
  color: #1c1c1b;
  display: inline-block;
  font-weight: 700;
  padding: 14px 12px;
  width: 100%; }
  .c-download__item:hover {
    background-color: #ebebeb; }

.c-download__purpose {
  color: #756f6f;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  float: right; }

/* _component.social */
.c-social {
  z-index: 11; }

.c-social--fixed {
  position: fixed;
  right: 0;
  top: 15%; }

.c-social__item {
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  display: block;
  position: relative;
  height: 48px;
  width: 48px; }

.c-social__item--facebook {
  background-image: url(../img/ui-social-facebook.png); }

.c-social__item--twitter {
  background-image: url(../img/ui-social-twitter.png); }

.c-social__item--google {
  background-image: url(../img/ui-social-google.png); }

.c-social__item--youtube {
  background-image: url(../img/ui-social-youtube.png); }

.c-social__item--documents {
  background-image: url(../img/ui-social-documents.png);
  height: 191px; }

.u-align-left {
  text-align: left; }

.u-align-center {
  text-align: center; }

.u-align-right {
  text-align: right; }

.u-bold {
  font-weight: bold; }

.u-extrabold {
  font-weight: bolder; }

.u-italic {
  font-style: italic; }

.u-uppercase {
  text-transform: uppercase; }

.u-bree {
  font-family: "bree", sans-serif; }

.u-text-small {
  font-size: 12px; }

.u-block {
  display: block; }

.u-inline-block {
  display: inline-block; }

.u-inline {
  display: inline; }

.u-hide {
  display: none !important; }

.u-space-t0 {
  margin-top: 0px; }

.u-space-r0 {
  margin-right: 0px; }

.u-space-b0 {
  margin-bottom: 0px; }

.u-space-l0 {
  margin-left: 0px; }

.u-space-x0 {
  margin-left: 0px;
  margin-right: 0px; }

.u-space-t1 {
  margin-top: 10px; }

.u-space-r1 {
  margin-right: 10px; }

.u-space-b1 {
  margin-bottom: 10px; }

.u-space-l1 {
  margin-left: 10px; }

.u-space-x1 {
  margin-left: 10px;
  margin-right: 10px; }

.u-space-t2 {
  margin-top: 20px; }

.u-space-r2 {
  margin-right: 20px; }

.u-space-b2 {
  margin-bottom: 20px; }

.u-space-l2 {
  margin-left: 20px; }

.u-space-x2 {
  margin-left: 20px;
  margin-right: 20px; }

.u-space-t3 {
  margin-top: 30px; }

.u-space-r3 {
  margin-right: 30px; }

.u-space-b3 {
  margin-bottom: 30px; }

.u-space-l3 {
  margin-left: 30px; }

.u-space-x3 {
  margin-left: 30px;
  margin-right: 30px; }

.u-space-t4 {
  margin-top: 40px; }

.u-space-r4 {
  margin-right: 40px; }

.u-space-b4 {
  margin-bottom: 40px; }

.u-space-l4 {
  margin-left: 40px; }

.u-space-x4 {
  margin-left: 40px;
  margin-right: 40px; }

.u-space-t5 {
  margin-top: 50px; }

.u-space-r5 {
  margin-right: 50px; }

.u-space-b5 {
  margin-bottom: 50px; }

.u-space-l5 {
  margin-left: 50px; }

.u-space-x5 {
  margin-left: 50px;
  margin-right: 50px; }

.u-space-t6 {
  margin-top: 60px; }

.u-space-r6 {
  margin-right: 60px; }

.u-space-b6 {
  margin-bottom: 60px; }

.u-space-l6 {
  margin-left: 60px; }

.u-space-x6 {
  margin-left: 60px;
  margin-right: 60px; }

.u-space-t7 {
  margin-top: 70px; }

.u-space-r7 {
  margin-right: 70px; }

.u-space-b7 {
  margin-bottom: 70px; }

.u-space-l7 {
  margin-left: 70px; }

.u-space-x7 {
  margin-left: 70px;
  margin-right: 70px; }

.u-space-t8 {
  margin-top: 80px; }

.u-space-r8 {
  margin-right: 80px; }

.u-space-b8 {
  margin-bottom: 80px; }

.u-space-l8 {
  margin-left: 80px; }

.u-space-x8 {
  margin-left: 80px;
  margin-right: 80px; }

.u-space-t9 {
  margin-top: 90px; }

.u-space-r9 {
  margin-right: 90px; }

.u-space-b9 {
  margin-bottom: 90px; }

.u-space-l9 {
  margin-left: 90px; }

.u-space-x9 {
  margin-left: 90px;
  margin-right: 90px; }

.u-space-t10 {
  margin-top: 100px; }

.u-space-r10 {
  margin-right: 100px; }

.u-space-b10 {
  margin-bottom: 100px; }

.u-space-l10 {
  margin-left: 100px; }

.u-space-x10 {
  margin-left: 100px;
  margin-right: 100px; }

.u-space-t11 {
  margin-top: 110px; }

.u-space-r11 {
  margin-right: 110px; }

.u-space-b11 {
  margin-bottom: 110px; }

.u-space-l11 {
  margin-left: 110px; }

.u-space-x11 {
  margin-left: 110px;
  margin-right: 110px; }

.u-space-t12 {
  margin-top: 120px; }

.u-space-r12 {
  margin-right: 120px; }

.u-space-b12 {
  margin-bottom: 120px; }

.u-space-l12 {
  margin-left: 120px; }

.u-space-x12 {
  margin-left: 120px;
  margin-right: 120px; }

.u-space-t13 {
  margin-top: 130px; }

.u-space-r13 {
  margin-right: 130px; }

.u-space-b13 {
  margin-bottom: 130px; }

.u-space-l13 {
  margin-left: 130px; }

.u-space-x13 {
  margin-left: 130px;
  margin-right: 130px; }

.u-space-t14 {
  margin-top: 140px; }

.u-space-r14 {
  margin-right: 140px; }

.u-space-b14 {
  margin-bottom: 140px; }

.u-space-l14 {
  margin-left: 140px; }

.u-space-x14 {
  margin-left: 140px;
  margin-right: 140px; }

.u-space-t15 {
  margin-top: 150px; }

.u-space-r15 {
  margin-right: 150px; }

.u-space-b15 {
  margin-bottom: 150px; }

.u-space-l15 {
  margin-left: 150px; }

.u-space-x15 {
  margin-left: 150px;
  margin-right: 150px; }

.u-pad-t0 {
  padding-top: 0px; }

.u-pad-r0 {
  padding-right: 0px; }

.u-pad-b0 {
  padding-bottom: 0px; }

.u-pad-l0 {
  padding-left: 0px; }

.u-pad-t1 {
  padding-top: 10px; }

.u-pad-r1 {
  padding-right: 10px; }

.u-pad-b1 {
  padding-bottom: 10px; }

.u-pad-l1 {
  padding-left: 10px; }

.u-pad-t2 {
  padding-top: 20px; }

.u-pad-r2 {
  padding-right: 20px; }

.u-pad-b2 {
  padding-bottom: 20px; }

.u-pad-l2 {
  padding-left: 20px; }

.u-pad-t3 {
  padding-top: 30px; }

.u-pad-r3 {
  padding-right: 30px; }

.u-pad-b3 {
  padding-bottom: 30px; }

.u-pad-l3 {
  padding-left: 30px; }

.u-pad-t4 {
  padding-top: 40px; }

.u-pad-r4 {
  padding-right: 40px; }

.u-pad-b4 {
  padding-bottom: 40px; }

.u-pad-l4 {
  padding-left: 40px; }

.u-pad-t5 {
  padding-top: 50px; }

.u-pad-r5 {
  padding-right: 50px; }

.u-pad-b5 {
  padding-bottom: 50px; }

.u-pad-l5 {
  padding-left: 50px; }

.u-pad-t6 {
  padding-top: 60px; }

.u-pad-r6 {
  padding-right: 60px; }

.u-pad-b6 {
  padding-bottom: 60px; }

.u-pad-l6 {
  padding-left: 60px; }

.u-pad-t7 {
  padding-top: 70px; }

.u-pad-r7 {
  padding-right: 70px; }

.u-pad-b7 {
  padding-bottom: 70px; }

.u-pad-l7 {
  padding-left: 70px; }

.u-pad-t8 {
  padding-top: 80px; }

.u-pad-r8 {
  padding-right: 80px; }

.u-pad-b8 {
  padding-bottom: 80px; }

.u-pad-l8 {
  padding-left: 80px; }

.u-pad-t9 {
  padding-top: 90px; }

.u-pad-r9 {
  padding-right: 90px; }

.u-pad-b9 {
  padding-bottom: 90px; }

.u-pad-l9 {
  padding-left: 90px; }

.u-pad-t10 {
  padding-top: 100px; }

.u-pad-r10 {
  padding-right: 100px; }

.u-pad-b10 {
  padding-bottom: 100px; }

.u-pad-l10 {
  padding-left: 100px; }

.u-pad-t11 {
  padding-top: 110px; }

.u-pad-r11 {
  padding-right: 110px; }

.u-pad-b11 {
  padding-bottom: 110px; }

.u-pad-l11 {
  padding-left: 110px; }

.u-pad-t12 {
  padding-top: 120px; }

.u-pad-r12 {
  padding-right: 120px; }

.u-pad-b12 {
  padding-bottom: 120px; }

.u-pad-l12 {
  padding-left: 120px; }

.u-pad-t13 {
  padding-top: 130px; }

.u-pad-r13 {
  padding-right: 130px; }

.u-pad-b13 {
  padding-bottom: 130px; }

.u-pad-l13 {
  padding-left: 130px; }

.u-pad-t14 {
  padding-top: 140px; }

.u-pad-r14 {
  padding-right: 140px; }

.u-pad-b14 {
  padding-bottom: 140px; }

.u-pad-l14 {
  padding-left: 140px; }

.u-pad-t15 {
  padding-top: 150px; }

.u-pad-r15 {
  padding-right: 150px; }

.u-pad-b15 {
  padding-bottom: 150px; }

.u-pad-l15 {
  padding-left: 150px; }

.u-clear {
  clear: both; }

.form-columns span.custom {
  display: none !important; }

.travel.sticky span.custom {
  display: none !important; }

.travel.sticky input[type=radio] {
  display: none !important; }

/************************
9. RIGHT SIDEBAR
************************/
.right-sidebar {
  background: #f0f0f0;
  margin: 0 0 15px;
  padding: 20px 20px 10px; }
  .right-sidebar h4 {
    font-size: 26px;
    margin-bottom: 6px; }
  .right-sidebar .sub-title {
    font-family: "bree", sans-serif;
    font-style: normal;
    font-size: 21px;
    margin-bottom: 6px; }
  .right-sidebar ul {
    list-style: none; }
  .right-sidebar li {
    font-size: 16px;
    line-height: 24px;
    padding: 18px 0;
    border-bottom: 1px solid #c0c0c0; }
    .right-sidebar li:before {
      border-bottom: none;
      content: "";
      border: none !important;
      display: inline-block;
      height: 20px;
      /*left: -15px;*/
      position: relative;
      /*top: -3px;*/ }
    .right-sidebar li:after {
      content: ""; }
    .right-sidebar li:last-child {
      border-bottom: none;
      padding-bottom: 0; }

.paperless {
  background: #f0f0f0 !important; }
  .paperless h4 {
    color: #333 !important; }
  .paperless p {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #333;
    font-size: 21px;
    margin-bottom: 6px; }

.case-studies {
  background: #f0f0f0;
  margin: 0 0 15px;
  padding: 20px 20px 10px; }
  .case-studies h4 {
    color: #333;
    font-size: 26px;
    margin-bottom: 6px; }
  .case-studies ul {
    list-style: none; }
  .case-studies li {
    font-size: 18px;
    line-height: 24px;
    padding: 18px 0;
    border-bottom: 1px solid #c0c0c0; }
    .case-studies li:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    .case-studies li h5 {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 24px;
      color: #333 !important; }
    .case-studies li a {
      font-size: 16px; }

/*************************
CONTENTS

1.	MAIN COLOURS
2.	BUTTONS
3.	HIGHLIGHTS & OUTLINES
4.	BASE
5.	HEADER
6.	NAVIGATION
7.	a. HOME SLIDER BASE
	b. HOME SLIDER TEXT
8.	LEFT SIDEBAR
9.	RIGTH SIDEBAR
10. HOMEPAGE
11. FOOTER
12. MY ACCOUNT
13. RETRIEVE A QUOTE
14. FEATURE SECTION ON INNER HOMEPAGES
15. TABLES
16. PRICING TABLES
17. CUSTOMER SERVICES
18. NEWS
*************************/
/************************
COUNTRY SELECT MODAL
************************/
#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: none !important; }

#country-select-content {
  overflow: hidden;
  padding: 0 0 20px;
  text-align: center; }
  #country-select-content h3 {
    color: #58585a;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px; }
  #country-select-content p {
    color: #5f5f5f;
    font-size: 13px;
    margin-top: 12px; }

.country-select {
  background: url(../images/globe.png) no-repeat 0 center;
  color: #999;
  float: left;
  margin: 15px 0 0;
  padding-left: 26px; }
  .country-select img {
    height: 12px;
    margin-left: 5px; }

.region-off {
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 36px auto 0;
  width: 140px; }
  .region-off a {
    background: none;
    display: block;
    padding: 20px 15px 10px 15px;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  .region-off .region-off a:hover {
    background: #eee; }

body .fancybox-close {
  background: #005697;
  border: 4px solid;
  border-radius: 22px;
  color: #fff;
  font-size: 22px;
  position: absolute;
  top: -18px;
  right: -18px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 8040; }

body .fancybox-close::before {
  content: 'x';
  left: 8px;
  position: absolute;
  top: 0; }

/************************
1. MAIN COLOURS
************************/
.black {
  color: #1c1c1b; }

.white {
  color: #fff; }

.base-green {
  color: #3ab11a; }

.base-blue {
  color: #234666; }

/************************
2. BUTTONS
************************/
.button {
  font-size: 16px; }

.quote-button {
  border: 0;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 12px 20px;
  text-align: center;
  width: 193px; }

.bigRed {
  background-color: Red; }

.btn-base-green {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #3ab11a; }
  .btn-base-green:hover {
    background: #2a930e; }

.btn-base-blue {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #234666; }
  .btn-base-blue:hover {
    background: #0f283e; }

.btn-black {
  background: #1c1c1b;
  border: none; }
  .btn-black:hover {
    background: #333; }

/************************
3. HIGHLIGHTS & OUTLINES
************************/
::selection {
  background: #3ab11a;
  color: #fff; }

::-moz-selection {
  background: #3ab11a;
  color: #fff; }

textarea:focus, input:focus {
  outline: #8c8c8c; }

*:focus {
  outline: #8c8c8c; }

/************************
4. BASE
************************/
.mt10 {
  margin-top: 10px; }

.mt20 {
  margin-top: 20px; }

.mt30 {
  margin-top: 30px; }

small {
  font-size: 80%; }

.none {
  display: none; }

h1, h2, h3, h4, h5, h6 {
  color: #234666; }

body {
  font-family: "myriad-pro", arial;
  font-style: normal; }

a {
  color: #8c8c8c; }
  a:hover, a.active {
    color: #234666; }

p.copy-title {
  font-weight: bold;
  margin: 0; }

p.first-paragraph {
  margin: 24px 0 0; }

#logo {
  margin: 20px 0 0; }
  #logo a {
    display: block; }

#main-content {
  margin-top: 30px;
  position: relative;
  z-index: 0; }
  #main-content h3 {
    margin: 10px 0 15px; }

#main-content h2, #main-content h4 {
  margin: 0 0 20px; }

table ol {
  margin: 0 0 0 20px; }

ol {
  margin-left: 0; }

.list-basic {
  font-size: 12px;
  padding: 5px 0 5px 18px; }

.list-nobull {
  font-size: 12px;
  list-style: none;
  padding: 0; }

input[type="submit"].button {
  font-size: 16px; }

input[type="text"][disabled] {
  background-color: white;
  border: none;
  color: black; }

ul.accordion li.active {
  border-top: 3px solid #3ab11a; }

ul.accordion li .title {
  font-size: 13px;
  padding: 10px 30px 10px 15px !important; }
  ul.accordion li .title h5 {
    font-family: "bree-serif", sans-serif;
    font-style: normal;
    color: #333;
    font-size: 18px;
    line-height: 20px; }

ul.accordion p {
  font-family: "myriad-pro", arial;
  font-style: normal;
  font-size: 16px; }

input[type=submit], button {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  font-family: "myriad-pro", arial;
  font-style: normal;
  cursor: pointer;
  font-size: 16px; }

.table-select-policy {
  overflow: hidden;
  width: 100%; }
  .table-select-policy table {
    width: 100%; }
    .table-select-policy table tr td, .table-select-policy table tr, .table-select-policy table th {
      color: #555;
      font-size: 18px;
      padding: 15px 10px; }
      .table-select-policy table tr td:last-child, .table-select-policy table tr:last-child, .table-select-policy table th:last-child {
        text-align: center; }
  .table-select-policy button {
    float: right; }

#main-content .productAdditions {
  padding: 0; }
  #main-content .productAdditions ol.formv2 {
    list-style-type: none;
    margin-left: 188px;
    overflow: hidden;
    width: auto; }
    #main-content .productAdditions ol.formv2 li {
      float: left;
      width: 40%; }
      #main-content .productAdditions ol.formv2 li span.checkbox {
        float: left;
        margin-right: 10px; }
      #main-content .productAdditions ol.formv2 li input {
        float: left;
        margin: 6px 5px 0 0; }
      #main-content .productAdditions ol.formv2 li label {
        float: left; }
  #main-content .productAdditions table {
    width: 100%; }

/************************
8. LEFT SIDEBAR
************************/
.left-sidebar ul {
  list-style: none; }
  .left-sidebar ul li {
    margin: 0 0 1px; }
    .left-sidebar ul li a {
      border-bottom: 1px solid #ccc;
      color: #8c8c8c;
      display: block;
      padding: 12px 0; }
      .left-sidebar ul li a:hover {
        border-bottom: 1px solid #8c8c8c; }
      .left-sidebar ul li a.selected {
        color: #1c1c1b;
        font-weight: bold; }

#call-us {
  font-family: "bree", sans-serif;
  font-style: normal;
  margin: 20px 0 0; }
  #call-us b {
    color: #8c8c8c;
    display: block;
    font-size: 17px; }
  #call-us span {
    color: #333;
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 10px; }
  #call-us strong {
    color: #234666;
    display: block;
    font-size: 34px; }

/************************
12. MY ACCOUNT
************************/
dl.table-display {
  margin: 10px 0; }
  dl.table-display:before, dl.table-display:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  dl.table-display:after {
    clear: both; }
  dl.table-display dt {
    clear: left;
    float: left;
    font-weight: bold;
    margin: 5px 0;
    width: 100px; }
  dl.table-display dd {
    float: left;
    margin: 5px 0; }

.facebook-social .connection-details {
  background: #577ac3; }

.twitter-social .connection-details {
  background: #55bcef; }

.social-connection {
  margin: 10px 0; }
  .social-connection a {
    position: relative;
    display: block; }
  .social-connection .connection-details {
    height: 50px;
    max-height: 50px;
    padding: 1px 0; }
    .social-connection .connection-details p {
      color: #fff;
      margin: 5px 0 0;
      line-height: 18px;
      padding: 0; }
    .social-connection .connection-details a {
      font-family: "bree", sans-serif;
      font-style: normal;
      color: #fff; }
  .social-connection img {
    float: left;
    margin: 0 10px 0 0; }
  .social-connection span {
    left: 33px;
    position: absolute;
    top: 33px; }

.buttonRight {
  float: right; }

.buttonRight a:hover {
  color: #fff; }

/************************
13. RETRIEVE A QUOTE
************************/
#retrieve-modal {
  text-align: left; }
  #retrieve-modal h3 {
    margin: 0 0 10px; }

#retrieve-quote {
  margin-top: 20px; }
  #retrieve-quote .facebook {
    text-align: center; }

#btn-register {
  border: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
  background: #8c8c8c;
  display: block;
  margin-top: 20px; }
  #btn-register:hover {
    background: #333; }

.or-separator-onlight {
  margin: 0; }
  .or-separator-onlight p {
    color: #8c8c8c;
    margin: 10px 0; }
  .or-separator-onlight span {
    background: #1c1c1b;
    display: block;
    left: 30px;
    min-height: 70px;
    position: relative;
    width: 1px; }

.outside-labels {
  text-align: left; }
  .outside-labels p {
    font-weight: bold;
    margin: 0; }
  .outside-labels hr {
    margin: 5px 0 10px; }

/************************
14. FEATURE SECTION ON INNER HOMEPAGES
************************/
.product-feature {
  background-color: #ccc;
  margin: -10px 0 30px;
  min-height: 250px; }
  .product-feature .banner-details {
    float: left;
    width: 58.333333%; }
  .product-feature #feature-copy {
    background: url(../img/bg-grey-transp.png) 0 0;
    padding: 35px 10px 15px 30px;
    min-height: 250px; }
    .product-feature #feature-copy p {
      font-size: 21px;
      font-weight: bold; }
    .product-feature #feature-copy li {
      color: #fff; }
  .product-feature .coming-soon {
    border-radius: 57px;
    bottom: -10px;
    height: 113px;
    left: 50px;
    padding-top: 25px;
    position: absolute;
    width: 113px; }
    .product-feature .coming-soon h4 {
      font-weight: bold;
      line-height: 22px; }
  .product-feature h1 {
    color: #fff;
    margin: 30px 0 12px 40px; }
  .product-feature a {
    border: 0;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    padding: 12px 20px;
    text-align: center;
    bottom: 60px;
    font-size: 16px;
    left: 42px;
    margin: 0;
    padding: 12px 20px;
    position: absolute;
    width: 193px; }
    .product-feature a:hover {
      border: 0;
      color: #fff;
      display: inline-block;
      font-weight: 600;
      padding: 12px 20px;
      text-align: center;
      padding: 12px 20px; }

/************************
15. TABLES
************************/
.colour-tier-option {
  text-align: center; }
  .colour-tier-option p {
    font-family: "bree", sans-serif;
    font-style: normal;
    font-size: 24px;
    margin: 0 0 5px; }
  .colour-tier-option ul {
    list-style: none;
    margin: 0 auto 10px;
    width: 100%; }
    .colour-tier-option ul:before, .colour-tier-option ul:after {
      content: "\0020";
      display: block;
      height: 0;
      overflow: hidden; }
    .colour-tier-option ul:after {
      clear: both; }
    .colour-tier-option ul li {
      display: inline; }
      .colour-tier-option ul li.per-mth strong {
        font-size: 24px; }
      .colour-tier-option ul li.per-mth span {
        display: inline-block;
        font-size: 11px;
        line-height: 11px;
        margin: 0 0 0 3px; }
      .colour-tier-option ul li.per-or {
        font-size: 12px;
        margin: 0 10px; }
      .colour-tier-option ul li.per-yr strong {
        font-size: 24px; }
      .colour-tier-option ul li.per-yr span {
        display: inline-block;
        font-size: 11px;
        line-height: 11px;
        margin: 0 0 0 3px; }

table.benefits {
  border-collapse: collapse;
  border: none; }
  table.benefits td {
    border-bottom: 1px solid #ccc; }
    table.benefits td.cell-left {
      overflow: hidden;
      white-space: nowrap;
      border-right: 1px solid #ccc; }
    table.benefits td.first {
      border-top: 1px solid #ccc; }

#quote-results-table td {
  padding: 30px 0;
  text-align: center; }
  #quote-results-table td b {
    font-size: 16px; }
  #quote-results-table td p {
    font-size: 30px;
    font-weight: bold;
    margin: 5px 0 0; }

/* Form Styles */
.form-columns h4 {
  margin: 15px 0; }

.form-columns input, .form-columns label, .form-columns p {
  font-size: 14px; }

.form-columns ul, .form-columns .custom.dropdown {
  width: 100% !important; }

/************************
17. CUSTOMER SERVICES
************************/
.arrow-bull {
  font-weight: bold;
  margin: 0 0 0px; }

/************************
18. NEWS
************************/
.news-article {
  border-bottom: 1px dotted #234666;
  margin: 0 0 20px;
  padding: 0 0 10px;
  list-style: none; }
  .news-article .title:before, .news-article .title:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .news-article .title:after {
    clear: both; }
  .news-article h4 {
    /*float: left;*/ }
  .news-article b {
    float: right;
    line-height: 35px; }
  .news-article .feature-img {
    margin: 15px 0 20px 0; }

.page-testimonials article {
  border-bottom: 1px solid #ccc;
  padding-top: 24px; }

.testimonial-customer {
  color: black;
  font-weight: bold;
  margin: 24px 0; }

div.pager {
  float: right;
  margin: 5px 0px 0px 0px;
  font-size: 0.9em; }

.previous, .next {
  border: none;
  padding: 0px 10px 2px 10px;
  background-color: #234666;
  text-decoration: none;
  color: #FFF;
  float: left;
  margin-right: 1px;
  font-weight: normal; }

.next {
  margin-left: 1px; }

.pager ul.pages {
  float: left;
  margin: 0;
  padding: 0; }

.pager ul.pages li {
  float: left;
  list-style-type: none;
  background-color: #234666;
  margin: 0px 1px 0px 1px;
  padding: 1px 10px;
  text-align: center; }

.pager ul.pages li.active, .pager ul.pages li.active A {
  background-color: #0f283e;
  color: #fff;
  font-weight: normal; }

.pager ul.pages li a, .pager .previous a, .pager .next a {
  color: #fff;
  font-weight: normal; }

.pager ul.pages li a:hover, .pager .previous a:hover, .pager .next a:hover {
  text-decoration: none; }

.paging {
  float: left; }

.round-message {
  -moz-border-radius: 55px;
  -o-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 55px;
  background: #f79132;
  bottom: -120px;
  height: 110px;
  left: 60px;
  position: absolute;
  width: 110px; }
  .round-message p {
    font-family: "bree", sans-serif;
    font-style: normal;
    color: #fff !important;
    font-size: 21px;
    font-weight: 600;
    padding: 30px 0 0;
    text-align: center; }
    .round-message p span {
      font-size: 42px; }

.round-message.soon p {
  font-weight: 600;
  line-height: 24px;
  padding-top: 20px; }

.nine.columns h3 {
  font-weight: bold; }

.btnHazardList {
  background: url(../img/icon_hazard_list.png) no-repeat left 5px;
  font-size: 14px;
  position: relative;
  width: 210px; }
  .btnHazardList span {
    display: block;
    line-height: 16px;
    margin-left: 34px; }
    .btnHazardList span a {
      color: #361f59; }
      .btnHazardList span a:hover {
        color: #361f59; }

#modal-hazardous {
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  height: 600px;
  overflow-y: scroll; }
  #modal-hazardous .five.columns {
    padding: 0; }
  #modal-hazardous li {
    list-style: none;
    margin: 0 0 5px 0; }

.change-policy {
  background: #e3effa url(../img/bg-change-policy.jpg) right center no-repeat;
  padding: 7px; }
  .change-policy a {
    color: #234666;
    font-weight: bold; }
  .change-policy h4 {
    color: #234666;
    font-weight: bold;
    margin: 0 0 10px !important;
    padding: 10px 0 0 10px; }
  .change-policy p {
    padding: 0 0 0 10px; }

.product-section .four {
  margin-bottom: 30px; }
  .product-section .four h4 {
    font-weight: bold;
    margin-bottom: 0 !important; }
  .product-section .four ul {
    list-style: none; }
    .product-section .four ul li {
      padding: 0 0 5px 25px;
      position: relative; }
      .product-section .four ul li a:before {
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #ccc;
        content: "";
        height: 0;
        left: 0;
        top: 5px;
        position: absolute;
        width: 0; }

.product-section .breakdown h4 a {
  color: #032d4a; }

.product-section .breakdown ul li a:hover {
  color: #032d4a; }

.product-section .breakdown ul li a:before {
  border-left-color: #032d4a; }

.product-section .travel h4 a {
  color: #361f59; }

.product-section .travel ul li a:hover {
  color: #361f59; }

.product-section .travel ul li a:before {
  border-left-color: #361f59; }

.product-section .household h4 a {
  color: #21557a; }

.product-section .household ul li a:hover {
  color: #21557a; }

.product-section .household ul li a:before {
  border-left-color: #21557a; }

.product-section .warranty h4 a {
  color: #124546; }

.product-section .warranty ul li a:hover {
  color: #124546; }

.product-section .warranty ul li a:before {
  border-left-color: #124546; }

.product-section .gap h4 a {
  color: #3e863b; }

.product-section .gap ul li a:hover {
  color: #3e863b; }

.product-section .gap ul li a:before {
  border-left-color: #3e863b; }

.product-section .excess h4 a {
  color: #55bb50; }

.product-section .excess ul li a:hover {
  color: #55bb50; }

.product-section .excess ul li a:before {
  border-left-color: #55bb50; }

.product-section .mot h4 a {
  color: #d4ab17; }

.product-section .mot ul li a:hover {
  color: #d4ab17; }

.product-section .mot ul li a:before {
  border-left-color: #d4ab17; }

.product-section .home h4 a {
  color: #f79132; }

.product-section .home ul li a:hover {
  color: #f79132; }

.product-section .home ul li a:before {
  border-left-color: #f79132; }

.product-section .life h4 a {
  color: #d4ab17; }

.product-section .life ul li a:hover {
  color: #d4ab17; }

.product-section .life ul li a:before {
  border-left-color: #d4ab17; }

.product-section .alloy h4 a {
  color: #9d1b0f; }

.product-section .alloy ul li a:hover {
  color: #9d1b0f; }

.product-section .alloy ul li a:before {
  border-left-color: #9d1b0f; }

.product-section .tyre h4 a {
  color: #a2086e; }

.product-section .tyre ul li a:hover {
  color: #a2086e; }

.product-section .tyre ul li a:before {
  border-left-color: #a2086e; }

.product-section .scratch h4 a {
  color: #5a120b; }

.product-section .scratch ul li a:hover {
  color: #5a120b; }

.product-section .scratch ul li a:before {
  border-left-color: #5a120b; }

/************************
27. 10% DISCOUNT
************************/
.home-emergency .discount10 {
  background: #fbedb5;
  display: none; }
  .home-emergency .discount10 h4 {
    margin-bottom: 0 !important; }
  .home-emergency .discount10 .sub-title {
    color: #1c1c1b;
    font-size: 20px;
    margin-bottom: 10px; }
  .home-emergency .discount10 p {
    margin-bottom: 0; }
  .home-emergency .discount10 i {
    color: #f79132; }

#vehTypes li {
  list-style: none; }
  #vehTypes li input[type=radio] {
    float: left;
    margin-right: 6px;
    margin-top: 6px; }

.social-links {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top; }
  .social-links--small .social-links__item {
    width: 23px;
    height: 23px;
    margin-right: 0; }
    .social-links--small .social-links__item--fb {
      background-position: -10px -10px; }
    .social-links--small .social-links__item--twitter {
      background-position: -70px -10px; }
    .social-links--small .social-links__item--google {
      background-position: -130px -10px; }
  .social-links__item {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-image: url("../img/social-icons.png");
    background-repeat: no-repeat;
    margin-right: 14px; }
    .social-links__item:last-child {
      margin-right: 0; }
    .social-links__item a {
      display: block;
      height: 100%;
      width: 100%; }
    .social-links__item--fb {
      background-position: -10px -70px; }
    .social-links__item--twitter {
      background-position: -70px -70px; }
    .social-links__item--google {
      background-position: -130px -70px; }
