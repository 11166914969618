.form-columns {
	span.custom {
		display: none !important;
	}
} 


.travel.sticky {
	span.custom {
		display: none !important;
	}
	input[type=radio] {
		display: none !important;
	}
}