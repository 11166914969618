@import "mixins";
@import "vars";

.btn-alloy {
	@include btn-alloy;
	&:hover {
		background: $alloy-hover;
	}
}

.row.alloy-options {
	margin-top: 48px;
}


.alloy-wheel {
	overflow: hidden;
	width: 100%;
	::selection {
		background: $alloy;
		color: $white;
	}
	::-moz-selection {
		background: $alloy;
		color: $white;
	}
	h2, h3 {
		color: $alloy;
	}
	.product-feature {
		background: url(../img/banner_alloy.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $alloy;

			&:hover {
				background: $alloy-light;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: #fff;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $alloy;
				}
				&.active {
					background: $alloy;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $alloy;
			}
		}
	}

	.case-studies {
		li {
			a{
				color: $alloy;

				&:hover{
					color: $alloy;
				}
			}
		}
	}

	.right-sidebar {
            	background: #e0f0df;

		h4 {
			color: $alloy;
		}
		li {
			border-bottom-color: $alloy;
			&:before {
				@include list-tick-alloy;
			}
		}
	}

	.feature-list {
		li {
			@include list-tick-alloy;
		}
	}
	.round-message {
		background: $alloy;
	}
}