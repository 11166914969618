@import "mixins";
@import "vars";

#home-boxes {
    margin-bottom: 20px;

    li {
        background: url(../img/home_boxes_new.jpg) no-repeat;
        list-style: none;
        margin: 0 10px 20px;
        padding: 0;
        width: 220px;

        span {
            background-image: url(../img/home_boxes_arrows.png);
            background-repeat: no-repeat;
            width: 100%;
        }

        a.home-box-link {
            display: block;
            min-height: 166px;
        }


        &.box-breakdown {
            /* used to be 1494px*/
            background-position: left -1991px;

            &:hover {
                background-position: right -1991px;
            }

            span {
                background-color: $breakdown-dark;
                background-position: 180px -176px;
            }
        }

        &.box-lifestyle-legal {
            background-position: left -2158px;

            &:hover {
                background-position: right -2158px;
            }

            span {
                background-color: $lifestyle-legal-dark;
                background-position: 180px -951px;
            }
        }

        &.box-rent-guarantee {
            background-position: left -3777px;

            &:hover {
                background-position: right -3777px;
            }

            span {
                background-color: $lifestyle-legal-dark;
                background-position: 180px -951px;
            }
        }

        &.box-travel {
            background: url(../img/home_boxes_new.jpg) no-repeat;
            background-position: left -499px;
            width: 220px;

            &:hover {
                background-position: right -499px;
            }

            span {
                background-color: $travel-dark;
                background-position: 180px -176px;
            }
        }

        &.box-news {
            background: $news-light;
            background-position: left 0;
            height: 220px;
            width: 460px;

            &:hover {
                background-position: right 0;
            }

            span {
                background-color: $news-dark;
                background-position: 420px -305px;
            }
        }

        &.box-household {
            background: url(../img/home_boxes_new.jpg) no-repeat;
            background-position: left -0px;
            width: 220px;

            &:hover {
                background-position: right -0px;
            }

            span {
                background-color: #21557a;
                background-position: 420px -111px;
            }
        }


        &.box-household-wide {
            background: url(../img/home_boxes_wide.jpg);
            background-repeat: no-repeat !important;
            background-position: -1px -333px;
            width: 460px;

            &:hover {
                background-position: -461px -333px;
            }

            span {
                background-color: #21557a;
                background-position: 420px -305px;
            }
        }

        &.box-household2 {
            background-position: left 0;

            &:hover {
                background-position: right 0;
            }

            span {
                background-color: #21557a;
                background-position: 180px -120px;
            }
        }

        &.box-warranty {
            background-position: left -1826px;

            &:hover {
                background-position: right -1826px;
            }

            span {
                background-color: $warranty;
                background-position: 180px -563px;
            }
        }

        &.box-gap {
            background-position: left -332px;

            &:hover {
                background-position: right -332px;
            }

            span {
                background-color: #3e863b;
                background-position: 180px -240px;
            }

            strong {
                @include arial;
                color: $gap;
                display: block;
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
                margin: 20px 20px 0 15px;
            }
        }

        &.box-excess {
            background-position: left -830px;

            &:hover {
                background-position: right -830px;
            }

            span {
                background-color: #55bb50;
                background-position: 180px -499px;
            }
        }

        &.box-mot {
            background-position: left -996px;

            &:hover {
                background-position: right -996px;
            }

            span {
                background-color: $mot;
                background-position: 180px -396px;
            }
        }

        &.box-home {
            background: url(../img/home_boxes_new.jpg) no-repeat;
            background-position: left -166px;
            width: 220px;

            &:hover {
                background-position: right -167px;
            }

            span {
                background-color: $home;
                background-position: 420px -886px;
            }
        }

        &.box-bicycle {
            background: url(../img/home_boxes_new.jpg) no-repeat;
            background-position: left -3557px;
            width: 220px;

            &:hover {
                background-position: right -3557px;
            }

            span {
                background-color: $bicycle;
                background-position: 180px -886px;
            }
        }

        &.box-life {
            background-position: left -2378px;

            &:hover {
                background-position: right -2378px;
            }

            span {
                background-color: $life;
                background-position: 180px 18px;
            }
        }

        &.box-keys {
            background-position: left -2710px;

            &:hover {
                background-position: right -2710px;
            }

            span {
                background-color: $keys;
                background-position: 180px -628px;
            }
        }

        &.box-hire {
            background-position: left -1660px;

            &:hover {
                background-position: right -1660px;
            }

            span {
                background-color: $hire;
                background-position: 180px -370px;
            }
        }

        &.box-caravan {
            background-position: left -2544px;

            &:hover {
                background-position: right -2544px;
            }

            span {
                background-color: $caravan;
                background-position: 180px -434px;
            }
        }

        &.box-touring-caravan {
            background-position: left -3042px;

            &:hover {
                background-position: right -3042px;
            }

            span {
                background-color: $caravan;
                background-position: 180px -434px;
            }
        }

        &.box-alloy {
            background: url(../img/home_boxes_wide.jpg) no-repeat;
            background-position: left 0;
            width: 460px;

            &:hover {
                background-position: right 0;
            }

            span {
                background-color: $alloy;
                background-position: 420px -757px;
            }
        }

        &.box-tyre {
            background-position: left -1328px;

            &:hover {
                background-position: right -1328px;
            }

            span {
                background-color: $tyre;
                background-position: 180px -822px;
            }
        }

        &.box-scratch {
            background-position: left -664px;

            &:hover {
                background-position: right -664px;
            }

            span {
                background-color: #5a120b;
                background-position: 180px -693px;
            }
        }

        &.box-gadget {
            background-position: left -3208px;

            &:hover {
                background-position: right -3208px;
            }

            span {
                background-color: #d53674;
                background-position: 180px -1015px;
            }
        }

        &.box-property-let {
            background-position: left -3376px;

            &:hover {
                background-position: right -3376px;
            }

            span {
                background-color: #0089ab;
                background-position: 180px -176px;
            }
        }
    }

    h3 {
        @include bree;
        font-size: 28px;
        margin: 0;
        padding: 15px 15px 0;
    }

    p {
        color: #fff;
    }

    span {
        background: url(../img/home/arrow-quote.png) no-repeat 170px center;
        display: block;
        font-weight: bold;
        padding: 15px;

        a {
            color: #fff;
            display: block;
        }
    }

    .home-news {
        margin: 8px 0 0 0;
        height: 175px;

        li {
            background: none !important;
            height: 175px;
            width: 460px !important;

            p {
                color: #000;
                line-height: 1.5em;
                margin: 0 15px;
            }

            span {
                bottom: 0;
                position: absolute;
                width: 100%;
            }
        }
    }
}



/************************
7. a. HOME SLIDER BASE
************************/
#home-slider {
	clear: both;
	float: left;
	height: 290px !important;
	margin: 36px 1px 0;
	position: relative;
	.slide-sl {
		color: #fff;
		float: left;
		height: 185px;
		margin-top: 0;
		position: relative;
		z-index: 2;
	}
    .slide-s4{
        h2{
            @include bree;
            color: #1a2d3d;
            font-size: 32px;
            line-height: 36px;
            font-weight: 400;
            margin-bottom: 15px;
        }
        h1{
            @include bree;
            color: #1a2d3d;
            font-size: 65px;
            font-weight: 700;
            line-height: 36px;
        }
    }
	.slide-content-sl {
		ul {
			height: 34px;
			list-style:	none;
			position: absolute;

			li {
				float: left;

				a {
					&.link {
						color: #d2cdb8;
						display: block;
						float: left;
						font-size: 0.875em;
						margin:	14px 0 0 0;
						padding: 0 9px 5px 0;
						text-align:	center;
						text-decoration: none;
						width: 136px;
					}
				}
			}
		}
	}
}

/************************
7. b. HOME SLIDER TEXT
************************/

h2.daysingletrip {
	@include bree;
	@include liga;
	 font-style: normal;
	 font-weight: 700;
	 font-size: 43px;
	 line-height: 54px;
	 letter-spacing: -2px;
	 text-indent: 0;
	 color: rgba(255, 255, 255, 1);

}

h1.europe {
	@include bree;
	@include liga;
	 font-style: normal;
	 font-weight: 700;
	 font-size: 80px;
	 line-height: 70px;
	 letter-spacing: -4px;
	 text-indent: 0;
	 margin-top: 0;
	 margin-bottom: 0;
	 color: #946aac;
	 width: 700px;
}

h4.travelfromaslittleas {
	@include bree;
	@include liga;
	 font-style: oblique;
	 font-weight: 200;
	 font-size: 24px;
	 text-indent: 0;
	 letter-spacing: 0px;
	 line-height: 34px;
	 margin-bottom: 0px;
	 color: #fff;
	 margin-top: 0;
}

h3.travelprice {
	 font-family: "myriad-pro";
	font-size: 62px;
	line-height: 44px;
	text-indent: 0;
	color: #fff;
	letter-spacing: -3px;
	margin-bottom: 0px;
	margin-top: 6px;
	font-style: normal;
	font-weight: 700;
}


h1.recovery {
	 @include bree;
	 @include liga;
	 font-style: normal;
	 font-weight: 700;
	 font-size: 102px;
	 line-height: 60px;
	 letter-spacing: -4px;
	 text-indent: 0px;
	 margin-top: 0;
	 margin-bottom:0;
	 color: rgba(35, 70, 102, 1.00);
	 width: 577px;
	 text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
}

h2.localbreakdown {
	 @include bree;
	 font-style: normal;
	 font-weight: 700;
	 font-size: 52px;
	 line-height: 72px;
	 letter-spacing: -2px;
	 text-indent: 0px;
	 -moz-font-feature-settings: 'liga=1';
	 -ms-font-feature-settings: 'liga' 1;
	 -webkit-font-feature-settings: 'liga' 1;
	 -o-font-feature-settings: 'liga' 1;
	 font-feature-settings: 'liga' 1;
	 color: rgba(35, 70, 102, 1.00);
	 text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
}

h4.breakdownfromaslittleas {
	 @include bree;
	 font-style: oblique;
	 font-weight: 200;
	 -moz-font-feature-settings: 'liga=1';
	 -ms-font-feature-settings: 'liga' 1;
	 -webkit-font-feature-settings: 'liga' 1;
	 -o-font-feature-settings: 'liga' 1;
	 font-feature-settings: 'liga' 1;
	 font-size: 24px;
	 text-indent: 4px;
	 letter-spacing: -0.04em;
	 line-height: 34px;
	 margin-bottom: 0px;
	 color: rgba(35, 70, 102, 1.00);
	 margin-top: 12px;
}

h3.breakdownprice {
	 font-family: myriad-pro;
	 font-size: 63px;
	 line-height: 61px;
	 text-indent: 164px;
	 color: rgba(35, 70, 102, 1.00);
	 letter-spacing: 0px;
	 margin-bottom: 0px;
	 margin-top: -34px;
	 font-style: normal;
	 font-weight: 400;
	 strong {
		color: #0fb5e4;
	 }
}

h4.breakdownpermonth {
	 @include bree;
	 font-style: oblique;
	 font-weight: 400;
	 -moz-font-feature-settings: 'liga=1';
	 -ms-font-feature-settings: 'liga' 1;
	 -webkit-font-feature-settings: 'liga' 1;
	 -o-font-feature-settings: 'liga' 1;
	 font-feature-settings: 'liga' 1;
	 font-size: 24px;
	 text-indent: 268px;
	 letter-spacing: -0.04em;
	 line-height: 46px;
	 margin-bottom: 0px;
	 color: rgba(35, 70, 102, 1.00);
	 margin-top: -44px;
}

/* Household */
h2.household {
	@include bree;
	color: #fff;
	font-size: 39px;
	font-style: normal;
	font-weight: 300;
	line-height: 36px;
}

h2.householdinsurance {
	@include bree;
	@include liga;
	color: #fff;
	font-size: 55px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0px;
	line-height: 48px;
	text-indent: 0px;
}

#household-price {
	@include rotate;
	background: url(../img/home/bg_household_price.png) no-repeat 0 0;
	bottom: 20px;
	height: 175px;
	left: 450px;
	padding: 40px 0 0;
	position: absolute;
	text-align: center;
	width: 175px;
	strong {
		@include bree-serif;
		color: #fff;
		display: block;
		font-size: 36px;
		font-weight: 500;
		margin-bottom: 4px;
	}

	span {
		@include bree;
		color: #0a5c06;
		display: block;
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		padding: 0 20px;
	}
}

/* Home Emergency */
.slider-text h1.indent3 {
	@include bree;
	@include liga;
	color: rgba(249,243,148,1);
	text-indent: 0px;
	font-style: normal;
	font-weight: 700;
	font-size: 80px;
	letter-spacing: -4px;
	line-height: 15px;
	margin: -5px 0 40px;
}

.slider-text h3.indent2 {
	@include bree;
	@include liga;
	color: #fff;
	font-size: 24px;
	font-style: oblique;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 10px;
	margin: -8px 0 200px;
	text-indent: 173px;
}

.slider-text h1.white {
	@include bree;
	@include liga;
	color: #fff;
	font-size: 56px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: -2px;
	line-height: 72px;
	text-indent: 0px;
}

.slider-text h3.white {
	@include bree;
	@include liga;
	color: #fff;
	font-size: 24px;
	font-style: oblique;
	font-weight: 200;
	letter-spacing: 0px;
	line-height: 30px;
	margin: -10px 0 0;
	text-indent: 41px;
}

.slider-text h5.price-homeemergencywhite {
	@include bree-serif;
	color: #fff;
	font-size: 75px;
	letter-spacing: 0px;
	line-height: 10px;
	margin: 20px 0 -5px;
	text-indent: 95px;
}

/* Gap */
h1.gap {
	@include bree;
	@include liga;
	color: $gap;
	font-style: normal;
	font-weight: 700;
	font-size: 85px;
	line-height: 65px;
	letter-spacing: -2px;
	text-indent: 0px;
}

h2.gapbestcoveronthemarket {
	@include bree;
	@include liga;
	color: $base-blue;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0px;
	line-height: 38px;
	text-indent: 0px;
}

h3.gapreplacementcar {
	@include bree;
	@include liga;
	color: $base-blue;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 23px;
	text-indent: 0px;
}