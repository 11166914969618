.btn-home {
	@include btn-home;
	&:hover {
		background: $home-hover;
	}
}

.home-emergency {
	::selection {
		background: $home;
		color: $white;
	}
	::-moz-selection {
		background: $home;
		color: $white;
	}
	h1,h2, h3,h4 {
		color: $home;
	}
	.product-feature {
		background: url(../img/banner_home.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $home;

			&:hover {
				background: $home;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: $home;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $home;
				}
				&.active {
					background: $home;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $home;
			}
		}
	}

	.case-studies {
    		li {
    			a{
    				color: $home;

    				&:hover{
    					color: $home;
    				}
    			}
    		}
    	}

    	.right-sidebar {
                	background: #e0f0df;

    		h4 {
    			color: $home;
    		}
    		li {
    			border-bottom-color: $home;
    			&:before {
    				@include list-tick-home;
    			}
    		}
    	}
	ul.feature-list {
		li {
			@include list-tick-home;
		}
	}
	.round-message {
		background: $home;
		/*display:none;*/
	}
}

.radio-gold {
	margin: 0 10px 0 -5px;
    span:before {
        background-color: $home !important;
    }
}