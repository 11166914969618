.btn-travel {
	@include btn-travel;
	&:hover {
		background: $travel-hover;
	}
}

body .travel {
	::selection {
		background: $travel;
		color: $white;
	}

	::-moz-selection {
		background: $travel;
		color: $white;
	}
	margin-bottom: 24px;
	position: relative;
	h1 {
		@include bree;
		font-size: 60px;
		line-height: 48px;
		margin-bottom: 0px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: -3px;
		color: #A2086E;
	}
	.middle-content h2.blackh2 {
		color: #333;
		font-size: 20px;
		line-height: 33px;
		margin-bottom: 0px;
		font-style: normal;
		font-weight: 700;

		margin-top: 0px;
		letter-spacing: -1px;
		text-indent: 0px;
	}
	.insurees
	{
		.missingInsurees
		{
			background-color:#fae8f4;
			text-align:center;

		}

		TABLE
		{
			width:100%;
			margin:0px;
			border:none;
			text-align:center;
			padding:0px;
			thead
			{
				TR
				{
					background-color:white;
					color: $travel;
					margin-bottom:8px;
					font-family: "bree", sans-serif;

					padding-bottom:8px;
					TD
					{
						padding:0px 0px 8px 0px;
						font-size: 16px;
						text-align:center;

						&.insureeName
						{
							width:165px;
							text-align:left;
						}

						&.insureeCategory
						{
							width:95px;
						}

						&.insureeAge
						{
							width:55px;
						}

						&.insureeHealth
						{
							width:190px;
						}

						&.insureeAdditions
						{
							width:120px;
						}

					}

				}
			}

			TR {
				background-color:#fae8f4;
				margin-bottom:4px;

				TD {
					padding: 10px;
					font-size: 16px;
					text-align: center;
					border-bottom: 5px solid white;
					color: #756f6f;
						&.policyHolderRemove {
							text-align: right;
						}
						&.insureeName {
							text-align:left;
						}

						A {
							background-color:$travel;
							padding:0px 4px 0px 4px;
							color:white;
						}
				}
			}
		}
	}

	.priceBox
	{
		background-color:#fae8f4;
		padding:20px;
		float:left;
		margin-bottom:20px;

		H3
		{
			width: 200px;
			float:left;
			margin:0px;

		}

		.quotePrice
		{
			float:left;
			color:#1b4464;

			font-size: 48px;
			font-weight: bold;
			margin-top:30px;
		}

		.btn-travel
		{
			margin-top:20px;
			float:right;
		}

	}

	h2, h3 {
		color: $travel;
	}
	.checks > TABLE
	{
		border:none;
	}
	.checks > TABLE tbody {
		tr:nth-child(even) {
			background: none;
		}
	}
	.checks > TABLE tbody tr td
	{
		margin:9px 0px;
		width:135px;

		SPAN
		{
			float:left;
			margin-right:6px;
		}
		b {
			display: block;
			margin: 0 0 0 30px;
		}
		label {
			p {
				margin: 0 0 0 40px;
			}
		}

	}

	.radios
	{
		SPAN
		{
			float:left;
			margin-right:10px;
		}
	}

	.subText
	{
		font-size: small;
		float: left;
		margin-top: -7px;
	}
	.middle-content {
		h4 {
			color: $travel;
			font-weight: bold;
		}
	}
	.product-feature {
		background: url(../img/banner_travel.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $travel;
			&:hover {
				background: $travel-light;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color:$travel-light;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $travel;
				}
				&.active {
					background: $travel;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $travel;
			}
		}
	}
	.case-studies {
	                li {
	                    a{
	                        color: $travel;

	                        &:hover{
	                            color: $travel;
	                        }
	                    }
	                }
	            }

	            .right-sidebar {
	                        // background: #e0f0df;

	                h4 {
	                    color: $travel;
	                }
	                li {
	                	border-bottom-color: $travel;
	                    &:before {
	                        @include list-tick-travel;
	                    }
	                }
	            }
	.feature-list {
		li {
			@include list-tick-travel;
		}
	}
	.round-message.soon {
		background: $travel;
	}
	.radio-light-purple {
		margin: 0 10px 0 -5px;
		span:before {
			background-color: $travel !important;
		}
	}

}


.purpleSub {
	color: $travel;
	font-weight: bold;
}

#main-content .travel .selectPolicyType {
	.radio {
		display: none;
	}
	label {
		color: #756f6f;

		div {
			padding: 20px 0 20px 80px;
		}
		b {
			color: $travel-dark;
			display: block;
			font-size: 21px;
		}
		&:hover {
			background: $travel-dark;
			color: #fff;
			b {
				color: #fff;
			}
			div {
				color: #fff;
			}
		}
	}
	span.checked + label {
		background: $travel-dark;
		color: #fff;
		b {
			color: #fff;
		}
		div {
			color: #fff;
		}
	}

	#policySingle {
		background: url(../img/icon_single_trip.png) no-repeat 0 20px;
	}
	#policyAnnual {
		background: url(../img/icon_annual_trip.png) no-repeat 0 20px;
	}
	#policyAnnualWinter {
		background: url(../img/icon_annual_winter_trip.png) no-repeat 0 20px;
		span {
			font-weight: normal;
		}
	}
	#policyBackpacker {
		background: url(../img/icon_backpacker_trip.png) no-repeat 0 20px;
	}
}

.travel.sticky {
	.policySidebar {
		position: absolute;
	}
	.sticky-sidebar {
		width: 240px;
	}
	.nine.columns {
		margin-left: 240px;
	}
}


.sidebarPolicySummary {
	background: #f2cde5;
	margin-bottom: 24px;
	padding: 24px 20px;
	ul {
		list-style: none;
		margin: 0;
		li {
			font-size: 14px;
		}
	}
	p {
		@include bree;
		color: $base-blue;
		margin: 0;
	}
	b {
		@include myriad;
		@include liga;
		color: $travel;
		display: block;
		font-size: 32px;
		font-weight: 800;
		margin-bottom: 24px;
	}
}