﻿/* Typography */

@mixin arial { 
	font-family: arial, sans-serif;
	font-style: normal;
}

@mixin bree { 
	font-family: "bree", sans-serif;
	font-style: normal;
}

@mixin bree-serif { 
	font-family: "bree-serif", sans-serif; 
	font-style: normal;
}

@mixin myriad { 
	font-family: "myriad-pro", arial;
	font-style: normal;
}

@mixin liga {
	font-feature-settings: "liga" 1;
	 -moz-font-feature-settings: "liga=1";
	 -ms-font-feature-settings: "liga" 1;
	 -o-font-feature-settings: "liga" 1;
	 -webkit-font-feature-settings: "liga" 1;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

h1 {
	@include bree;
    font-size: 48px;
    font-weight: 700;
	line-height: 48px;
}

h2 {
	@include bree;
    font-size: 36px;
    font-weight: 700;
	letter-spacing: -1px;
    line-height: 39px;
}

h3 {
	@include bree;
    font-size: 28px;
	font-style: normal;
    font-weight: 400;
}

h4 {
	@include bree;
    font-size: 21px;
	font-style: normal;
	font-weight: 400;
    line-height: 28px;
}

h5 {
    font-style: normal;
    font-weight: normal;
}

h6 {
    font-style: italic;
	font-weight: 400;
}

p a:link {
    text-decoration: none;
}

p a:visited {
    text-decoration: none;
}

p a:hover {
    text-decoration: none;
}

dt, dl, dd, label, li, p, div {
	color: rgba(117,111,111,1);
	font-family: myriad-pro, arial, sans-serif;
    font-size: 16px;
	font-style: normal;
	font-weight: 400;
    line-height: 24px;
	text-indent: 0px;
}

p.bullet {
	font-family: myriad-pro, arial, sans-serif;
    text-indent: -10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

p {
	b {
		margin: 0;
	}
}

/* End Typography */