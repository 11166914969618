﻿@import "mixins";
@import "vars";

.btn-bicycle {
	@include btn-bicycle;
    &:hover {
        background: lighten($bicycle, 15%);
        color: #fff;
    }
}
#nav-bicycle {
    &.selected {
        border-color: $bicycle;
    }
}
.bicycle {
	::selection {
		background: $bicycle;
		color: $white;
	}
	::-moz-selection {
		background: $bicycle;
		color: $white;
	}
	h2, h3 {
		color: $bicycle;
	}
    &.quote-button {
		background: $bicycle;
        padding: 12px;

		&:hover {
			background: lighten($bicycle, 15%);
			color: #fff;
		}
        &:active {
			background: darken($bicycle, 15%);
			color: #fff;
		}
	}

    .call-and-advice{
        color: $black;
        font-size: 18px;

        span{
            color: #756f6f;
            font-size: 16px;
        }

        u{
            border-bottom: 1px solid $bicycle;
            text-decoration: none;
        }
    }
    

	.product-feature {
		background: url(../img/banner_bicycle.png) no-repeat 0 0;
		/*background-size: cover;*/
        min-height: 260px;
        position: relative;

        #feature-copy { 
            background: rgba(0,0,0,0.7);
            padding: 22px 10px 7px 30px;
            min-height: 260px;

             p { margin-bottom: 9px; }
        }

        .banner-details {
            height: 100%;
            position: absolute;
        }

		h1 {
			color: $white;
		}
		p {
			color: $bicycle;
		}

        .coming-soon {
            background-color: $bicycle;
        }

	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 1px solid $bicycle;
				}
				&.selected {
					//border-color: $bicycle;
				}
			}
		}
		#call-us {
			strong {
				color: $bicycle;
			}
		}
	}

		.case-studies {
			li {
				a{
					color: $bicycle;

					&:hover{
						color: $bicycle;
					}
				}
			}
		}

		.right-sidebar {
            background: $bicycle-light !important;

			h4 {
				color: $bicycle;
                font-size: 20px;
                margin: 0;
			}

			.button {
				background: $bicycle !important;
                padding-left: 12px;
                padding-right: 12px;
			}

			li {
				border-bottom-color: $bicycle;
			}
		}

	.feature-list {
		li {
			@include list-tick-bicycle;
            border-bottom: none;
		}
	}
	.round-message {
		background: $bicycle;
        bottom: -10px;
	}

	.radio-orange {
		margin: 0 10px 0 -5px;

		span:before {
			background-color: $bicycle !important;
		}
	}
}

table#quote-results-table {
	tbody tr:nth-child(even) {
		background: none;
	}
}
