.btn-caravan {
    @include btn-caravan;
    &:hover {
        background: $caravan-hover;
    }
}


.caravan {
    ::selection {
        background: $caravan;
        color: $white;
    }
    ::-moz-selection {
        background: $caravan;
        color: $white;
    }

    h2, h3 {
        color: $caravan;
    }
    .product-feature {
        background: url(../img/banner_caravan.jpg) no-repeat 0 0;
        background-size: cover;
        a {
            background: $caravan;
            &:hover {
                background: $caravan-hover;
            }
        }
        h1 {
            color: $base-blue;
        }
        p {
            color: $caravan-light;
        }
    }
    .left-sidebar {
        li {
            a {
                &:hover {
                    border-bottom: 7px solid $caravan;
                }
                &.active {
                    background: $caravan;
                    border-bottom: none;
                }
            }
        }
        #call-us {
            strong {
                color: $caravan;
            }
        }
    }
    .case-studies {
        li {
            a{
                color: $caravan;

                &:hover{
                    color: $caravan;
                }
            }
        }
    }

    .right-sidebar {
                background: #ebe3f7;

        h4 {
            color: $caravan;
        }
        li {
            border-bottom-color: $caravan;
            &:before {
                @include list-tick-caravan;
            }
        }
    }
    .feature-list {
        li {
            @include list-tick-caravan;
        }
    }
    .round-message {
        background: $caravan;
    }
}


/* 
 * Which Caravan
 * component.caravans
*/

.c-caravans{
    position: relative
}

    .c-caravans__item{
        position: relative;
    }

    .c-caravans__bg{
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 418px;
    }

    .c-caravans__item--touring .c-caravans__bg{ background-image: url(../img/which-caravan-touring.jpg); }
    .c-caravans__item--static .c-caravans__bg{ background-image: url(../img/which-caravan-static.jpg); }

    .c-caravans__detail{
        bottom: 0;
        left: 0;
        padding: 28px 35px;
        position: absolute;
        right: 0;

        h2{
            color: $white;
            font-size: 32px;
            margin-bottom: 30px;
        }

        .button{
            border: 1px solid $white;
            margin-right: 10px;

            &:hover{
                background-color: rgba(255, 255, 255, 0.4);
            }
        }
    }