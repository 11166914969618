.btn-keys {
    @include btn-keys;
    &:hover {
        background: $keys-hover;
    }
}


.keys {
    ::selection {
        background: $keys;
        color: $white;
    }
    ::-moz-selection {
        background: $keys;
        color: $white;
    }

    h2, h3 {
        color: $keys;
    }
    .product-feature {
        background: url(../img/banner_keys.jpg) no-repeat 0 0;
        background-size: cover;
        a {
            background: $keys;
            &:hover {
                background: $keys-hover;
            }
        }
        h1 {
            color: $base-blue;
        }
        p {
            color: $keys;
        }
    }
    .left-sidebar {
        li {
            a {
                &:hover {
                    border-bottom: 7px solid $keys;
                }
                &.active {
                    background: $keys;
                    border-bottom: none;
                }
            }
        }
        #call-us {
            strong {
                color: $keys;
            }
        }
    }
     .case-studies {
            li {
                a{
                    color: $keys;

                    &:hover{
                        color: $keys;
                    }
                }
            }
        }

        .right-sidebar {
                    background: #f6e4c9;

            h4 {
                color: $keys;
            }
            li {
                border-bottom-color: $keys;
                &:before {
                    @include list-tick-keys;
                }
            }
        }
    .feature-list {
        li {
            @include list-tick-keys;
        }
    }
    .round-message {
        background: $keys;
    }
}