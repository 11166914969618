@import "vars";

@mixin opacity($value) {
    opacity: #{"." + $value};
    filter: alpha(opacity=$value);
     -moz-opacity: #{"." + $value};
     -ms-filter: "alpha(opacity=$value)";
     -khtml-opacity: #{"." + $value};
}

@mixin hide-text{
    overflow:hidden;
    text-indent:-9000px;
    display:block;
}

@mixin clear() {
    &:before, &:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}


@mixin box-shadow($left, $top, $radius, $color, $inset:"") {
    -moz-box-shadow: #{$inset} $left $top $radius $color;
    -webkit-box-shadow: #{$inset} $left $top $radius $color;
    box-shadow: #{$inset} $left $top $radius $color;
}

@mixin rotate {
     -moz-transform: rotate(-7deg);
     -ms-transform: rotate(-7deg);
     -o-transform: rotate(-7deg);
     -webkit-transform: rotate(-7deg);
     transform: rotate(-7deg);
}

@mixin radius($radius) {
    -moz-border-radius: $radius;
    -o-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}


/* BUTTON MIXINS */
@mixin btn {
    border: 0;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    padding: 12px 20px;
    text-align: center;
}

@mixin btn-household {
    @include btn;
    background: $household;
}
@mixin btn-household-hover {
    @include btn;
    background: $household-hover;
}

@mixin btn-home {
    @include btn;
    background: $home;
}
@mixin btn-home-hover {
    @include btn;
    background: $home-hover;
}
@mixin btn-bicycle {
	@include btn;
	background: $bicycle;
}
@mixin btn-bicycle-hover {
	@include btn;
	background: $bicycle-hover;
}
@mixin btn-keys {
    @include btn;
    background: $keys;
}
@mixin btn-keys-hover {
    @include btn;
    background: $keys-hover;
}

@mixin btn-caravan {
    @include btn;
    background: $keys;
}
@mixin btn-caravan-hover {
    @include btn;
    background: $keys-hover;
}

@mixin btn-gap {
    @include btn;
    background: $gap-light;
}
@mixin btn-gap-hover {
    @include btn;
    background: $gap-hover;
}
@mixin btn-gadget {
	@include btn;
	background: $gadget-light;
}
@mixin btn-gadget-hover {
	@include btn;
	background: $gadget-hover;
}
@mixin btn-property-let {
	@include btn;
	background: $property-let-light;
}
@mixin btn-property-let-hover {
	@include btn;
	background: $property-let-hover;
}
@mixin btn-travel {
    @include btn;
    background: $travel;
}
@mixin btn-travel-hover {
    @include btn;
    background: $travel-hover;
}

@mixin btn-scratch {
    @include btn;
    background: $scratch;
}
@mixin btn-scratch-hover {
    @include btn;
    background: $scratch-hover;
}

@mixin btn-excess {
    @include btn;
    background: $excess;
}
@mixin btn-excess-hover {
    @include btn;
    background: $excess-hover;
}

@mixin btn-mot {
    @include btn;
    background: $mot;
}
@mixin btn-mot-hover {
    @include btn;
    background: $mot-hover;
}

@mixin btn-alloy {
    @include btn;
    background: $alloy;
}
@mixin btn-alloy-hover {
    @include btn;
    background: $alloy-hover;
}

@mixin btn-tyre {
    @include btn;
    background: $tyre;
}
@mixin btn-tyre-hover {
    @include btn;
    background: $tyre-hover;
}

@mixin btn-breakdown {
    @include btn;
    background: $breakdown;
}
@mixin btn-breakdown-hover {
    @include btn;
    background: $breakdown-hover;
}

@mixin btn-life {
    @include btn;
    background: $life;
}
@mixin btn-life-hover {
    @include btn;
    background: $life-hover;
}

@mixin btn-hire {
    @include btn;
    background: $hire;
}
@mixin btn-hire-hover {
    @include btn;
    background: $hire-hover;
}

@mixin btn-warranty {
    @include btn;
    background: $warranty;
}
@mixin btn-warranty-hover {
    @include btn;
    background: $warranty-hover;
}



@mixin btn-base-blue {
    @include btn;
    background: $base-blue;
}

@mixin btn-base-blue-hover {
    @include btn;
    background: $base-blue-hover;
}

@mixin btn-base-green {
    @include btn;
    background: $base-green;
}

@mixin btn-base-green-hover {
    @include btn;
    background: $base-green-hover;
}

@mixin btn-grey {
    @include btn;
    background: $grey;
}

/* END BUTTON MIXINS */


@mixin border-left {
    border-bottom: 1px solid $grey-light;
}

@mixin border-right {
    border-bottom: 1px solid $grey-light;
}

@mixin border-top {
    border-bottom: 1px solid $grey-light;
}

@mixin border-bottom {
    border-bottom: 1px solid $grey-light;
}


/* LIST MIXINS */
@mixin list-basic {
    font-size: 12px;
    padding: 5px 0 5px 18px;
}

@mixin list-nobull {
    font-size: 12px;
    list-style: none;
    padding: 0;
}

@mixin list-tick {
    background-image: url(../img/sprite_lists.png);
    background-repeat: no-repeat;
    border-bottom: 1px solid $grey;
    font-size: 14px;
    list-style: none;
    padding: 3px 0 3px 18px;
}

@mixin list-tick-household {
    @include list-tick;
    background-position: -4px -695px;
}

@mixin list-tick-home {
    @include list-tick;
    background-position: -4px -945px;
}

@mixin list-tick-bicycle {
	@include list-tick;
	background-position: -4px -945px;
}

@mixin list-tick-gap {
    @include list-tick;
    background-position: -4px -795px;
}

@mixin list-tick-gadget {
	@include list-tick;
	background-position: -4px -1095px;
}
@mixin list-tick-property-let {
	@include list-tick;
	background-position: -4px -695px;
}

@mixin list-tick-travel {
    @include list-tick;
    background-position: -4px -645px;
}

@mixin list-tick-lifestyle-legal {
    @include list-tick;
    background-position: -4px -1192px;
}

@mixin list-tick-scratch {
    @include list-tick;
    background-position: -4px -1145px;
}

@mixin list-tick-keys {
    @include list-tick;
    background-position: -4px -1241px;
}

@mixin list-tick-caravan {
    @include list-tick;
    background-position: -4px -1290px;
}

@mixin list-tick-excess {
    @include list-tick;
    background-position: -4px -844px;
}

@mixin list-tick-mot {
    @include list-tick;
    background-position: -4px -894px;
}

@mixin list-tick-alloy {
    @include list-tick;
    background-position: -4px -1045px;
}

@mixin list-tick-tyre {
    @include list-tick;
    background-position: -4px -994px;
}

@mixin list-tick-breakdown {
    @include list-tick;
    background-position: -4px -595px;
}

@mixin list-tick-life {
    @include list-tick;
    background-position: -4px -1095px;
}
@mixin list-tick-hire {
    @include list-tick;
    background-position: -4px -1095px;
}

@mixin list-tick-warranty {
    @include list-tick;
    background-position: -4px -745px;
}


@mixin product-info($product) {
    h4 a {
        color: $product;
    }
    ul li {
        a {
            &:hover {
                color: $product;
            }
            &:before {
                border-left-color: $product;
            }
        }
    }
}

@mixin product-sidebars($color) {
    .left-sidebar {
        li {
            a {
                &:hover {
                    border-bottom: 7px solid $color;
                }
                &.active {
                    background: $color;
                    border-bottom: none;
                }
            }
        }
        #call-us {
            strong {
                color: $color;
            }
        }
    }
    .right-sidebar {
        background: #e0f0df;
        h4 {
            color: $color;
        }
        ul {
            li {
                list-style: none;
                position: relative;
                &:before {
                    @include list-tick-tyre;
                    border-bottom: none;
                    content: "";
                    height: 20px;
                    left: -15px;
                    position: absolute;
                    top: -3px;
                }
                &:after {
                    content: "";
                }
            }
        }
    }
}