﻿/* _component.download.scss */

.c-download{

}

    .c-download__item{        
        border-bottom: 1px solid #878787;
        color: $black;
        display: inline-block;
        font-weight: 700;
        padding: 14px 12px;
        width: 100%;

        &:hover{
            background-color: #ebebeb;
        }
    }

    .c-download__purpose{
        color: #756f6f;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        float: right;
    }