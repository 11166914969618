﻿/* _component.social */

.c-social{
    z-index: 11;
}

    .c-social--fixed{
        position: fixed;
        right: 0;
        top: 15%;
    }

    .c-social__item{
        background-position: center;
        background-repeat: no-repeat;
        background-color: #fff;
        display: block;
        position: relative;
        height: 48px;
        width: 48px;
    }

    .c-social__item--facebook{ background-image: url(../img/ui-social-facebook.png); }
    .c-social__item--twitter{ background-image: url(../img/ui-social-twitter.png); }
    .c-social__item--google{ background-image: url(../img/ui-social-google.png); }
    .c-social__item--youtube{ background-image: url(../img/ui-social-youtube.png); }
    .c-social__item--documents{ 
        background-image: url(../img/ui-social-documents.png);
        height: 191px;
    }