#register-modal {
	left: 0;
	margin: 0 auto;
	right: 0;
	width: 700px;
}

#login-modal {
	left: 0;
	margin: 0 auto;
	max-width: 600px;
	right: 0;
}

#login-modal, #register-modal, #retrieve-modal, #retrieve-quote, #edit-details-modal, #retrieve-password {
	background: #eee;
	background-clip: padding-box;
	 -webkit-background-clip: padding-box;
    border-radius: 12px;
	 -webkit-border-radius: 12px;
	text-align: center;
	.modal-content {
		 table
            {
                width:100%;
            }
			.modal-iframe {
				border:none;
				height:320px;
				width:95%;
			}
	}
	.modal-header {
		padding: 9px 15px;
	}
	.modal-body {
		max-height: 470px;
		overflow-y: auto;
		padding: 15px;
		position: relative;
	}
	.regPage {
		max-height: 600px;
		.input-container {
			margin: 0 0 5px;
			.input, .select {
				box-sizing: border-box;
				height: 40px;
				margin: 0 0 10px;
				padding: 0 0 0 10px;
			}
		}
	}

	.regWarning
	{

		background: #ffffcc url(../img/alert000.png) no-repeat 15px 17px;
		margin: 5px 0 0;
		padding: 15px 15px 15px 40px;
		p {
			margin: 0;
		}

	}
	.facebook {
		background-color: #4161a6;
		background-clip: padding-box;
		 -webkit-background-clip: padding-box;
		color: white;
		font-size: 16px;
		font-weight: bold;
		margin: 0;
		padding: 12px 0;
		position: relative;
		text-shadow: 0 1px 1px rgba(0,0,0,0.5);
		&:hover {
			background-color: #234666;
		}
		a {
			color: #fff;
			display: block;
		}
	}
	.password-reset {
		color: #666;
		display: block;
		font-size: 12px;
		margin: 8px 0 0 0;
		text-align: right;
		text-decoration: underline;
	}

	.register-link {
		color: #666;
		display: block;
		font-size: 14px;
		margin: 15px 0 0 0;
		text-align: left;
		a {
			color: $base-blue;
			font-weight: bold;
			&:hover {
				color: $base-blue-hover;
			}
		}
	}

	.input-container {
		position: relative;
		input {
			background-color: #fff;
			border: 1px solid #ccc;
			box-sizing: border-box;
			color: #aaa;
			display: inline-block;
			font-size: 17px;
			height: 54px;
			margin: 0 0 20px;
			padding: 0 0 0 60px;
			width: 100%;
		}
		i {
			position: absolute;
		}
		.sprite {
			background-image: url(../img/social-sprite.png);
			background-repeat: no-repeat;
		}
		.head {
			background-position: -552px -55px;
			height: 26px;
			left: 15px;
			top: 15px;
			width: 28px;
		}
		.lock {
			background-position: -509px -343px;
			height: 32px;
			left: 17px;
			top: 10px;
			width: 23px;
		}
	}

	.white-fb-logo {
		background-position: -228px -107px;
		height: 30px;
		width: 14px;
	}
	.pulse-btn.facebook i {
		left: 25px;
		margin-top: -15px;
		position: absolute;
		top: 50%;
	}
	.options-divider {
		color: #686868;
		font-size: 16px;
		margin: 15px 0;
		text-align: center;
		text-shadow: 0 1px 0 #fff;
	}
}

#edit-details-modal
{
	margin-top: -225px;

	#edit-details {
		margin-top: 20px;

		.watermarked {
			color: #9e9e9f !important;
		}
	}
}

/* Main Navigation */
.nav-bar {
	background: $base-blue;
	display: table;
	margin-bottom: 0;
	position: relative;
	width: 100%;
    z-index: 11;

	li {
		position: relative;
		display: table-cell;
		vertical-align: top;
		float: none;
		&:hover {
			background: $dark-blue;
		}
		a,span {
			@include myriad;
			color: $white;
			font-weight: 600;
			font-size: 16px !important;
			padding: 0 12px 0 12px !important;

			&.active {
				font-weight: bold;
				line-height: 56px;
			}
		}
	}

    li > ul {
        a,span {
            &.selected {
		        background-color: #032d4a;
		        border-bottom: 7px solid #0c98c0;
		        color:#fff;
		    }
        }
    }

	&__dropdown{
		padding-left: 10px;
		padding-right: 10px;

		&:after {
			background: url(../img/nav-arrow.png) no-repeat right top;
			content: '';
			display: inline-block;
			height: 12px;
			width: 12px;
		}
		&:hover {
			cursor: default;
			background-position: right -69px;

			&:after{
				background-position: right bottom;
			} 
		}
	}
}

.nav-bar.sticky {
    margin: 0;
    position: fixed;
    top: 0;
    width: 940px;
    z-index: 100;
}

.nav-bar li ul {
	display: none;
	margin: 0;
	left: 0;
	position: absolute;
	min-width: 100%;
	z-index: 2;

	li {
		background: $dark-blue;
		border-bottom: 1px solid #7e9bb5;
		border-left: none;
		display: block;
		float: none;
		height: auto;
		margin: 0;
		width: 100%;

		&:last-child {
			border-bottom: none;
		}

		a {
			color: #fff;
            padding: 12px 20px 12px 12px !important;

            .right-arrow{
                right: 12px;
                position: absolute;
                top: 35%;
            }
		}
	}
}

.nav-bar li:hover ul {
	display: block;
	li {
		margin: 0;
		a {
            border-bottom: 7px solid transparent;
			&:hover {
				background: $dark-blue;
				color: #fff;
			}
		}
	}
    li.nav-household a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $household;
	    }
    }

    li.nav-home-emergency a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $home;
	    }
    }

    li.nav-keys a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $keys;
	    }
    }

    li.nav-property-let a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $property-let;
	    }
    }

    li.nav-travel a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $travel;
	    }
    }

    li.nav-life a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $life;
	    }
    }

    li.nav-hire a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $hire;
	    }
    }

    li.nav-breakdown a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $breakdown-light;
	    }
    }

    li.nav-gap a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $gap;
	    }
    }

    li.nav-caravan a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $caravan;
	    }
    }

    li.nav-gadget a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $gadget;
	    }
    }

    li.nav-other a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $dark-blue;
	    }
    }

    li.nav-lifestyle-legal a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $lifestyle-legal;
	    }
    }

    li.nav-alloy-wheel a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $alloy;
	    }
    }

    li.nav-scratch-dent a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $scratch;
	    }
    }

    li.nav-car-excess a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $excess;
	    }
    }

    li.nav-mot a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $mot;
	    }
    }

    li.nav-courtesy-car a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $tyre;
	    }
    }

    li.nav-tyre a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $tyre;
	    }
    }

    li.nav-warranty a {
	    &:hover, &.selected {
	    border-bottom: 7px solid $warranty;
	    }
    }
}



/* Top Navigation  */
.utility-nav{
	text-align: right;

	&__locale{
		color: $grey;
		display: inline-block;
		font-style: italic;
		padding-top: 12px;

		a{
			font-style: normal;
			font-weight: 700;
			text-decoration: underline;
		}
	}
}

.sub-nav{
	/*padding-top: 32px;*/
	text-align: right;
}

/* Top Navigation  */
#nav-extra {
	// float: left;
	display: inline-block;
	margin: 15px 0 0 0;
	position: relative;
	vertical-align: top;
	z-index: 2;
	li {
		border-right: 1px solid $black;
		display: inline;
		list-style: none;
		padding: 0 9px 0 9px;
		position: relative;
		z-index: 3;
		&.last {
			border-right: none;
			// padding: 0 0 0 8px;
		}
		a {
			@include myriad;
			font-size: 16px;
		}
	}
}

ul#top-btns {
	float: right;
	li {
		display: inline-block;
		margin: 0 0 0 8px;
		text-align: center;

        &.first,
        &.second{
            margin: 0;
            vertical-align: top;
        }

        &.first{
            padding-top: 12px;
        }

		a {
			@include btn;
            padding: 12px 15px;
			font-size: 16px;
		}
	}

}

/* Utility Nav */
#nav-util{
    margin: 0;

    li {
        border-right: 1px solid $black;
        display: inline;
		list-style: none;
		padding: 0 9px 0 9px;
		position: relative;
        margin: 0 !important;

        &:last-child {
			border-right: none;
		}
        a {
            @include myriad;
            font-weight: 400 !important;
            color: #8c8c8c !important;
            padding: 0 0 !important;
            font-size: 15px !important;
        }
    }
}

/* Country Selector */
#country_change{
    appearance: none;
    background-color: transparent;
    background-image: url(../img/ui-country-select-bg.png);
    background-repeat: no-repeat;
    background-position: center bottom 4px;
    padding-bottom: 14px;
    padding-top: 18px;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid #234666;
    border-top: none;
    cursor: pointer;
    color: #8c8c8c !important;
}

.loggedin-controls{
    position: relative;
    text-align: left;

    .parent{
        &:after {
            margin-left: 8px;
			background: url(../img/nav-arrow.png) no-repeat right top;
			content: '';
			display: inline-block;
			height: 12px;
			width: 12px;
		}
		&.active {
			cursor: default;
            background-color: #1a1f26;
			background-position: right -69px;

			&:after{
				background-position: right bottom;
			} 
		}
    }

    &__dropdown{
        display: block;
        position: absolute;
        top: -999em;
        margin: 0;
        width: 14em;
        z-index: 999999;

        li{
            background: #1a1f26;
            border-bottom: 1px solid #7e9bb5;
            color: #fff;
            margin: 0 !important;
            width: 100%;
            z-index: 99999;
            
            &:last-child{
                border: none;
            }

            &:hover{
                background: #234666;
            }

            a {
                text-align: left !important;
                position: relative;
                padding: 12px !important;
                width: 100%;
            }
        }
    }

    .parent.active + .loggedin-controls__dropdown{
        left: 0;
        top: 48px;
    }
}

.login-details {
    display: inline-block;
    text-align:left;
    font-size:10pt;
    vertical-align: top;
    margin: 2px 10px 0 0;

    .date {
        color: #aaa;
        font-size: 0.65em;
        line-height: 1.5;
    }
    .login-name {
        bottom: 5px;
        font-size: 0.9em;
        line-height: 1.2;
        overflow: hidden;
        em {
            color: #234666;
            display: inline-block;
            font-style: normal;
            font-weight: bold;
            width: 52px;
        }
    }
}


.non-fault-heading{
    @include myriad;
    font-size: 19px;
    font-style: italic;
    font-weight: 400;
    text-align: right;

    span{
        font-size: 30px;
        font-weight: bold;
        font-style: normal;
        vertical-align: bottom;
    }
}