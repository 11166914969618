.btn-breakdown {
	@include btn-breakdown;
	&:hover {
		background: $breakdown-hover;
	}
}


.breakdown {
	h2, h3 {
		color: $breakdown;
	}
	.product-feature {
		background: url(../img/banner_breakdown_summer.jpg) no-repeat 0 0;
		background-size: cover;
		a {
			background: $breakdown;
			&:hover {
				background: $breakdown-hover;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: $breakdown;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $breakdown;
				}
				&.active {
					background: $breakdown;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $breakdown;
			}
		}
	}
	.case-studies {
		li {
			a{
				color: $breakdown;

				&:hover{
					color: $breakdown;
				}
			}
		}
	}

	.right-sidebar {
            	background: #e0f0df;

		h4 {
			color: $breakdown;
		}
		li {
			border-bottom-color: $breakdown;
			&:before {
				@include list-tick-breakdown;
			}
		}
	}
	.feature-list {
		li {
			@include list-tick-breakdown;
		}
	}
	.round-message {
		background: $breakdown;
	}
}