.btn-excess {
	@include btn-excess;
	&:hover {
		background: $excess-hover;
	}
}

.car-excess {
	::selection {
		background: $excess;
		color: $white;
	}
	::-moz-selection {
		background: $excess;
		color: $white;
	}
	h2, h3 {
		color: $excess;
	}
	.product-feature {
		background: url(../img/banner_excess.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $excess;

			&:hover {
				background: $excess;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: #fff;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $excess;
				}
				&.active {
					background: $excess;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $excess;
			}
		}
	}
	.case-studies {
		li {
			a{
				color: $excess;

                &:hover{
                    color: $excess;
                }
			}
		}
	}
	.right-sidebar {
		background: #e0f0df;
		h4 {
			color: $excess;
		}
		ul {
			li {
				list-style: none;
				margin-left: 14px;
				position: relative;
				&:before {
					@include list-tick-excess;
					border-bottom: none;
					content: "";
					height: 20px;
					left: -15px;
					position: absolute;
					top: -3px;
				}
				&:after {
					content: "";
				}
			}
		}
	}
	.feature-list {
		li {
			@include list-tick-excess;
		}
	}
	.round-message {
		background: $excess;
	}
}

.engine-size {
	left: -60px;
}