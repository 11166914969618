﻿.btn-hire {
	@include btn-hire;
	&:hover {
		background: $hire-hover;
	}
}
.hire {
	::selection {
		background: $hire;
		color: $white;
	}
	::-moz-selection {
		background: $hire;
		color: $white;
	}
	h2, h3 {
		color: $hire;
	}
	.product-feature {
		background: url(../img/banner-car-hire-excess.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $hire;

			&:hover {
				background: $hire;
			}
		}
		h1 {
			color: $hire;
		}
		p {
			color: $hire;
		}
		.banner-details {
			position: relative;
		}
	}

	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $hire;
				}
				&.active {
					background: $hire;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $hire;
			}
		}
	}
	.middle-content {
		h4 {
			color: $hire;
			font-weight: bold;
		}
	}
	 .case-studies {
		li {
			a{
				color: $hire;

				&:hover{
					color: $hire;
				}
			}
		}
	}

	.right-sidebar {
            	background: #e0f0df;

		h4 {
			color: $hire;
		}
		li {
			border-bottom-color: $hire;
			&:before {
				@include list-tick-hire;
			}
		}
	}
	.feature-list {
		li {
			@include list-tick-hire;
		}
	}
	.round-message {
		background: $hire;
	}
}