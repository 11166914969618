﻿/* MAIN PRODUCT COLOURS */

/* Household */
// $household: #0c98c0;
// $household-hover: #0c98c0;
$household-light: #53c6e7;

$household: #21557a;
$household-hover: #21557a;

/* Home Emergency */
$home: #f79132;
$home-hover: #f79132;

/* Bicycle */
$bicycle: #f79132;
$bicycle-hover: #f79132;
$bicycle-light: #fff0e2;

/* Caravan */
$caravan: #4e396d;
$caravan-hover: darken($caravan, 10%);
$caravan-light: #8f77b2;

/* GAP */
$gap: #3e863b;
$gap-hover: #3e863b;
$gap-light: #74c370;

/* GADGET */
$gadget: #d53674;
$gadget-dark: #a2086e;
$gadget-hover: darken($gadget, 10%);
$gadget-light: #fee5f6;

/* Property */
$property-let: #0089ab;
$property-let-hover: darken($property-let, 10%);
$property-let-light: #daf0f6;

/* Keys */
$keys: #fcb64f;
$keys-hover: darken($keys, 10%);

/* Travel */
// $travel: #946aac;
// $travel-hover: #946aac;
$travel-light: #bd98f4;
$travel-dark: #361f59;

$travel: #361f59;
$travel-hover: #361f59;

/* Latest News */
$news-light: #bdd9bb;
$news-dark: #3e863b;

/* Rent Guarantee */
$rentg: #f79132;
$rentg-hover: #f79132;
$rentg-light: #fff0e2;

/* Lifestyle Legal */
$lifestyle-legal: #007e81;
$lifestyle-legal-hover: #946aac;
$lifestyle-legal-light: #81b2bc;
$lifestyle-legal-dark: #007e81;

/* Scratch and Dent */
// $scratch: #cf1716;
// $scratch-hover: #cf1716;

$scratch: #5a120b;
$scratch-hover: #5a120b;

/* Car Excess */
$excess: #55bb50;
$excess-hover: #55bb50;

/* MOT */
$mot: #d4ab17;
$mot-hover: #d4ab17;

/* Alloy Wheel */
$alloy: #9d1b0f;
$alloy-hover: #9d1b0f;
$alloy-light: #ed2714;

/* Tyre */
$tyre: #a2086e;
$tyre-hover: #a2086e;
$tyre-light: #e7376b;

/* Breakdown Recovery */
// $breakdown: #0fb5e4;
$breakdown-hover: #032d4a;
$breakdown-light: #9fdef5;
$breakdown-dark: #032d4a;

$breakdown: #032d4a;

/* Life Assurance */
// $life: #d53f3b;
// $life-hover: #d53f3b;
$life: #d4ab17;
$life-hover: #d4ab17;


/* Car Hire Excess */
$hire: #d53f3b;
$hire-hover: #d53f3b;

/* Motor Warranty */
// $warranty: #007e81;
// $warranty-hover: #007e81;
$warranty-light: #77e6e9;

$warranty: #124546;
$warranty-hover: #124546;

/* Courtesy Car */
$courtesy: #00b5ba;
$courtesy-hover: #e7376b;

/* LINKS AND BUTTON COLOURS */

$base-green: #3ab11a;
$base-green-hover: #2a930e;

$base-blue: #234666;
$base-blue-hover: #0f283e;
$dark-blue: #032d4a;

$black: #1c1c1b;
$white: #fff;

$grey: #8c8c8c;
$grey-dark: #333;
$grey-light: #ccc;

.household	   	{color: $household;}
.home		   	{color: $home;}
.gap			{color: $gap;}
.travel		 	{color: $travel;}
.scratch	 	{color: $scratch;}
.excess	        {color: $excess;}
.mot			{color: $mot;}
.keys           {color: $keys;}
.caravan        {color: $caravan;}
.alloy		  	{color: $alloy;}
.tyre		   	{color: $tyre;}
.breakdown	   	{color: $breakdown;}
.life		   	{color: $life;}
.hire			{color: $hire;}
.warranty		{color: $warranty;}


// Main Space Size
$space: 10px;
