@import "mixins";
@import "vars";
@import "typography";
@import "homepage";
@import "header";
@import "footer";
@import "sitemap";
@import "pricingtables";

@import "household";
@import "home";
@import "gap";
@import "travel";
@import "scratch";
@import "excess";
@import "mot";
@import "alloy";
@import "keys";
@import "caravan";
@import "tyre";
@import "breakdown";
@import "life";
@import "hire";
@import "warranty";
@import "lifestylelegal";
@import "gadget";
@import "_bicycle";
@import "property-let";
@import "_slick";

@import "_component.download";
@import "_component.social";
@import "utility.helpers";

@import "shame";

/************************
9. RIGHT SIDEBAR
************************/
.right-sidebar {
	background: #f0f0f0;
	margin: 0 0 15px;
	padding: 20px 20px 10px;
	h4 {
		font-size: 26px;
		margin-bottom: 6px;
	}
	.sub-title {
		@include bree;
		font-size: 21px;
		margin-bottom: 6px;
	}

    ul{
        list-style: none;
    }

	li{
		font-size: 16px;
        line-height: 24px;
       	padding: 18px 0;
		border-bottom: 1px solid #c0c0c0;

        &:before {
		    border-bottom: none;
		    content: "";
            border: none !important;
            display: inline-block;
		    height: 20px;
		    /*left: -15px;*/
		    position: relative;
		    /*top: -3px;*/
		}

        &:after {
			content: "";
		}

        &:last-child{
	        border-bottom: none;
	        padding-bottom: 0;
        }
	}
}

.paperless {
	background: #f0f0f0 !important;
	h4 {
		color: $grey-dark !important;
	}
	p {
		@include bree;
		color: $grey-dark;
		font-size: 21px;
        margin-bottom: 6px;
	}
}

.case-studies {
	background: #f0f0f0;
	margin: 0 0 15px;
	padding: 20px 20px 10px;

	h4 {
        		color: $grey-dark;
		font-size: 26px;
		margin-bottom: 6px;
	}

	ul{
		list-style: none;
	}

	li {
		font-size: 18px;
        		line-height: 24px;
       		padding: 18px 0;
		border-bottom: 1px solid #c0c0c0;

        		&:last-child{
	           	 	border-bottom: none;
	            	padding-bottom: 0;
        		}

        h5{
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 24px;
            color: $grey-dark !important;
        }

        a{
            font-size: 16px;
        }
	}
}



/*************************
CONTENTS

1.	MAIN COLOURS
2.	BUTTONS
3.	HIGHLIGHTS & OUTLINES
4.	BASE
5.	HEADER
6.	NAVIGATION
7.	a. HOME SLIDER BASE
	b. HOME SLIDER TEXT
8.	LEFT SIDEBAR
9.	RIGTH SIDEBAR
10. HOMEPAGE
11. FOOTER
12. MY ACCOUNT
13. RETRIEVE A QUOTE
14. FEATURE SECTION ON INNER HOMEPAGES
15. TABLES
16. PRICING TABLES
17. CUSTOMER SERVICES
18. NEWS
*************************/


/************************
COUNTRY SELECT MODAL
************************/

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: none !important;
}

#country-select-content {
    overflow: hidden;
    padding: 0 0 20px;
    text-align: center;
    h3 {
        color: #58585a;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
    }
    p {
        color: #5f5f5f;
        font-size: 13px;
        margin-top: 12px;
    }
}

.country-select {
    background: url(../images/globe.png) no-repeat 0 center;
    color: #999;
    float: left;
    margin: 15px 0 0;
    padding-left: 26px;
    img {
        height: 12px;
        margin-left: 5px;
    }

}

.region-off {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin: 36px auto 0;
    width: 140px;
    a {
        background: none;
        display: block;
        padding: 20px 15px 10px 15px;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }
    .region-off a:hover {
        background: #eee;
    }
}

body .fancybox-close {
    background: #005697;
    border: 4px solid;
    border-radius: 22px;
    color: #fff;
    font-size: 22px;
    position: absolute;
    top: -18px;
    right: -18px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    z-index: 8040;
}

body .fancybox-close::before {
content: 'x';
left: 8px;
position: absolute;
top: 0;
}



/************************
1. MAIN COLOURS
************************/
.black			{color: $black;}
.white			{color: $white;}
.base-green		{color: $base-green;}
.base-blue		{color: $base-blue;}


/************************
2. BUTTONS
************************/

.button {
	font-size: 16px;
}

.quote-button {
	border: 0;
	color: #fff;
	display: inline-block;
	font-size: 16px;
	font-weight: 600;
	margin: 0;
	padding: 12px 20px;
	text-align: center;
	width: 193px;
}

.bigRed {
    background-color:Red;
}

.btn-base-green {
	@include btn-base-green;
	&:hover {
		background: $base-green-hover;
	}
}


.btn-base-blue {
	@include btn-base-blue;
	&:hover {
		background: $base-blue-hover;
	}
}

.btn-black {
	background: $black;
	border: none;
	&:hover {
		background: #333;
	}
}

/************************
3. HIGHLIGHTS & OUTLINES
************************/

::selection {
	background: $base-green;
	color: $white;
}
::-moz-selection {
	background: $base-green;
	color: $white;
}

textarea:focus, input:focus {
    outline: $grey;
}

*:focus {
    outline: $grey;
}

/************************
4. BASE
************************/

.mt10 {
	margin-top: 10px;
}

.mt20 {
	margin-top: 20px;
}

.mt30 {
	margin-top: 30px;
}

small {
	font-size: 80%;
}

.none {
	display: none;
}

h1, h2, h3, h4, h5, h6 {
	color: $base-blue;
}

body {
	@include myriad;
}

a {
	color: $grey;
	&:hover, &.active {
		color: $base-blue;
	}
}

p {
	&.copy-title {
		font-weight: bold;
		margin: 0;
	}
	&.first-paragraph {
		margin: 24px 0 0;
	}
}

#logo {
	margin: 20px 0 0;
	a {
		display: block;
	}
}

#main-content {
	margin-top: 30px;
	position: relative;
	z-index: 0;
	h3 {
		margin: 10px 0 15px;
	}
}

#main-content {
	h2, h4 {
		margin: 0 0 20px;
	}
}

table ol {
	margin: 0 0 0 20px;
}

ol {
	margin-left: 0;
}

.list-basic {
	@include list-basic;
}

.list-nobull {
	@include list-nobull;
}

input[type="submit"] {

}

input[type="submit"].button {
	font-size: 16px;
}

input[type="text"][disabled]
{
background-color:white;
border:none;
color:black;
}

ul.accordion {
	li {
		&.active {
			border-top: 3px solid $base-green;
		}
		.title {
			font-size: 13px;
			padding: 10px 30px 10px 15px !important;
			h5 {
				@include bree-serif;
				color: $grey-dark;
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
	p {
		@include myriad;
		font-size: 16px;
	}
}

input[type=submit], button {
	@include btn;
	@include myriad;
	cursor: pointer;
	font-size: 16px;
}

.table-select-policy {
	overflow: hidden;
	width: 100%;
	table {
		width: 100%;
		tr td, tr, th {
			color: #555;
			font-size: 18px;
			padding: 15px 10px;
			&:last-child {
				text-align: center;
			}
		}
	}
	button {
		float: right;
	}
}

#main-content .productAdditions {
	ol.formv2 {
		list-style-type:none;
		margin-left: 188px;
		overflow: hidden;
		width: auto;
		li {
			float:left;
			width:40%;
			span.checkbox {
				float:left;
				margin-right:10px;
			}
			input {
				float: left;
				margin: 6px 5px 0 0;
			}
			label {
				float: left;
			}
		}
	}
	padding: 0;
	table {
		width: 100%;
	}
}

/************************
8. LEFT SIDEBAR
************************/

.left-sidebar {
	ul {
		list-style: none;
		li {
			margin: 0 0 1px;
			a {
                border-bottom: 1px solid $grey-light;
				color: $grey;
				display: block; 
				padding: 12px 0;
                &:hover {
                    border-bottom: 1px solid $grey;
                }
				&.selected {
                    color: $black;
                    font-weight: bold;
				}
			}
		}
	}
}

#call-us {
		@include bree;
		margin: 20px 0 0;
		b {
			color: $grey;
			display: block;
			font-size: 17px;
		}
		span {
			color: #333;
			display: block;
			font-size: 20px;
			font-weight: bold;
			margin: 0 0 10px;
		}
		strong {
			color: $base-blue;
			display: block;
			font-size: 34px;
		}
	}


// /************************
// 9. RIGHT SIDEBAR
// ************************/
// #main-content .right-sidebar {
// 	background: #f0f0f0;
// 	margin: 0 0 15px;
// 	padding: 20px 20px 10px;
// 	h4 {
// 		font-size: 26px;
// 		margin-bottom: 6px;
// 	}
// 	.sub-title {
// 		@include bree;
// 		font-size: 21px;
// 	}
// 	li {
// 		font-size: 16px;
// 	}
// }

// .paperless {
// 	background: #f0f0f0 !important;
// 	h4 {
// 		color: $grey-dark;
// 	}
// 	p {
// 		@include bree;
// 		color: $grey-dark;
// 		font-size: 21px;
// 	}
// 	ul {
// 		li {
// 			border-bottom: none !important;
// 		}
// 	}
// }


/************************
12. MY ACCOUNT
************************/

dl.table-display {
	margin: 10px 0;
	@include clear;
	dt {
		clear: left;
		float: left;
		font-weight: bold;
		margin: 5px 0;
		width: 100px;
	}
	dd {
		float: left;
		margin: 5px 0;
	}
}
	.facebook-social {
		.connection-details {
			background: #577ac3;
		}
	}
	.twitter-social {
		.connection-details {
			background: #55bcef;
		}
	}

.social-connection {
	a {
		position: relative;
		display: block;
	}

	margin: 10px 0;
	.connection-details {
		height: 50px;
		max-height: 50px;
		padding: 1px 0;
		p {
			color: $white;
			margin: 5px 0 0;
			line-height: 18px;
			padding: 0;
		}
		a {
			@include bree;
			color: $white;
		}
	}
	img {
		float: left;
		margin: 0 10px 0 0;
	}
	span {
		left: 33px;
		position: absolute;
		top: 33px;
	}
}
.buttonRight {
	float: right;
}
.buttonRight a:hover {
	color: #fff;
}


/************************
13. RETRIEVE A QUOTE
************************/

#retrieve-modal {
	text-align: left;
	h3 {
		margin: 0 0 10px;
	}
}

#retrieve-quote {
	margin-top: 20px;
	.facebook {
		text-align: center;
	}
}
#btn-register {
	@include btn-grey;
	display: block;
	margin-top: 20px;
	&:hover {
		background: $grey-dark;
	}
}

.or-separator-onlight {
	margin: 0;
	p {
		color: $grey;
		margin: 10px 0;
	}
	span {
		background: $black;
		display: block;
		left: 30px;
		min-height: 70px;
		position: relative;
		width: 1px;
	}
}

.outside-labels {
	text-align: left;
	p {
		font-weight: bold;
		margin: 0;
	}
	hr {
		margin: 5px 0 10px;
	}
}

/************************
14. FEATURE SECTION ON INNER HOMEPAGES
************************/
.product-feature {
	background-color: $grey-light;
	margin: -10px 0 30px;
	min-height: 250px;
	.banner-details {
		float: left;
		width: 58.333333%;
	}
	#feature-copy {
		background: url(../img/bg-grey-transp.png) 0 0;
		padding: 35px 10px 15px 30px;
		min-height: 250px;

		p {
			font-size: 21px;
			font-weight: bold;
		}

		li {
			color: $white;
		}
	}

    .coming-soon {
        border-radius: 57px;
        bottom: -10px;
        height: 113px;
        left: 50px;
        padding-top: ($space * 2.5);
        position: absolute;
        width: 113px;
        h4{
            font-weight: bold;
            line-height: 22px;
        }
    }

	h1 {
		color: $white;
		margin: 30px 0 12px 40px;
	}

	a {
		@include btn;
		bottom: 60px;
		font-size: 16px;
		left: 42px;
		margin: 0;
		padding: 12px 20px;
		position: absolute;
		width: 193px;
		&:hover {
			@include btn;
			padding: 12px 20px;
		}
	}
}


/************************
15. TABLES
************************/
.colour-tier-option {
	text-align: center;
	p {
		@include bree;
		font-size: 24px;
		margin: 0 0 5px;
	}
	ul {
		list-style: none;
		margin: 0 auto 10px;
		width: 100%;
		@include clear;
		li {
			display: inline;
			&.per-mth {
				strong {
					font-size: 24px;
				}
				span {
					display: inline-block;
					font-size: 11px;
					line-height: 11px;
					margin: 0 0 0 3px;
				}
			}
			&.per-or {
				font-size: 12px;
				margin: 0 10px;
			}
			&.per-yr {
				strong {
					font-size: 24px;
				}
				span {
					display: inline-block;
					font-size: 11px;
					line-height: 11px;
					margin: 0 0 0 3px;
				}
			}
		}
	}
}

table.benefits {
	border-collapse: collapse;
	border: none;
	td {
		@include border-bottom;

		&.cell-left {
			overflow: hidden;
			white-space: nowrap;
			border-right: 1px solid $grey-light;
		}
		&.first {
			border-top: 1px solid $grey-light;
		}
	}
}

#quote-results-table {
	td {
		padding: 30px 0;
		text-align: center;
		b {
			font-size: 16px;
		}
		p {
			font-size: 30px;
			font-weight: bold;
			margin: 5px 0 0;
		}
	}
}


/* Form Styles */
.form-columns {
	h4 {
		margin: 15px 0;
	}
	input, label, p {
		font-size: 14px;
	}
	ul, .custom.dropdown {
		width: 100% !important;
	}
}


/************************
17. CUSTOMER SERVICES
************************/
.arrow-bull {
	font-weight: bold;
	margin: 0 0 0px;
}

/************************
18. NEWS
************************/
.news-article {
	border-bottom: 1px dotted $base-blue;
	margin: 0 0 20px;
	padding: 0 0 10px;
	list-style: none;
	.title {
		@include clear;
	}
	h4 {
		/*float: left;*/
	}
	b {
		float: right;
		line-height: 35px;
	}
	.feature-img {
		margin: 15px 0 20px 0;
	}

}

.page-testimonials article {
  border-bottom: 1px solid #ccc;
  padding-top: 24px; }

  .testimonial-customer {
  color: black;
  font-weight: bold;
  margin: 24px 0; }

div.pager
{
    float:right;
    margin: 5px 0px 0px 0px;
	font-size: 0.9em;
}
.previous, .next
{
	border: none;
    padding: 0px 10px 2px 10px;
	background-color: $base-blue;
	text-decoration:none;
	color:#FFF;
	float:left;
	margin-right: 1px;
	font-weight: normal;
}
.next
{
	margin-left: 1px;
}
.pager ul.pages
{
    float:left;
    margin:0;
    padding:0;
}
.pager ul.pages li
{
    float: left;
    list-style-type: none;
    background-color: $base-blue;
    margin: 0px 1px 0px 1px;
    padding: 1px 10px;
    text-align: center;
}
.pager ul.pages li.active, .pager ul.pages li.active A
{
    background-color: $base-blue-hover;
    color:#fff;
	font-weight: normal;
}
.pager ul.pages li a, .pager .previous a, .pager .next a
{
    color: #fff;
	font-weight: normal;
}
.pager ul.pages li a:hover, .pager .previous a:hover, .pager .next a:hover
{
    text-decoration: none;
}
.paging {
	float: left;
}

.round-message {
	@include radius(55px);
	background: $home;
	bottom: -120px;
	height: 110px;
	left: 60px;
	position: absolute;
	width: 110px;
	p {
		@include bree;
		color: #fff !important;
		font-size: 21px;
		font-weight: 600;
		padding: 30px 0 0;
		text-align: center;
		span {
			font-size: 42px;
		}
	}
}

.round-message.soon {
    p {
        font-weight: 600;
        line-height: 24px;
        padding-top: 20px;
    }
}



.nine.columns {
	h3 {
		font-weight: bold;
	}
}


.btnHazardList {
	background: url(../img/icon_hazard_list.png) no-repeat left 5px;
	font-size: 14px;
	position: relative;
	width: 210px;
	span {
		display: block;
		line-height: 16px;
		margin-left: 34px;
		a {
			color: $travel;
			&:hover {
				color: $travel-hover;
			}
		}
	}
}

#modal-hazardous {
	@include radius(10px);
	height: 600px;
	overflow-y: scroll;
	.five.columns {
		padding: 0;
	}
	li {
		list-style: none;
		margin: 0 0 5px 0;
	}
}

.change-policy {
	background: #e3effa url(../img/bg-change-policy.jpg) right center no-repeat;
	padding: 7px;
	a {
		color: $base-blue;
		font-weight: bold;
	}
	h4 {

		color: $base-blue;
		font-weight: bold;
		margin: 0 0 10px !important;
		padding: 10px 0 0 10px;
	}
	p {
		padding: 0 0 0 10px;
	}
}


//PRODUCT INFORMATION
.product-section {
    .four {
        margin-bottom: 30px;

        h4 {
            font-weight: bold;
            margin-bottom: 0 !important;
        }

		ul {
			list-style: none;
			li {
				padding: 0 0 5px 25px;
				position: relative;
				a {
					&:before {
						border-top: 7px solid transparent;
						border-bottom: 7px solid transparent;
						border-left: 7px solid #ccc;
						content: "";
						height: 0;
						left: 0;
						top: 5px;
						position: absolute;
						width: 0;
					}
				}
			}
        }
    }

	.breakdown {
		@include product-info($breakdown);
	}

	.travel {
		@include product-info($travel);
	}

	.household {
		@include product-info($household);
	}

	.warranty {
		@include product-info($warranty);
	}

	.gap {
		@include product-info($gap);
	}

	.excess {
		@include product-info($excess);
	}

	.mot {
		@include product-info($mot);
	}

	.home {
		@include product-info($home);
	}

	.life {
		@include product-info($life);
	}

	.alloy {
		@include product-info($alloy);
	}

	.tyre {
		@include product-info($tyre);
	}

	.scratch {
		@include product-info($scratch);
	}

}

/************************
27. 10% DISCOUNT
************************/

.home-emergency {
	.discount10 {
		background: #fbedb5;
		display:none;
		h4 {
			margin-bottom: 0 !important;
		}
		.sub-title {
			color: $black;
			font-size: 20px;
			margin-bottom: 10px;
		}
		p {
			margin-bottom: 0;
		}
		i {
			color: $home;
		}
	}
}

#vehTypes {
	li {
		list-style: none;
		input[type=radio] {
			float: left;
			margin-right: 6px;
			margin-top: 6px;
		}
	}
}

.social-links{
	display: inline-block;
	margin-right: 10px;
	vertical-align: top;

	&--small{

		.social-links{
			&__item{
				width: 23px;
				height: 23px;
				margin-right: 0;

				&--fb{
					background-position: -10px -10px;
				}

				&--twitter{
					background-position: -70px -10px;
				}

				&--google{
					background-position: -130px -10px;
				}
			}
		}
	}


	&__item{
		display: inline-block;
		width: 48px;
		height: 48px;
		background-image: url('../img/social-icons.png');
		background-repeat: no-repeat;
		margin-right: 14px;

		&:last-child{
			margin-right: 0;
		}

		a{
			display: block;
			height: 100%;
			width: 100%;
		}

		&--fb{
			background-position: -10px -70px;
		}

		&--twitter{
			background-position: -70px -70px;
		}

		&--google{
			background-position: -130px -70px;
		}
	}
}
