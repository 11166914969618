.sitemap ul {
	margin: 0;
	li {
		list-style: none;
		margin: 0;
	}
}

#primaryNav.col8 li { width:12.5%; }

/*	Top Level */

#primaryNav {
	margin: 0;
	float: left;
	width: 100%;
	li {
		background: url('../img/sitemap/L1-center.png') center top no-repeat;
		float: left;
		margin-top: -30px;
		padding: 30px 0;
		a {
			border: 1px solid #ccc;
			display: block;
			color: black;
			font-size: 12px;
			font-weight: bold;
			margin: 0 20px 0 0;
			padding: 10px 5px;
			text-align: center;
			&:hover {
				background-color: #ccc;
			}
		}
		&:last-child {
			background: url('../img/sitemap/L1-right.png') center top no-repeat;
		}
		li {
			background: url('../img/sitemap/vertical-line.png') center bottom repeat-y;
			clear: left;
			margin-top: 0;
			padding: 10px 0 0;
			width: 100% !important;
			
			a {
				background-color: #eee;
				font-weight: normal;
				&:hover {
					background-color: #e7f1d7;
					border-color: #94b75f;
				}
				&:link:before, &:visited:before {
					color: #8faf5c;
				}
			}
			&:first-child {
				padding-top: 30px;
			}
			&:first-child {
				background: url('../img/sitemap/vertical-line.png') center bottom repeat-y;
			}
			ul {
				background: #ffffff url('../img/sitemap/L3-ul-top.png') center top no-repeat;
				float: right;
				padding: 9px 0 10px 0;
				margin: 10px 0 0 0;
				width: 100%;
				li {
					background: url('../img/sitemap/L3-center.png') left center no-repeat;
					padding: 5px 0;
					width: 100% !important;
					a {
						background-color: #eee;
						border-color: #ccc;
						float: right;
						font-size: 12px;
						padding: 5px 0;
						width: 80%;
						&:hover {
							background-color: #ddd;
							border-color: #ccc;
						}
						&:link:before, &:visited:before {
							color: #ccae14;
							font-size: 9px;
						}
					}
					&:first-child {
						padding: 15px 0 5px 0;
						background: url('../img/sitemap/L3-li-top.png') left center no-repeat;
					}
					&:last-child {
						background: url('../img/sitemap/L3-bottom.png') left center no-repeat;
					}
				}
			}
		}
	}
	#home {
		background: #ffffff url('../img/sitemap/L1-left.png') center bottom no-repeat;
		display: block;
		float: none;
		padding: 0 0 30px 0;
		position: relative;
		z-index: 2;
	}
}

/* Utility Navigation */

.utilityNav {
	float: right;
	margin-right: 10px;
	max-width: 100%;
	li {
		float: left;
		margin-bottom: 10px;
		a {
			background: #ccc;
			border: 1px solid #ccc;
			color: black;
			display: block;	
			margin: 0 10px 0 0;
			padding: 5px 10px;
			font-size: 12px;
			font-weight: bold;
			text-align: center;
			&:hover {
				background-color: #fff;
				border-color: #ccc;
			}
			&:link:before, &:visited:before {
				color: #ccae14;
	font-size: 9px;
	margin-bottom: 3px;
			}
		}
	}
}