/* 2 COLUMNS */
.p-table {
	float: left;
	height: 100%;
	margin: 10px 0 35px;
	width: 100%;
	a {
		&.sign_up {
			margin: 0 auto;
			text-align: center;
		}
	}
	b {
		color: $white;
		display: block;
	}
	h4 {
		color: $white;
		line-height: 20px;
		margin: 10px 0 0;
	}
	p {
		margin: 80px 0 15px 10px;
		display: block;
		font-weight: bold;
	}
	strong {
		@include bree;
		color: $white;
		font-size: 30px;
	}
	ul {
		list-style-type: none;
		li {
			margin: 0px;

			&.footer_row {
				background-color: #e2e2e2;
				border: none;
				float: left;
				height: 75px;
				padding: 15px 0;
				width: 100%;
				div {
					display: inline !important;
					text-align: center;
				}
				.button {
					border: none;
					padding: 15px;
				}
			}

			&.row_style_2, &.row_style_4 {
				float: left;
				height: 100%;
				min-height: 26px;
				padding: 4px 0 3px !important;
				width: 100%;
			}
		}
	}

	.caption_column {
		float: left;
		height: 100%;
		width: 50%;

		li {
			text-align: left;
		}
	}

	.header_row_1 {
		height: 100px;
		padding: 32px 0px 6px 10px;
		width: 100%;
	}

	.header_row_2 {
		background-color: #333;
		height: 100px;
		width: 100%;
		padding: 3px 0px 7px 0px;

		H4
		{
			margin:3px 0 0 0 !important;
		}

	}

	.header_row_hhuk {
		background-color: #333;
		height: 100px;
		width: 100%;
		padding: 3px 0px 7px 0px !important;

		H4
		{
			margin:10px 0 0 0 !important;
		}
		}
	.column_1, .column_2 {
		border-left: 1px solid #ccc;
		height: 100%;
		float: left;
		position: relative;
		transition: box-shadow 0.5s ease;
		 -moz-transition: box-shadow 0.5s ease;
		 -ms-transition: box-shadow 0.5s ease;
		 -o-transition: box-shadow 0.5s ease;
		 -webkit-transition: box-shadow 0.5s ease;
		width: 25%;
			
		&:hover {
			box-shadow: 5px 0px 30px rgba(0,0,0,0.5);
			 -moz-box-shadow: 5px 0px 30px rgba(0,0,0,0.5);
			 -webkit-box-shadow: 5px 0px 30px rgba(0,0,0,0.5);
			left: 0px;
			margin: 0 0 -45px;
			position: relative;
			top: -15px;
			z-index: 100;
		}
		li {
			text-align: center;
		}
	}
	.column_2 {
		border-right: 1px solid #ccc;
	}
}
	
.column_1:hover li.row_style_2, .column_2:hover li.row_style_2, .column_3:hover li.row_style_2, 
.column_1:hover li.row_style_4, .column_2:hover li.row_style_4, .column_3:hover li.row_style_4
	{
	padding: 7px 5px 3px !important;
	}

.column_1:hover li.header_row_2, .column_2:hover li.header_row_2,
.column_3:hover li.header_row_2
	{
	padding: 5px 0 !important;
	}

.column_1:hover li.footer_row, .column_2:hover li.footer_row,
.column_3:hover li.footer_row
	{
	padding: 10px 0 0 !important;
	}
	
li.row_style_2 {
	background-color: #f6f6f6;
	span {
		line-height: 26px;
		padding: 0 0 0 10px;
		&.has-tip {
			font-weight: normal;
			padding: 0;
		}
	}
}
	
li.row_style_4 {
background-color: #e3e3e3;
	span {
		line-height: 26px;
		padding: 0 0 0 10px;
			&.has-tip {
				font-weight: normal;
				padding: 0;
			}
	}
}


/* 3 COLUMNS */
.three-col-table {
	span {
		font-size: 14px;
	}
	.caption_column {
		float: left;
		height: 100%;
		width: 40%;

		li {
			text-align: left;
		}
	}

	.header_row_1 {
		height: 100px;
		padding: 32px 0px 6px 10px;
		width: 100%;
	}

	.header_row_2 {

		span {
			color: $white;
			display: block;

			&.month-cost {
				font-size: 11px;
				line-height: 14px;
				margin: 12px 0 0;

				b {
					@include bree-serif;
					display: inline;
					font-size: 32px;
					font-weight: normal;
					margin: 0 5px 0 0;
				}
			}

			&.or {
				font-size: 13px;
				line-height: 16px;
			}

			&.annual-cost {
				font-size: 13px;
				line-height: 14px;
				margin-top:0;
				b {
					display: inline;
					font-size: 14px;
					font-weight: bold;
				}
			}
		}
	}
	.column_1, .column_2, .column_3 {
		border-left: 1px solid #ccc;	
		float: left;
		height: 100%;
		position: relative;
		transition: box-shadow 0.5s ease;
		 -o-transition: box-shadow 0.5s ease;
		 -moz-transition: box-shadow 0.5s ease;
		 -ms-transition: box-shadow 0.5s ease;
		 -webkit-transition: box-shadow 0.5s ease;
		width: 20%;
	}
	.column_2, .columns_3 {
		border-right: 1px solid #ccc;
	}
}



.p1-table {
	float: left;
	height: 100%;
	margin: 10px 0 35px;
	width: 100%;
	a {
		&.sign_up {
			margin: 0 auto;
			text-align: center;
		}
	}
	b {
		color: $white;
		display: block;
	}
	h4 {
		color: $white;
		line-height: 20px;
		margin: 10px 0 0;
	}
	p {
		margin: 80px 0 15px 10px; 
		display: block;
		font-weight: bold;
	}
	strong {
		@include bree;
		color: $white;
		font-size: 30px;
	}
	ul {
		list-style-type: none;
		li {
			margin: 0px;

			&.footer_row {
				background-color: #e2e2e2;
				float: left;
				height: 75px;
				padding: 14px 0 6px;
				width: 100%;
				div {
					display: inline !important;
					text-align: center;
				}
			}

			&.row_style_2, &.row_style_4 {
				float: left;
				height: 100%;
				min-height: 26px;
				padding: 4px 0 3px !important;
				width: 100%;
			}
		}
	}

	.caption_column {
		float: left;
		height: 100%;
		width: 65%;

		li {
			text-align: left;

			&.header_row_1 {
				height: 100px;
				padding: 32px 0px 6px 10px !important;
				width: 100%;
			}
		}
	}

	.header_row_2 {
		background-color: $travel;
		border-top: 1px solid #d4ab17;
		border-bottom: 1px solid #d4ab17;
		height: 100px;
		width: 100%;
		padding: 3px 0px 7px 0px !important;

		H4
		{
			margin:3px 0 0 0 !important;
		}

	}

	

	.header_row_travel {
		background-color: $travel;
		border-top: 1px solid $travel;
		border-bottom: 1px solid $travel;
		height: 100px;
		width: 100%;
		padding: 3px 0px 7px 0px !important;

		H4
		{
			margin:10px 0 0 0 !important;
		}
		}

	.column_1, .column_2 {
		border-left: 1px solid #ccc;
		height: 100%;
		float: left;
		position: relative;
		transition: box-shadow 0.5s ease;
		 -moz-transition: box-shadow 0.5s ease;
		 -ms-transition: box-shadow 0.5s ease;
		 -o-transition: box-shadow 0.5s ease;
		 -webkit-transition: box-shadow 0.5s ease;
		width: 35%;
			
		&:hover {
			box-shadow: 5px 0px 30px rgba(0,0,0,0.5);
			 -moz-box-shadow: 5px 0px 30px rgba(0,0,0,0.5);
			 -webkit-box-shadow: 5px 0px 30px rgba(0,0,0,0.5);
			left: 0px;
			margin: 0 0 -45px;
			position: relative;
			top: -15px;
			z-index: 100;
		}
		li {
			text-align: center;
		}
	}
	.column_2 {
		border-right: 1px solid #ccc;
	}
}



.breakdown {
	.p-table {
		.header_row_1 {
			height: 80px;
		}
		.header_row_2 {
			background-color: $breakdown;
			height: 80px;
			padding-top: 8px;
		}
		.header_row_hhuk {
			background-color: $breakdown;
		}
		.footer_row {
			.button {
				background: $breakdown;
			}
		}
	}
}

.home-emergency {
	.p-table {
		.header_row_2 {
			background-color: $home;
		}
		.header_row_hhuk {
			background-color: $home;
		}
		.footer_row {
			.button {
				background: $home;
			}
		}
	}
}

.car-excess {
	.p-table {
		.header_row_2 {
			background-color: $excess;
			h4 {
				font-size: 17px;
			}
		}
		.header_row_hhuk {
			background-color: $excess;
		}
		.footer_row {
			.button {
				background: $excess;
			}
		}
	}
}

.tyre {
	.p-table {
		.header_row_1 {
			height: 80px;
		}
		.header_row_2 {
			background-color: $tyre;
			height: 80px;
			padding-top: 8px;
		}
		.header_row_2 {
			background-color: $tyre;
		}
		.header_row_hhuk {
			background-color: $tyre;
		}
		.footer_row {
			.button {
				background: $tyre;
			}
		}
	}
}

.mot {
	.p-table {
		.header_row_1 {
			height: 80px;
		}
		.header_row_2 {
			background-color: $mot;
			height: 80px;
			padding-top: 8px;
		}
		.header_row_hhuk {
			background-color: $mot;
		}
		.footer_row {
			.button {
				background: $mot;
			}
		}
	}
}
