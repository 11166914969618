@import "mixins";
@import "vars";

.btn-household {
	@include btn-household;
	&:hover {
		background: $household-hover;
	}
    &.disabled {
        background: lighten(#0c98c0, 15%);
    }
}

.household {
	::selection {
		background: $household;
		color: $white;
	}
	::-moz-selection {
		background: $household;
		color: $white;
	}
	h2, h3 {
		color: $household;
	}
	.product-feature {
		background: url(../img/banner_household.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $household;

			&:hover {
				background: $household-light;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: $household-light;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $household;
				}
				&.active {
					background: $household;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $household;
			}
		}
	}

	.case-studies {
		li {
			a{
				color: $household;

				&:hover{
					color: $household;
				}
			}
		}
	}

	.right-sidebar {
            	background: #d3f2fb;

		h4 {
			color: $household;
		}
		li {
			border-bottom-color: $household;
			&:before {
				@include list-tick-household;
			}
		}
	}
	.feature-list {
		li {
			@include list-tick-household;
			line-height: 24px;
		}
	}
	.round-message {
		background: $household;
	}
	.postcode {
		left: -10px;
	}
}

.radio-green {
	margin: 0 10px 0 -5px;
    span:before {
        background-color: $household !important;
    }
}

