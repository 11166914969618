.btn-life {
	@include btn-life;
	&:hover {
		background: $life-hover;
	}
}
.life {
	::selection {
		background: $life;
		color: $white;
	}
	::-moz-selection {
		background: $life;
		color: $white;
	}
	h2, h3 {
		color: $life;
	}
	.product-feature {
		background: url(../img/banner_life.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $life;

			&:hover {
				background: $life;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: $life;
		}
		.banner-details {
			position: relative;
		}
	}

	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $life;
				}
				&.active {
					background: $life;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $life;
			}
		}
	}
	.middle-content {
		h4 {
			color: $life;
			font-weight: bold;
		}
	}
	.case-studies {
                li {
                    a{
                        color: $life;

                        &:hover{
                            color: $life;
                        }
                    }
                }
            }

            .right-sidebar {
                        background: #e0f0df;

                h4 {
                    color: $life;
                }
                li {
                	border-bottom-color: $life;
                    &:before {
                        @include list-tick-life;
                    }
                }
            }

	.feature-list {
		li {
			@include list-tick-life;
		}
	}
	.round-message {
		background: $life;
	}
}