.btn-tyre {
	@include btn-tyre;
	&:hover {
		background: $tyre-hover;
	}
}

.row.tyre-options {
	margin-top: 48px;
	.colour-tier-option {

	}
}

.tyre {
	overflow: hidden;
	width: 100%;
	::selection {
		background: $tyre;
		color: $white;
	}
	::-moz-selection {
		background: $tyre;
		color: $white;
	}
	h2, h3 {
		color: $tyre;
	}
	.product-feature {
		background: url(../img/banner_tyre.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $tyre;
			&:hover {
				background: $tyre-light;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: $tyre-light;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $tyre;
				}
				&.active {
					background: $tyre;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $tyre;
			}
		}
	}
	 .case-studies {
	                li {
	                    a{
	                        color: $tyre;

	                        &:hover{
	                            color: $tyre;
	                        }
	                    }
	                }
	            }

	            .right-sidebar {
	                        background: #e0f0df;

	                h4 {
	                    color: $tyre;
	                }
	                li {
	                	border-bottom-color: $tyre;
	                    &:before {
	                        @include list-tick-tyre;
	                    }
	                }
	            }

	.feature-list {
		li {
			@include list-tick-tyre;
		}
	}
	.round-message {
		background: $tyre;
	}
}