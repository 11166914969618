.btn-warranty {
	@include btn-warranty;
	&:hover {
		background: $warranty-hover;
	}
}

.warranty {
	::selection {
		background: $warranty;
		color: $white;
	}
	::-moz-selection {
		background: $warranty;
		color: $white;
	}
	h2, h3 {
		color: $warranty;
	}
	.product-feature {
		background: url(../img/banner_warranty.png) no-repeat 0 0;
		background-size: cover;
		a {
			background: $warranty;

			&:hover {
				background: $warranty;
			}
		}
		h1 {
			color: $white;
		}
		p {
			color: $warranty-light;
		}
	}
	.left-sidebar {
		li {
			a {
				&:hover {
					border-bottom: 7px solid $warranty;
				}
				&.active {
					background: $warranty;
					border-bottom: none;
				}
			}
		}
		#call-us {
			strong {
				color: $warranty;
			}
		}
	}
	.case-studies {
	                li {
	                    a{
	                        color: $warranty;

	                        &:hover{
	                            color: $warranty;
	                        }
	                    }
	                }
	            }

	            .right-sidebar {
	                        background: #e0f0df;

	                h4 {
	                    color: $warranty;
	                }
	                li {
	                	border-bottom-color: $warranty;
	                    &:before {
	                        @include list-tick-warranty;
	                    }
	                }
	            }
	.feature-list {
		li {
			@include list-tick-warranty;
		}
	}
	.round-message {
		background: $warranty;
	}
}