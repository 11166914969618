#product-info-link {
	position: relative;
	z-index: 0;
	p {
		margin: 0;
	}
	a {
		color: #fff;
		background: $base-green;
		display: block;
		margin: 40px 0 20px;
		padding: 10px;
		text-align: center;
		&:hover {
			background: $base-green-hover;
		}	
	}
}

#home-refer {
	background: url(../img/home/refer-a-friend2.jpg) no-repeat 0 0;
	padding: 20px;
	h3 {
		margin: 0 0 10px;
	}
	ul {
		list-style: none;
		li {
			background: url(../img/bull-tick-green.png) no-repeat 0 5px;
			color: $black;
			font-weight: bold;
			font-size: 16px;
			padding: 0 0 0 20px;
		}
	}
	a {
		@include bree;
		color: $white;
		font-size: 21px;
		font-weight: 700;
		line-height: 24px;
		position: absolute;
		right: 53px;
		text-transform: uppercase;
		text-align: center;
		top: 55px;
		width: 110px;
	}
}

#home-news {
	h3 {
		margin: 0 0 20px;
	}
	p {
		line-height: 21px;
		margin: 0 0 20px;
		&.home-news-headline {
			color: #262c33;
			font-size: 18px;
			font-weight: bold;
			line-height: 24px;
			margin: 0 0 10px;
		}
	}
	a {
		color: $black;
		font-weight: bold;
	}
	ul li {
		list-style: none;
	}
}


#footer-email-signup {
	margin: 20px auto 30px;
	width: 740px;
	div {
		float: left;
		margin: 0 20px 0 0;
	}
	input[type="email"] {
		border: 4px solid #e7edef;
		box-shadow: none; 
		height: 40px;
	}
	input[type="submit"] {
		background: $base-green;
		border: none;
		color: #fff;
		cursor: pointer;
		font-size: 16px;
		font-weight: bold;
		margin: 0 0 0 20px;
		padding: 10px 40px;
		&:hover {
			background: $base-green-hover;
		}
	}
	h4 {
		line-height: 20px;
		margin: 0;
	}
}
.footer-social{
	margin-top: 32px;
	margin-bottom: 35px;
	text-align: center;
}
// #social-icons {
// 	margin: 0 0 30px;
// 	overflow: hidden;
// 	width: 100%;
// 	ul {
// 		display: block; 
// 		float: left;
// 		left: 50%;
// 		list-style: none; 
// 		position: relative; 
// 		li {
// 			@include opacity(99);
// 			display: block;
// 			float: left;
// 			margin:0 15px 0 0;
// 			position: relative;
// 			right: 50%;
// 			a {
// 				background: url("../img/social-icons.png") no-repeat 0 0;
// 				height: 55px;
// 				width: 55px;
// 				@include hide-text;
// 				&:hover {
						
// 					}
// 				&#social1 {
// 					background-position: 0 5px;
// 					&:hover {
// 						background-position: 0 -68px;
// 					}
// 					&:active {
// 						background-position: 0 -146px;
// 					}
// 				}
// 				&#social2 {
// 					background-position: -55px 5px;
// 					&:hover {
// 						background-position: -55px -68px;
// 					}
// 					&:active {
// 						background-position: -55px -146px;
// 					}
// 				}
// 				&#social3 {
// 					background-position: -113px 5px;
// 					&:hover {
// 						background-position: -113px -68px;
// 					}
// 					&:active {
// 						background-position: -113px -146px;
// 					}
// 				}
// 				&#social4 {
// 					background-position: -168px 5px;
// 					&:hover {
// 						background-position: -168px -68px;
// 					}
// 					&:active {
// 						background-position: -168px -146px;
// 					}
// 				}
// 			}
// 			&.last {
// 				margin: 0;
// 			}
// 		}
// 	}
// }

#quicklinks {
	overflow: hidden;
	width: 100%;
	p {
		@include bree;
		color: $black;
		font-size: 16px;
		margin: 0 0 10px;
	}
	ul {
		list-style:none;
		li {
			font-size: 15px;
			line-height: 22px;
			&.footer-tel {
				@include bree;
				font-size: 30px;
				font-weight: bold;
				color: $grey;
			}
		}
	}
	.red {
		color:red;
	}

	.appStore {
		display:block;
		margin-top:25px;
	}
	input[type=submit] {
		margin-top: 24px;
	}
}

#quicklinks-title {
	text-align: center;
	p {
		@include bree;
		color: $black;
		font-size: 22px;
		margin: 0 0 30px;
	}
}

#bodies {
	UL.logos {
        list-style-type:none;
		li {
           	float:left;
           	margin: 0 18px;
           	display:block;
		}
    }                         
}

#copyright,
#flint {
	p, li {
		@include arial;
		font-size: 12px;
	}
	ul {
		list-style: none;
		li {
			border-right: 1px solid $grey;
			display: inline;
			margin: 0 5px 0 0;
			padding: 0 10px 0 0;

			&.last {
				border: none;
			}
		}
	}
}

.footer-testimonials {
    margin-bottom: 36px;
    h4 {
        color: #1c1c1b;
        margin-bottom: 12px;
    }
    p {
        @include bree;
        color: #1a1f26;
        font-size: 18px;
    }
}

.footer-sponsors {
    h4 {
        color: #1c1c1b;
        margin-bottom: 12px;
    }
    ul{
        margin-bottom: 36px;
        text-align: center;
    }
    li {
        display: inline-block;
        list-style: none;
        padding: 0 20px;
    }
}